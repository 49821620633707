export enum DashboardMode {
    Viewer = 0,
    Designer = 1
}

export enum DashboardVisibility {
    Global = 0,
    Personal = 1
}

export enum DashboardSummarySettingSaveOption {
    UseHomeList = 0,
    UseActiveSettings = 1,
    UsePreviouslySavedSettings = 2
  }

  export enum DashboardSaveAsOption {
    Overwrite = 0,
    CreateAsPersonal = 1,
    CreateAsGlobal = 2
  }

  export enum LoadStyle {
    LoadImmediate = 0,
    LoadOnClick = 1,
    LoadDelayed = 2,
  }

  export enum DashboardSectionType {
    TwoThirdsOneThird = 0,
    OneThirdTwoThirds = 1,
    ThreeEqualColumns = 2,
    OneHalfOneHalf = 3,
    Whole = 4,
    SixEqualColumns = 5,
  }
