import { DashboardSectionType } from "../../enums/dashboards/enums";
import { iDashboardSection } from "../../interfaces/dashboards/idashboarddescriptor";
import { Dashboard } from "./dashboard";
import { DashboardSection } from "./dashboardsection";
import { OneHalfOneHalfDashboardSection } from "./onehalfonehalfdashboardsection";
import { OneThirdTwoThirdsDashboardSection } from "./onethirdtwothirdsdashboardsection";
import { SixEqualColumnDashboardSection } from "./sixequalcolumndashboardsection";
import { ThreeEqualColumndDashboardSection } from "./threeequalcolumnddashboardsection";
import { TwoThirdsOneThirdDashboardSection } from "./twothirdsonethirddashboardsection";
import { WholeDashboardSection } from "./wholedashboardsection";

    export class DashboardSectionFactory {

        static CreateDashboardSection(dashboardSectionInfo: iDashboardSection, dashboard: Dashboard): DashboardSection {
            let newSection: DashboardSection;

            switch (dashboardSectionInfo.SectionType) {
                case DashboardSectionType.OneHalfOneHalf:
                    newSection = new OneHalfOneHalfDashboardSection(dashboardSectionInfo.Tiles, dashboard);
                    break;
                case DashboardSectionType.ThreeEqualColumns:
                    newSection = new ThreeEqualColumndDashboardSection(dashboardSectionInfo.Tiles, dashboard);
                    break;
                case DashboardSectionType.OneThirdTwoThirds:
                    newSection = new OneThirdTwoThirdsDashboardSection(dashboardSectionInfo.Tiles, dashboard);
                    break;
                case DashboardSectionType.TwoThirdsOneThird:
                    newSection = new TwoThirdsOneThirdDashboardSection(dashboardSectionInfo.Tiles, dashboard);
                    break;
                case DashboardSectionType.Whole:
                    newSection = new WholeDashboardSection(dashboardSectionInfo.Tiles, dashboard);
                    break;
                case DashboardSectionType.SixEqualColumns:
                    newSection = new SixEqualColumnDashboardSection(dashboardSectionInfo.Tiles, dashboard);
            }

            return (newSection);
        }
        
    }
