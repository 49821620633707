import { LoadCompany, LoadUser, IsModuleLicensedAtCompany } from '../../infrastructure/context';
import { TileFactory } from "../../components/dashboards/tilefactory";
import { TileSettingsBase } from "../../components/dashboards/tilesettingsbase";
import { WelcomeTile } from "../../components/tiles-general/tile-welcome";
import { tdfModules } from 'enums/enums';
import { ReportingTileSettings } from 'components/advancedreporting/tiles/tile_reportingbase';

export function RegisterTiles(): JQueryPromise<any> {
    let wrapperDFD: JQueryDeferred<any> = $.Deferred();
    let dfds: JQueryPromise<any>[] = [];

    $.when(LoadCompany(), LoadUser()).done(function () {
        TileFactory.RegisterTileType("Welcome", "A tile that welcomes the user and shows the company logo.", "", TileSettingsBase, WelcomeTile);

        if (IsModuleLicensedAtCompany(tdfModules.TDF_BSI)) 
        {
            let chartDFD : JQueryDeferred<any> = $.Deferred();
            dfds.push(chartDFD.promise());

            import("../../components/tiles-general/charts").then(v =>{
                TileFactory.RegisterTileType("Charts", "A tile that can be used to display a BI Visualization.", "fa fa-pie-chart", v.ChartTileSettings, v.Charts);
                chartDFD.resolve();
            });
        }
            
        if (IsModuleLicensedAtCompany(tdfModules.TDF_InfoCenter)) // Note - I'm not certain if this is the right check, but mostly I want to ensure that our SherpaBI customers don't end up with the DailySummary tile.  So this works for that.
        {
            let dailySummaryDFD: JQueryDeferred<any> = $.Deferred();
            dfds.push(dailySummaryDFD.promise());
            import("../../components/tiles-general/dailysummary").then(v => {
                TileFactory.RegisterTileType("DailySummary", "A tile that can be used to display the standard Daily Summary that has always existed on the TdF Web home page.", "fa fa-calendar-o", v.DailySummaryTileSettings, v.DailySummary);
                dailySummaryDFD.resolve();
            });
        }

        if (IsModuleLicensedAtCompany(tdfModules.TDF_Alerts)) 
        {
            let alertDFD: JQueryDeferred<any> = $.Deferred();
            dfds.push(alertDFD.promise());
            
            let legacyAlertDFD:JQueryDeferred<any> = $.Deferred();
            legacyAlertDFD.promise();
            import("../../components/tiles-general/tile-legacyalert").then(v => {
                TileFactory.RegisterTileType("LegacyAlert", "A tile that can be used to display a legacy alert.", "fa fa-exclamation-circle", v.LegacyAlertTileSettings, v.LegacyAlertTile);
                legacyAlertDFD.resolve();
            });

            let alertListDFD: JQueryDeferred<any> = $.Deferred();
            alertListDFD.promise();
            import("../../components/tiles-general/alerts").then(v => {
                TileFactory.RegisterTileType("Alerts", "A tile that can be used to list the available alerts in TdF Web.", "fa fa-exclamation-circle", TileSettingsBase, v.Alerts);
                alertListDFD.resolve();
            });

            $.when(legacyAlertDFD, alertListDFD).done(() => {
                alertDFD.resolve();
            });
        }

        if (IsModuleLicensedAtCompany(tdfModules.TDF_BYOR))
        {
            let byorDFD : JQueryDeferred<any> = $.Deferred();
            dfds.push(byorDFD.promise());

            let legacyBYORDFD :JQueryDeferred<any> = $.Deferred();
            legacyBYORDFD.promise();
            import("../../components/tiles-general/tile-legacybyor").then(v => {
                TileFactory.RegisterTileType(
                    "LegacyBYOR",
                    "A tile that can be used to display a legacy BYOR report.",
                    "fa fa-line-chart",
                    v.LegacyByorTileSettings,
                    v.LegacyByorTile
                );
                legacyBYORDFD.resolve();
            });

            let byorReportTileDFD: JQueryDeferred<any> = $.Deferred();
            byorReportTileDFD.promise();
            import("../../components/tiles-general/byorreports").then(v => {
                TileFactory.RegisterTileType(
                    "ByorReportList",
                    "A tile that can be used to display a ist of legacy BYOR reports.",
                    "fa fa-line-chart",
                    v.ByorReportListTileSettings,
                    v.ByorReportList
                );
                byorReportTileDFD.resolve();
            });

            $.when(legacyBYORDFD, byorReportTileDFD).done(() => {
                byorDFD.resolve();
            });
        }

        if (IsModuleLicensedAtCompany(tdfModules.TDF_AdvancedReporting)) {
            let d: JQueryDeferred<void> = $.Deferred();
            dfds.push(d.promise());

            RegisterAdvancedReportingTiles().done(() => {
                d.resolve();
            });
        }

        $.when.apply($, dfds).done(function() {
            return wrapperDFD.resolve();
          });

        //TileFactory.RegisterTileType("DesignerSpaceTile", "A tile that is only used when designing a dashboard to indicate the spaces to add tiles.", "fa fa-plus", TileSettingsBase, DesignerSpaceTile);
    });

    return wrapperDFD.promise();
}

function RegisterAdvancedReportingTiles() {
    let d = $.Deferred();
    let advancedReportingTiles: TileModImportObj[] = [
        {
            FileName: 'tile_generalchart',
            ModName: 'Tile_GeneralChart',
            TileType: 'AdvancedReportingChart',
            Description: "A tile that can be used to display a chart.",
            Icon: "fa fa-line-chart",
            D: $.Deferred()
        }, {
            FileName: 'tile_code',
            ModName: 'Tile_Repeater',
            TileType: "AdvancedReportingRepeater",
            Description: "A tile that can be used to display a repeating section.",
            Icon: "fa fa-file-code-o",
            D: $.Deferred()
        }, {
            FileName: 'tile_gauge',
            ModName: 'Tile_Gauge',
            TileType: "AdvancedReportingGauge",
            Description: "A tile that can be used to display a gauge.",
            Icon: "fa fa-tachometer",
            D: $.Deferred()
        }, {
            FileName: 'tile_header',
            ModName: 'Tile_Header',
            TileType: "AdvancedReportingHeader",
            Description: "A tile that can be used to display a header section on a report.",
            Icon: "fa fa-code",
            D: $.Deferred()
        }, {
            FileName: 'tile_image',
            ModName: 'Tile_Image',
            TileType: "AdvancedReportingImage",
            Description: "A tile that can be used to display an image.",
            Icon: "fa fa-picture-o",
            D: $.Deferred()
        }, {
            FileName: 'tile_kpi',
            ModName: 'Tile_KPI',
            TileType: "AdvancedReportingKpi",
            Description: "A tile that can be used to display a KPI and opens a table on click.",
            Icon: "fa fa-hashtag",
            D: $.Deferred()
        }, {
            FileName: 'tile_piechart',
            ModName: 'Tile_PieChart',
            TileType: "AdvancedReportingPie",
            Description: "A tile that can be used to display a pie chart.",
            Icon: "fa fa-pie-chart",
            D: $.Deferred()
        }, {
            FileName: 'tile_table',
            ModName: 'Tile_Table',
            TileType: "AdvancedReportingTable",
            Description: "A tile that can be used to display a table.",
            Icon: "fa fa-table",
            D: $.Deferred()
        }
    ];

    $.when(...advancedReportingTiles.map(a => {
        return a.D;
    })).done(() => {
        d.resolve();
    })

    advancedReportingTiles.forEach(tile => {
        import(`components/advancedreporting/tiles/${tile.FileName}`).then(mod => {
            TileFactory.RegisterTileType(
                tile.TileType,
                tile.Description,
                tile.Icon,
                ReportingTileSettings,
                mod[tile.ModName]
            );
            tile.D.resolve();
        });
    });

    return d.promise();
}

interface TileModImportObj {
    FileName: string,
    ModName: string,
    TileType: string,
    Description: string,
    Icon: string
    D: JQueryDeferred<any>
}