import { Dialog } from '../dialogs/dialog';
import 'devextreme/ui/slide_out';
import { ITileInfo } from '../../interfaces/advancedreporting/interfaces';
import { TDFRequest } from '../../services/request';
import { DomSafeID, GimmeGUID, UpperCaseFirstChar } from '../../util/allutils';

export class TileSelector {
  // Stuff
  private SelectionDialog: Dialog;
  public SelectedTile: any;

  // IDs
  private BodyID: string = '';
  private SlideOutID: string = '';
  private TileVIewID: string = '';

  constructor() {
    let selector = this;
    selector.SetIDs();
  }

  SetIDs() {
    let selector = this;

    let domSafeNodeID = DomSafeID(GimmeGUID());

    selector.BodyID = `tileSelector_${domSafeNodeID}`;
    selector.SlideOutID = `slideOut_${domSafeNodeID}`;
    selector.TileVIewID = `tileView_${domSafeNodeID}`;
  }

  OpenForm(): JQueryPromise<any> {
    let selector = this;
    let dfd = $.Deferred();

    let bodyContent = `<div id='${selector.BodyID}' class='tileSeletor' />`;

    selector.SelectionDialog = new Dialog({
      title: 'Select a Tile',
      body: bodyContent,
      closable: true,
      onHidden: function(e) {
        e.component.dispose();
        return dfd.resolve(selector.SelectedTile);
        //$("#" + tileNode.TileSectionIDs.CardID).appendTo($("#" + tileNode.TileSectionIDs.TileWrapperID)); tile.RefreshUI();
      }
    });

    selector.SelectionDialog.open().done(function() {
      selector.Render();
    });

    return dfd.promise();
  }

  Render(): JQueryPromise<any> {
    let selector = this;

    let dfd = $.Deferred();

    let body: JQuery = $(`#${selector.BodyID}`);

    //let content: string = `<div id='${selector.LeftContainerID}'>Menu</div><div id='${selector.MainContainerID}'><div>Hello World</div></div>`;
    let content: string = `<div id='${selector.SlideOutID}' />`;
    body.append(content);

    selector.GetTiles().done(function(tileList) {
      function showMenu() {
        slideOut.option('menuVisible', !slideOut.option('menuVisible'));
      }

      let slideOut = $(`#${selector.SlideOutID}`)
        .dxSlideOut({
          dataSource: tileList,
          menuVisible: false,
          swipeEnabled: true,
          menuGrouped: false,
          itemTemplate: function(
            slideOutItemData,
            slideOutItemIndex,
            slideOutItemElement
          ) {
            $('<div/>')
              .appendTo(slideOutItemElement)
              .dxToolbar({
                dataSource: [
                  {
                    location: 'before',
                    widget: 'dxButton',
                    options: <DevExpress.ui.dxButtonOptions>{
                      icon: 'menu',
                      onClick: showMenu,
                      elementAttr: { id: `SelectTile_SlideOutBtn` }
                    }
                  },
                  {
                    location: 'center',
                    template: function(e) {
                      return '<h1>' + slideOutItemData.Category + ' Tiles</h1>';
                    }
                  }
                ]
              });

            $(`#SelectTile_SlideOutBtn`).mouseenter(event => {
              showMenu();
            });

            let $infoContainer = $('<div />').attr('id', 'info');
            $infoContainer.append($(`<div id='${selector.TileVIewID}' />`));

            slideOutItemElement.append($infoContainer);

            let tileView = $(`#${selector.TileVIewID}`)
              .css('padding-top', '40px')
              .dxTileView(<DevExpress.ui.dxTileViewOptions>{
                items: slideOutItemData.Tiles,
                itemTemplate: (tileData, tileIndex, tileElement) => {
                  tileElement.css('display', 'table');

                  let tileTile = $('<div />')
                    .addClass('tile-type-selector')
                    .append(
                      $('<i />')
                        .css('padding', '0px')
                        .addClass(`${tileData.Icon} fa-5x`),
                      $('<h4 />')
                        .css('margin-top', '0px')
                        .text(tileData.Name)
                    );

                  return tileTile;
                },
                onItemClick: e => {
                  selector.SelectedTile = e.itemData;
                  selector.SelectionDialog.close();
                  //reportingTile.ResetPreviewSection();

                  //new TDFRequest({
                  //    url: `${reportingTile.UrlStubTile}/${e.itemData.link ? e.itemData.link : e.itemData.text}`,
                  //    type: 'GET'
                  //}).MakeRequest().done((response: AdvancedReportingBase.TileInfo) => {
                  //    reportingTile.ShowOrHideDatasourceOptions(e.itemData.type);

                  //    reportingTile.OnSwitchTile(response, e.itemData.type);

                  //    //if (reportingTile.DatasourceFieldSelector && reportingTile.DatasourceFieldSelector.option('value')) {
                  //    //    reportingTile.DatasourceFieldSelector.option('value', null);
                  //    //}

                  //    reportingTile.Datasource = null;
                  //    reportingTile.DatasourceFields = null;

                  //    reportingTile.Wizard.UnmarkAllScreensAsCompleted();
                  //    reportingTile.Wizard.MarkScreenAsCompleted(0);
                  //    reportingTile.Wizard.GoNextPage();
                  //});
                },
                direction: 'vertical',
                showScrollbar: true,
                itemMargin: 10,
                baseItemHeight: 120,
                baseItemWidth: 120,

                onInitialized: e => {
                  let checkParent = setInterval(() => {
                    let exists = e.element.parent().length;

                    if (exists > 0) {
                      clearInterval(checkParent);
                      e.component.option('width', e.element.parent().width());
                    }
                  }, 50);
                }
              })
              .dxTileView('instance');
          },
          menuItemTemplate: function(itemData, itemIndex, itemElement) {
            //let menuItemContent: string = itemData.Category;
            //itemElement.append(menuItemContent);

            return itemData.Category;
          },
          onInitialized: e => {
            e.element
              .find('.dx-slideoutview-shield')
              .removeClass('dx-slideoutview-shield');

            e.element
              .find('.dx-slideoutview-menu-content')
              .mouseleave(event => {
                e.component.hideMenu();
              });
          }
        })
        .dxSlideOut('instance');

      return dfd.resolve();
    });

    return dfd.promise();
  }

  GetAdvancedReportingIcon(tileType: string): string {
    let selector = this;

    let tileIcons = [
      {
        type: 'Table',
        icon: 'fa fa-table'
      },
      {
        type: 'Chart',
        icon: 'fa fa-line-chart'
      },
      {
        type: 'Pie',
        icon: 'fa fa-pie-chart'
      },
      {
        type: 'Image',
        icon: 'fa fa-picture-o'
      },
      {
        type: 'Gauge',
        icon: 'fa fa-tachometer'
      },
      {
        type: 'Repeater',
        icon: 'fa fa-file-code-o'
      },
      {
        type: 'Header',
        icon: 'fa fa-code'
      },
      {
        type: 'Kpi',
        icon: 'fa fa-hashtag'
      }
    ];

    let index = tileIcons
      .map(a => {
        return a.type;
      })
      .indexOf(tileType);

    if (tileIcons[index]) {
      return tileIcons[index].icon;
    } else {
      return '';
    }
  }

  FindAdvancedReportingTileType(tile: ITileInfo): string {
    let selector = this;

    let tileType;

    switch (tile.Settings.TileType) {
      case 'html':
        if (tile.DatasourceIDs.length > 0) {
          tileType = 'Repeater';
        } else {
          tileType = 'Header';
        }
        break;

      default:
        tileType = UpperCaseFirstChar(tile.Settings.TileType);
        break;
    }

    return tileType;
  }

  GetTiles(): JQueryPromise<any> {
    let selector = this;
    let dfd: JQueryDeferred<any> = $.Deferred();

    //TODO:  Get this list from the server.
    let tileList = [];

    tileList.push({
      Category: 'General',
      Tiles: [
        {
          Name: 'Welcome Tile',
          Description:
            'Display the company logo or another custom image and a welcome message.',
          Icon: 'fa fa-user-circle',
          TileType: 'Welcome'
        },
        {
          Name: 'Daily Summary',
          Description: 'Display the standard Daily Summary.',
          Icon: 'fa fa-calendar-o',
          TileType: 'DailySummary'
        },
        {
          Name: 'BI Visualization',
          Description:
            'Display a BI Chart and customize it to use a pre-defined selection or to use your default BI summary setting.',
          Icon: 'fa fa-pie-chart',
          TileType: 'Charts'
        },
        {
          Name: 'Alert',
          Description:
            'Show an individaul web-enabled alert inside of a dashboard.',
          Icon: 'fa fa-exclamation-circle',
          TileType: 'LegacyAlert'
        },
        {
          Name: 'Alert List',
          Description:
            'Show an individaul web-enabled alert inside of a dashboard.',
          Icon: 'fa fa-exclamation-circle',
          TileType: 'Alerts'
        },
        {
          Name: 'BYOR Report',
          Description: 'Show a BYOR report inside of a dashboard.',
          Icon: 'fa fa-line-chart',
          TileType: 'LegacyBYOR'
        },
        {
          Name: 'BYOR Report List',
          Description:
            'Show a list of BYOR reports (much like the home page used to show).',
          Icon: 'fa fa-line-chart',
          TileType: 'ByorReportList'
        }
      ]
    });

    new TDFRequest({
      url: `${TDFRequest.ApiPath}/Reporting/Tile`,
      type: 'GET'
    })
      .MakeRequest()
      .done((reportingTiles: ITileInfo[]) => {
        let tileButtons = [];

        for (let i = 0, length = reportingTiles.length; i < length; i++) {
          let tileType = selector.FindAdvancedReportingTileType(
            reportingTiles[i]
          );

          tileButtons.push({
            Name: reportingTiles[i].Name,
            Description: reportingTiles[i].Description,
            Icon: selector.GetAdvancedReportingIcon(tileType),
            TileType: `AdvancedReporting${tileType}`,
            TileSettings: {
              ReportingTileID: reportingTiles[i].ID
            }
          });
        }

        tileList.push({
          Category: 'Advanced Reporting',
          Tiles: tileButtons
        });

        dfd.resolve(tileList);
      });

    return dfd.promise();
  }
}
