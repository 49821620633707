import { Dialog } from '../dialogs/dialog';
import { DialogResult } from '../../interfaces/interfaces';
import { TdFDashboardTile } from './tdfdashboardtile';
import { CloneIT, DomSafeID, GetDevice } from '../../util/allutils';

export class TileConfigurator {
  private ConfiguratorDialog: Dialog;
  private ConfiguratorToolbar: DevExpress.ui.dxToolbar;
  private Tile: TdFDashboardTile;

  // DOM IDs
  private BodyID: string;
  private LeftSectionID: string;
  private PropertiesSectionID: string;
  private PreviewSectionID: string;
  private ToolbarID: string;
  private PreviewButtonID: string;
  private ApplyButtonID: string;
  private CancelButtonID: string;
  private Canceled: boolean = false;

  constructor(tile: TdFDashboardTile) {
    let configurator = this;
    configurator.Tile = tile;

    configurator.SetIDs();
  }

  private SetIDs() {
    let configurator = this;
    let uniqueID: string = DomSafeID(configurator.Tile.TileInstanceID);

    configurator.BodyID = `configurationBody_${uniqueID}`;
    configurator.LeftSectionID = `leftSection_${uniqueID}`;
    configurator.PropertiesSectionID = `propertiesSection_${uniqueID}`;
    configurator.PreviewSectionID = `previewSection_${uniqueID}`;
    configurator.ToolbarID = `configuratorToolbar_${uniqueID}`;

    configurator.PreviewButtonID = `previewBtn_${uniqueID}`;
    configurator.ApplyButtonID = `applyBtn_${uniqueID}`;
    configurator.CancelButtonID = `cancelBtn_${uniqueID}`;
  }

  public Configure(contentElement?: JQuery): JQueryPromise<any> {
    let configurator = this;
    configurator.Canceled = false;

    let originalSettings = CloneIT(configurator.Tile.TileSettings);

    let dfd: JQueryDeferred<any> = $.Deferred();

    let bodyContent = `<div id='${
      configurator.BodyID
      }' class='row tileConfigurator'><div id='${
      configurator.LeftSectionID
      }' class='col-md-3 ConfiguratorSettingsArea'><div id='${
      configurator.ToolbarID
      }' /><div id='${configurator.PropertiesSectionID}' /> </div><div id='${
      configurator.PreviewSectionID
      }' class='col-md-9' /></div>`;

    let originalContentPlaceholder: JQuery;
    if (contentElement) {
      originalContentPlaceholder = contentElement.parent();
    }

    configurator.ConfiguratorDialog = new Dialog({
      title: 'Configure Properties...',
      body: bodyContent,
      size: 'size-large',
      closable: true,
      buttons: configurator.GetDialogButtons(),
      onHidden: function () {
        if (contentElement) {
          contentElement.appendTo(originalContentPlaceholder);
        }

        return dfd.resolve(configurator.Canceled);
      }
    }, null, true, false);

    configurator.ConfiguratorDialog.open().done(function () {
      if (contentElement) {
        contentElement.appendTo(`#${configurator.PreviewSectionID}`);
      }
      configurator.RenderSettings();
    });

    return dfd.promise();
  }

  private GetDialogButtons() {
    let configurator = this;
    let buttons = [];

    buttons.push({
      toolbar: 'bottom',
      location: 'after',
      widget: 'dxButton',
      options: {
        text: 'Preview',
        elementAttr: { id: configurator.PreviewButtonID },
        //icon: "",
        type: 'default',
        onClick(e) {
          let previewSection: JQuery = $(`#${configurator.PreviewSectionID}`);
          configurator.Tile.Render();
        }
      },
      locateInMenu: GetDevice().isDevice ? 'always' : 'auto'
    });

    buttons.push({
      toolbar: 'bottom',
      location: 'after',
      widget: 'dxButton',
      options: {
        text: 'OK',
        elementAttr: { id: configurator.ApplyButtonID },
        icon: "check",
        type: 'success',
        onClick(e) {
          configurator.Canceled = false;
          configurator.ConfiguratorDialog.close(undefined, DialogResult.OK);
        }
      },
      locateInMenu: GetDevice().isDevice ? 'always' : 'auto'
    });

    buttons.push({
      toolbar: 'bottom',
      location: 'after',
      widget: 'dxButton',
      options: {
        text: 'Cancel',
        elementAttr: { id: configurator.CancelButtonID },
        icon: "remove",
        type: 'danger',
        onClick(e) {
          configurator.Canceled = true;
          configurator.ConfiguratorDialog.close(undefined, DialogResult.Cancel);
        }
      },
      locateInMenu: GetDevice().isDevice ? 'always' : 'auto'
    });

    return buttons;
  }

  protected RenderSettings() {
    let configurator = this;
    let dfd: JQueryDeferred<any> = $.Deferred();

    let propertiesSection = $('#' + configurator.PropertiesSectionID);

    configurator.Tile.RenderSettingsForDesigner(propertiesSection).done(
      function () {
        return dfd.resolve();
      }
    );

    return dfd.promise();
  }
}
