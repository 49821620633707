import { TileBase, TileScopeType } from '../dashboards/tilebase';
import { TileFactory } from '../dashboards/tilefactory';
import { TileSettingsBase } from '../dashboards/tilesettingsbase';
import '../../../images/sherpa/customerLogo.png';

export class WelcomeTile extends TileBase {
  protected _ScopeSupportLevel: TileScopeType = TileScopeType.ScopeNotSupported;
  protected _Name: string = 'Welcome';

  constructor(
    tileInstanceID: string,
    settings: Partial<TileSettingsBase>,
    settingsClassRef: typeof TileSettingsBase = TileSettingsBase
  ) {
    super(tileInstanceID, settings, settingsClassRef);
  }

  public Render(contentElement: JQuery): JQueryPromise<any> {
    let alertsObj = this;
    let dfd: JQueryDeferred<any> = $.Deferred();

    let content: string = `<img class="cust-logo in" id="home-welcome" src="/images/customerLogo.png" alt="">`;

    $(contentElement).append(content);

    return dfd.promise(dfd.resolve());
  }
}

//TileFactory.RegisterTileType(
//  "Welcome",
//  "A tile that welcomes the user and shows the company logo.",
//  "",
//  TileSettingsBase,
//  WelcomeTile
//);
