import { eventNameSpace, EventTypes } from "../../enums/webevents/enums";
import { RaiseEvent2 } from "../../infrastructure/events/ui_events";
import { Preferences } from "../../infrastructure/user/preferences";
import { AlertService } from "../../services/tiles/alertservice";
import dxDataSource from "devextreme/data/data_source";
import { TileBase, TileScopeType } from "../dashboards/tilebase";
import { TileSettingsBase } from "../dashboards/tilesettingsbase";
import { GetDevice, DomSafeID } from "../../util/allutils";
import dxArrayStore from "devextreme/data/array_store";
import { TDFRequest } from "../../services/request";
import {SingleAlert} from "./alerts"

export class AlertInfo {
  MailingID: string;
  AlertID: number;
}

export class LegacyAlertTileSettings extends TileSettingsBase {
  SelectedAlertInfo: AlertInfo;
}

export class LegacyAlertTile extends TileBase {
  protected _ScopeSupportLevel: TileScopeType = TileScopeType.ScopeNotSupported;
  protected _Name: string = "Alert";

  private AlertContainerID: string = "";
  private AlertTitleContainerID: string = "";
  private AlertContentContainerID: string = "";
  private AlertTitleSelectBoxID: string = "";
  private AlertSpinnerID: string = "";

  constructor(
    tileInstanceID: string,
    tileSettings: LegacyAlertTileSettings,
    settingsClassRef: typeof TileSettingsBase = LegacyAlertTileSettings
  ) {
    super(tileInstanceID, tileSettings, settingsClassRef);

    let legacyAlertTile = this;
    legacyAlertTile.SetIDs();
  }

  protected Render(contentElement: JQuery) {
    let legacyAlertTile = this;
    let dfd: JQueryDeferred<any> = $.Deferred();

    let mySettings: LegacyAlertTileSettings = <LegacyAlertTileSettings>legacyAlertTile.Settings;

    let content: string = `<div id="${legacyAlertTile.AlertContainerID}">
                              <div id="${legacyAlertTile.AlertSpinnerID}" />
                              <div id="${legacyAlertTile.AlertContentContainerID}" />
                          </div>`;

    $(contentElement).append(content);

    var request = new TDFRequest({
      url: "/Alert/Data/",
      type: "Get",
      data: {
        mailingid: mySettings.SelectedAlertInfo.MailingID,
        alertid: mySettings.SelectedAlertInfo.AlertID
      }
    });
    request.spinnerContainerID = legacyAlertTile.AlertSpinnerID;
    request.MakeRequest().done(function (data) {
      if (data) {
        SingleAlert.MakeAlertDataGrid(mySettings.SelectedAlertInfo.AlertID, data, $(`#${legacyAlertTile.AlertContentContainerID}`));
      } else {
        let subscribeBtnID: string = `subscribeBtn${DomSafeID(
          legacyAlertTile.TileInstanceID
        )}`;

        //TODO:  Add in check to see if the user is subscribed or not.  Is it worth actually checking the subscription info?  this would be another call to look up the info every time this tile loads.
        let html = `<div>No data was returned by this alert.  You may not be subscribed.  Click here to <div id='${subscribeBtnID}' /></div>`;

        $(`#${legacyAlertTile.AlertContentContainerID}`).append(html);

        $(`#${subscribeBtnID}`).dxButton({
          onClick: function () {
            Preferences.UpdateAlertEnrollment(
              mySettings.SelectedAlertInfo.MailingID,
              mySettings.SelectedAlertInfo.AlertID,
              undefined,
              1
            );
            //TODO:  There might be a slight issue here if the update alert enrollment doesn't happen in a timely fashion and the tile might re-render with the "subscribe" message.
            RaiseEvent2(
              EventTypes.DashboardEventTypes.RefreshTile,
              DomSafeID(legacyAlertTile.TileInstanceID),
              eventNameSpace.dashboard
            );
          },
          //type: "info",
          text: "Subscribe"
        });
      }

      legacyAlertTile.RenderTitle().done(function () {
        return dfd.resolve();
      });
    });

    return dfd.promise();
  }

  public RenderTitle() {
    let legacyAlertTile = this;
    let dfd: JQueryDeferred<any> = $.Deferred();

    let mySettings: LegacyAlertTileSettings = <LegacyAlertTileSettings>legacyAlertTile.Settings;

    AlertService.AvailableAlertList().done(function (alertList) {
      let items = alertList.filter(function (item, index) {
        if (
          item.MailingID === mySettings.SelectedAlertInfo.MailingID &&
          item.ID === mySettings.SelectedAlertInfo.AlertID
        ) {
          return item;
        }
      });

      let titleString = "Title not found.";
      if (items && items.length > 0) {
        titleString = items[0].Description;
      }

      RaiseEvent2(
        EventTypes.DashboardEventTypes.updateTileTitle,
        legacyAlertTile.TileInstanceID,
        eventNameSpace.dashboard,
        { Title: titleString }
      );

      return dfd.resolve();
    });

    return dfd.promise();
  }

  public GetConfigurableTileSettings() {
    let legacyAlertTile = this;

    let dfd = $.Deferred();

    legacyAlertTile.GetAlertList().done(function (alertList) {
      let mappedData = alertList.map(function (item) {
        return {
          Description: item.Description,
          MailingID: item.MailingID,
          AlertID: item.ID
        };
      });

      let items = [
        {
          dataField: "TileSettings.SelectedAlertInfo",
          editorType: "dxSelectBox",
          editorOptions: {
            elementAttr: {
              id: legacyAlertTile.AlertTitleSelectBoxID
            },
            dataSource: new dxDataSource({
              store: new dxArrayStore({
                data: mappedData
              })
            }),
            displayExpr: "Description",
            width: GetDevice().isDevice ? "125px" : "300px",
            searchEnabled: true,
            searchExpr: "Description"
          }
        }
      ];

      dfd.resolve(items);
    });

    return dfd.promise();
  }

  private GetAlertList(): JQueryPromise<any> {
    var legacyAlertTile = this;
    let dfd: JQueryDeferred<any> = $.Deferred();

    // I am passing true her to force it to get the list of available alerts from the server.  This then solves the case where a new alert is added and the user is trying to add it to a dashboard.
    AlertService.AvailableAlertList(true).done(function (data) {
      return dfd.resolve(data);
    });

    return dfd.promise();
  }

  private SetIDs() {
    let legacyAlertTile = this;
    let domSafeTileID = DomSafeID(legacyAlertTile.TileInstanceID);

    legacyAlertTile.AlertContainerID = `alertArea_${domSafeTileID}`;
    legacyAlertTile.AlertSpinnerID = `alertSpinner_${domSafeTileID}`;
    legacyAlertTile.AlertTitleContainerID = `alertTitle_${domSafeTileID}`;
    legacyAlertTile.AlertContentContainerID = `alertContent_${domSafeTileID}`;
    legacyAlertTile.AlertTitleSelectBoxID = `alertTitleSelectBox_${domSafeTileID}`;
  }
}

//TileFactory.RegisterTileType("LegacyAlert", "A tile that can be used to display a legacy alert.", "fa fa-exclamation-circle",LegacyAlertTileSettings, LegacyAlertTile);
