import { Notification } from '../dialogs/notification';
import { Debugger } from '../../infrastructure/debugger';
import { WebEvents } from '../../infrastructure/events/ui_events';
//import { GetCompanyPreference } from "../../infrastructure/user/getpreference";
import {
  iDashboard,
  iDashboardSection
} from '../../interfaces/dashboards/idashboarddescriptor';
import { DashboardSaveOptions } from './dashboardsaveoptions';
import { DashboardSection } from './dashboardsection';
import { DashboardSectionFactory } from './dashboardsectionfactory';
import { DashboardService } from './dashboardservice';
import {
  DashboardSummarySettings,
  iDashboardSummarySettings
} from './dashboardsummarysettings';
import { MyDashboard } from './mydashboards';
import { TdFDashboardTile } from './tdfdashboardtile';
import { TileSelector } from './tileselector';
import { eventNameSpace, EventTypes } from '../../enums/webevents/enums';
import {
  GimmeGUID,
  DomSafeID,
  GlobalStrings,
  GetDevice
} from '../../util/allutils';
import 'jquery-ui/widgets/sortable';
import { Preferences } from '../../infrastructure/user/preferences';
import { CurrentUser } from '../../infrastructure/context';
import {
  DashboardVisibility,
  DashboardMode,
  DashboardSaveAsOption
} from './dashboardengine';
import { CloneIT } from '../../util/allutils';

export interface iDashboardDescriptor {
  ID: string;
  Name: string;
  Visibility: DashboardVisibility;
}

export interface iDashboard extends iDashboardDescriptor {
  Owner: string;
  Sections: iDashboardSection[];
  SummarySettings?: iDashboardSummarySettings;
}

export enum LoadStyle {
  LoadImmediate = 0,
  LoadOnClick = 1,
  LoadDelayed = 2
}

export class DashboardIDs {
  public LayoutContainerID: string;
  public SaveFormBodyID: string;
}

export class DashboardLimits {
  MaxNumTiles: number;
  MaxByorTiles: number;
}

export class Dashboard {
  private readonly DesignerLayoutClass: string = 'BlueprintGrid';

  private DashboardStructure: iDashboard;
  private DashboardSections: DashboardSection[] = [];

  public _Mode: DashboardMode; // TODO:  Make this private and set the mode differently from the engine.
  public get Mode(): DashboardMode {
    let myDashboard = this;
    return myDashboard._Mode;
  }

  Visibility: DashboardVisibility = DashboardVisibility.Global;

  private summarySettings: DashboardSummarySettings;
  public get SummarySettings(): DashboardSummarySettings {
    let myDashboard = this;
    return myDashboard.summarySettings;
  }

  private ItemList: TdFDashboardTile[] = [];
  private _Name: string = 'My Home Page Dashboard';
  public get Name() {
    let myDashboard = this;
    return myDashboard._Name;
  }
  public set Name(name: string) {
    let myDashboard = this;
    myDashboard._Name = name;
  }

  private _Owner: string;
  public get Owner(): string {
    let myDashboard = this;
    return myDashboard._Owner;
  }

  public ID: string = GimmeGUID();

  private myFrameIDs: DashboardIDs;
  public get FrameIDs(): DashboardIDs {
    let myDashboard = this;
    if (!myDashboard.myFrameIDs) {
      let domSafeDashboardID = DomSafeID(myDashboard.ID);

      myDashboard.myFrameIDs = new DashboardIDs();
      myDashboard.myFrameIDs.LayoutContainerID = `dashboardContainer_${domSafeDashboardID}`;
      myDashboard.myFrameIDs.SaveFormBodyID = `dashboardSaveForm_${domSafeDashboardID}`;
    }

    return myDashboard.myFrameIDs;
  }

  public get IsGlobal(): boolean {
    let myDashboard = this;
    return myDashboard.Visibility === DashboardVisibility.Global;
  }

  public SharedAsInitialLoad: boolean;

  private _DashboardLimitsObj: DashboardLimits;
  public get DashboardLimitsObj(): DashboardLimits {
    let dashEngine = this;

    if (dashEngine._DashboardLimitsObj) {
      return dashEngine._DashboardLimitsObj;
    } else {
      dashEngine._DashboardLimitsObj = {
        MaxNumTiles: parseInt(Preferences.GetCompanyPreference('MaxNumberOfTilesPerDashboard', 'TDFMobile', '20')),
        MaxByorTiles: parseInt(Preferences.GetCompanyPreference('MaxNumberOfByorTilesPerDashboard', 'TDFMobile', '1'))
      };

      return dashEngine._DashboardLimitsObj;
    }
  }

  public get TileCount(): number {
    let myDashboard = this;

    return myDashboard.ItemList.length;
  }

  constructor(dashboardModel: iDashboard, mode: DashboardMode = DashboardMode.Designer, sharedAsDefault: boolean = false) {
    let myDashboard = this;

    myDashboard.DashboardStructure = dashboardModel;
    myDashboard._Name = dashboardModel.Name;
    myDashboard.Visibility = dashboardModel.Visibility;
    myDashboard.ID = dashboardModel.ID;
    myDashboard._Mode = mode;
    myDashboard.SharedAsInitialLoad = sharedAsDefault;
    myDashboard.summarySettings = new DashboardSummarySettings(dashboardModel.SummarySettings);
  }

  //TOOD:  Make private? and use eventing?
  public Save(saveOptions: DashboardSaveOptions): JQueryPromise<any> {
    let myDashboard = this;

    let d: JQueryDeferred<any> = $.Deferred();
    // Update the ID, Name, and Visibility when saving as a different dashboard.
    if (!(saveOptions.SaveAsOption === DashboardSaveAsOption.Overwrite) || myDashboard.ID === MyDashboard.GlobalDashboardID) {
      myDashboard.ID = GimmeGUID();
    }

    if (saveOptions.DashboardName != myDashboard.Name) {
      myDashboard._Name = saveOptions.DashboardName;
    }

    if (saveOptions.SaveAsOption === DashboardSaveAsOption.CreateAsGlobal) {
      myDashboard._Owner = 'Global';
      myDashboard.Visibility = DashboardVisibility.Global;
    } else if (saveOptions.SaveAsOption === DashboardSaveAsOption.CreateAsPersonal) {
      myDashboard._Owner = CurrentUser.ID;
      myDashboard.Visibility = DashboardVisibility.Personal;
    } else {
      if (myDashboard.Visibility === DashboardVisibility.Global) {
        myDashboard._Owner = 'Global';
      } else {
        myDashboard._Owner = CurrentUser.ID;
      }
    }

    myDashboard.SummarySettings.ApplyNewScope(saveOptions.SummarySettingOption);

    DashboardService.SaveDashboard(myDashboard).done(function () {
      myDashboard.MarkClean();
      d.resolve();
    });

    return d.promise();
  }

  public RegisterTile(node: TdFDashboardTile) {
    let myDashboard = this;
    myDashboard.ItemList.push(node);
  }

  public UnregisterTile(ID: string) {
    let myDashboard = this;
    let indexToUnregister = -1;

    for (let i = 0, length = myDashboard.ItemList.length; i < length; i++) {
      if (myDashboard.ItemList[i].TileInstanceID === ID) {
        indexToUnregister = i;
      }
    }

    if (indexToUnregister !== -1) {
      myDashboard.ItemList.splice(indexToUnregister, 1);
    }
  }

  public RenderTilesByID(idList: string[]): JQueryPromise<any> {
    let myDashboard = this;
    let dfd: JQueryDeferred<any> = $.Deferred();

    let tilesToRender: TdFDashboardTile[] = myDashboard.ItemList.filter(function (item, index) {
      let includeItem: boolean = false;

      for (let i = 0, len = idList.length; i < len; i++) {
        if (item.TileInstanceID === idList[i]) {
          includeItem = true;
          break;
        }
      }

      return includeItem ? item : null;
    });

    myDashboard.RenderTheseTiles(tilesToRender).done(function () {
      return dfd.resolve();
    });

    return dfd.promise();
  }

  private ContentElement: JQuery;
  public Render(contentElement: JQuery) {
    let myDashboard = this;

    myDashboard.RenderLayoutAndRegisterTiles(contentElement);
    myDashboard.RegisterListeners();
    myDashboard.InitSortableColumns();
    myDashboard.RenderTiles();
  }

  private InitSortableColumns() {
    let myDashboard = this;
    WebEvents.Event.RaiseEvent(EventTypes.DashboardEventTypes.InitializeSortableTileLists, eventNameSpace.dashboard);
  }

  private ScrollView: DevExpress.ui.dxScrollView;
  private RenderLayoutAndRegisterTiles(contentElement: JQuery) {
    let myDashboard = this;

    let layoutClass = myDashboard.Mode == DashboardMode.Designer ? myDashboard.DesignerLayoutClass : '';

    myDashboard.ScrollView = $('<div />').dxScrollView().dxScrollView('instance');

    myDashboard.ScrollView.content().append(`<div id='${myDashboard.FrameIDs.LayoutContainerID}' />`);

    $(contentElement).addClass(layoutClass).append(myDashboard.ScrollView.element());

    myDashboard.ContentElement = $(`#${myDashboard.FrameIDs.LayoutContainerID}`);

    $.each(myDashboard.DashboardStructure.Sections, function (index, section: iDashboardSection) {
      let mySection = DashboardSectionFactory.CreateDashboardSection(section, myDashboard);
      myDashboard.DashboardSections.push(mySection);
      mySection.Render(myDashboard.ContentElement);
    });

    myDashboard.InitializeSectionSortableLists();
  }

  private InitializeSectionSortableLists() {
    let myDashboard = this;

    let selector = `#${myDashboard.myFrameIDs.LayoutContainerID}`;

    $(`${selector}`).sortable({
      disabled: myDashboard.Mode === DashboardMode.Viewer,
      cursorAt: { left: 50, top: 35 },
      distance: 10,
      tolerance: 'pointer',
      connectWith: '.BlueprintGrid',

      update: function (event, tdfTileUI) {
        Debugger.Log('Update called for ' + $(this).attr('id'));
        myDashboard.UpdateSectionOrder();
      }
    });
  }

  private RefreshSectionSortableList() {
    let myDashboard = this;
    let selector = `#${myDashboard.myFrameIDs.LayoutContainerID}`;
    $(`${selector}`).sortable('refresh');
  }

  private UpdateSectionOrder() {
    let myDashboard = this;

    let sectionSortable = $(`#${myDashboard.myFrameIDs.LayoutContainerID}`);

    let sectionOrderedList = $(sectionSortable).sortable('toArray').filter(a => { return a.length > 0; });

    let newSectionList: DashboardSection[] = [];

    $.each(sectionOrderedList, function (idIndex, sectionElementID) {
      let sectionInstanceID = $(`#${sectionElementID}`).data('SectionID');
      let currentSection: DashboardSection;
      let currentSectionIndex: number = -1;

      $.each(myDashboard.DashboardSections, function (index, dashboardSection) {
        if (dashboardSection.ID === sectionInstanceID) {
          currentSection = dashboardSection;
          currentSectionIndex = index;
        }
      });

      if (currentSection) {
        newSectionList.push(currentSection);
        myDashboard.DashboardSections.splice(currentSectionIndex, 1);
      }
    });

    myDashboard.DashboardSections = newSectionList;
  }

  private LoadTileSelector() {
    let myDashboard = this;

    let tileSelector = new TileSelector();
    tileSelector.OpenForm().done(function (selectedItem) {
      // TODO:  Should this use the limiter on the event?
      if (selectedItem) {
        let numOfByorTiles = myDashboard.ItemList.filter(a => { return a.TileType === 'LegacyBYOR'; }).length;

        if (selectedItem.TileType === 'LegacyBYOR' && numOfByorTiles >= myDashboard.DashboardLimitsObj.MaxByorTiles) {
          new Notification({ message: `The maximum number of BYOR Tiles for this Dashboard has been reached. This tile has not been added. (Max = ${myDashboard.DashboardLimitsObj.MaxByorTiles})`, type: 'error' });
        } else {
          WebEvents.Event.RaiseEvent(EventTypes.DashboardEventTypes.tileSelectorClosed, eventNameSpace.dashboard, selectedItem);
        }
      }
    });
  }

  // Event Handlers
  private RegisterListeners() {
    let myDashboard = this;

    // Add Listeners
    WebEvents.Event.AddHandler(EventTypes.DashboardEventTypes.loadTileSelector, eventNameSpace.dashboard, myDashboard.ContentElement, myDashboard.LoadTileSelector.bind(myDashboard));
    WebEvents.Event.AddHandler(EventTypes.DashboardEventTypes.InitializeSortableTileLists, eventNameSpace.dashboard, myDashboard.ContentElement, myDashboard.InitializeTileSortableLists.bind(myDashboard));
    WebEvents.Event.AddHandler(EventTypes.DashboardEventTypes.RefreshSortableTileLists, eventNameSpace.dashboard, myDashboard.ContentElement, myDashboard.RefreshTileSortableLists.bind(myDashboard));
    WebEvents.Event.AddHandler(EventTypes.DashboardEventTypes.TileDetached, eventNameSpace.dashboard, myDashboard.ContentElement, myDashboard.CatchDetachedTile.bind(myDashboard));
    WebEvents.Event.AddHandler(EventTypes.DashboardEventTypes.DashboardScope, eventNameSpace.request, myDashboard.ContentElement, myDashboard.HandleDashboardScopeRequest.bind(myDashboard));
    WebEvents.Event.AddHandler(EventTypes.DashboardEventTypes.dashboardModeChanged, eventNameSpace.dashboard, myDashboard.ContentElement, myDashboard.HandleDashboardModeChanged.bind(myDashboard));
    WebEvents.Event.AddHandler(EventTypes.DashboardEventTypes.RemoveSection, eventNameSpace.dashboard, myDashboard.ContentElement, myDashboard.RemoveSection.bind(myDashboard));
    WebEvents.Event.AddHandler2(EventTypes.DashboardEventTypes.AddSection, myDashboard.ID, eventNameSpace.dashboard, myDashboard.ContentElement, myDashboard.AddSection.bind(myDashboard));
    WebEvents.Event.AddHandler2(EventTypes.DashboardEventTypes.ChangeSectionType, myDashboard.ID, eventNameSpace.dashboard, myDashboard.ContentElement, myDashboard.ChangeSectionType.bind(myDashboard));
    WebEvents.Event.AddHandler2(EventTypes.DashboardEventTypes.DisplayDashboardScope, myDashboard.ID, eventNameSpace.request, myDashboard.ContentElement, myDashboard.DisplaySummarySettings.bind(myDashboard));
    WebEvents.Event.AddHandler(EventTypes.DashboardEventTypes.MarkDashboardDirty, eventNameSpace.dashboard, myDashboard.ContentElement, myDashboard.MarkDirty.bind(myDashboard));
  }

  private MarkDirty() {
    window[GlobalStrings.DashboardDirty] = true;
  }

  private MarkClean() {
    if (window[GlobalStrings.DashboardDirty]) {
      delete window[GlobalStrings.DashboardDirty];
    }
  }

  public get IsDirty(): boolean {
    return window[GlobalStrings.DashboardDirty];
  }

  private DisplaySummarySettings(e: JQueryEventObject, data) {
    let myDashboard = this;
    if (data.elem) {
      myDashboard.SummarySettings.LoadEditor($(data.elem), myDashboard.Mode);
    }
  }

  private HandleDashboardModeChanged(e: JQueryEventObject, data) {
    let myDashboard = this;

    if (myDashboard.Mode === DashboardMode.Designer) {
      $(`#${myDashboard.FrameIDs.LayoutContainerID}`).parent().addClass(myDashboard.DesignerLayoutClass);

      myDashboard.ScrollView.option('height', () => { return window.innerHeight - 150; });
      myDashboard.ScrollView.option('showScrollbar', 'never');
      myDashboard.ScrollView.element().find('.dx-scrollable-container:first').css({ overflow: 'auto' });
    } else {
      $(`#${myDashboard.FrameIDs.LayoutContainerID}`).parent().removeClass(myDashboard.DesignerLayoutClass);

      myDashboard.ScrollView.option('height', 'auto');
      myDashboard.ScrollView.option('showScrollbar', GetDevice().isDevice ? 'onScroll' : 'onHover');
      myDashboard.ScrollView.element().find('.dx-scrollable-container:first').css({ overflow: 'hidden' });
    }

    $(`#${myDashboard.myFrameIDs.LayoutContainerID}`).sortable('option', 'disabled', !(myDashboard.Mode === DashboardMode.Designer));
  }

  private HandleDashboardScopeRequest(e: JQueryEventObject, data) {
    let myDashboard = this;
    data.deferred.resolve(myDashboard.SummarySettings.ActiveScope);
  }

  private detachedTiles: TdFDashboardTile[] = [];
  private CatchDetachedTile(e: JQueryEventObject, data) {
    let myDashboard = this;
    myDashboard.detachedTiles.push(data.DetachedTile);
  }

  private InitializeTileSortableLists(e: JQueryEventObject, data) {
    let myDashboard = this;

    let selector = '.tdfSectionColumnFrame';

    if (data && data.ColumnID) {
      selector = '#' + data.ColumnID;
    }

    $(`${selector}`).sortable({
      connectWith: '.tdfSectionColumnFrame',
      cursorAt: { left: 50, top: 37 },
      distance: 10,
      tolerance: 'pointer',
      scroll: true,
      disabled: myDashboard.Mode === DashboardMode.Viewer,
      remove: function (e, ui) {
        myDashboard.MarkDirty();

        Debugger.Log('Remove called for ' + $(this).attr('id'));
        let changedList = this.id;
        var order = $(this).sortable('toArray');
        Debugger.Log({ id: changedList, count: order.length, items: order });
        let sectionID = $(this).data('SectionID');

        let removedTileID = $(ui.item).data('TileID');
        // Raise an event on the individual section to remove the tile from the array.
        WebEvents.Event.RaiseEvent2(EventTypes.DashboardEventTypes.TileLayoutTileRemoved, sectionID, eventNameSpace.dashboard, { TileID: removedTileID });
      },
      receive: function (e, tdfTileUI) {
        myDashboard.MarkDirty();

        Debugger.Log('Receive called for ' + $(this).attr('id'));
        let changedList = this.id;
        var order = $(this).sortable('toArray');

        Debugger.Log({ id: changedList, count: order.length, items: order });

        let sectionID = $(this).data('SectionID');
        let columnName = $(this).data('ColumnName');
        let receivedTileID = $(tdfTileUI.item).data('TileID');

        let receivedTileIndex: number = -1;
        $.each(myDashboard.detachedTiles, function (index, detachedTile) {
          if (detachedTile.TileInstanceID === receivedTileID) {
            receivedTileIndex = index;
          }
        });

        if (receivedTileIndex != -1) {
          let detachedTile = myDashboard.detachedTiles[receivedTileIndex];
          myDashboard.detachedTiles.splice(receivedTileIndex, 1);
          // Raise an event to the section to send the detached tile over.
          WebEvents.Event.RaiseEvent2(EventTypes.DashboardEventTypes.TileLayoutTileReceived, sectionID, eventNameSpace.dashboard, { Tile: detachedTile, NewColumnName: columnName, NewColumnID: changedList });
        }
      },
      update: function (event, tdfTileUI) {
        myDashboard.MarkDirty();

        Debugger.Log('Update called for ' + $(this).attr('id'));
        let changedList = this.id;
        var order = $(this).sortable('toArray');
        Debugger.Log({ id: changedList, count: order.length, items: order });
      }
    });
  }
  private RefreshTileSortableLists(e: JQueryEventObject, data) {
    let myDashboard = this;

    let selector = '.tdfSectionColumnFrame';

    if (data && data.ColumnID) {
      selector = '#' + data.ColumnID;
    }

    $(`${selector}`).sortable('refresh');
  }

  private get NonEmptySections(): DashboardSection[] {
    let myDashboard = this;
    let sectionList = myDashboard.DashboardSections.filter(function (section, index) {
      if (section.Tiles.length > 0) {
        return section;
      }

      return null;
    });

    return sectionList;
  }

  toJSON(key) {
    let myDashboard = this;

    let it = {
      ID: myDashboard.ID,
      Name: myDashboard.Name,
      Owner: myDashboard.Owner,
      Visibility: myDashboard.Visibility,
      Sections: myDashboard.NonEmptySections,
      SummarySettings: myDashboard.SummarySettings
    };

    return it;
  }

  private AddSection(e, data) {
    let myDashboard = this;

    let newDashboardSection: iDashboardSection = {
      SectionType: data.SectionInfo.SectionType,
      Tiles: []
    };

    myDashboard.DashboardStructure.Sections.push(newDashboardSection);

    let mySection = DashboardSectionFactory.CreateDashboardSection(newDashboardSection, myDashboard);
    myDashboard.DashboardSections.push(mySection);
    mySection.Render($(`#${myDashboard.FrameIDs.LayoutContainerID}`));

    if (myDashboard.DashboardSections && myDashboard.DashboardSections.length > 0) {
      $(`#${mySection.SectionWrapperID}`).insertBefore($(`#${myDashboard.DashboardSections[0].SectionWrapperID}`));
    }

    let guidList = newDashboardSection.Tiles.map((tile: any, index: number) => { return tile.TileInstanceID; });

    let newTiles = myDashboard.ItemList.filter(function (item, index) {
      return guidList.some(e => e === item.TileInstanceID) ? item : null;
    });

    myDashboard.RenderTheseTiles(newTiles);
    myDashboard.RefreshSectionSortableList();
    myDashboard.UpdateSectionOrder();

    myDashboard.InitSortableColumns();
  }

  private RemoveSection(e, data) {
    let myDashboard = this;

    let indexToRemove: number = -1;

    for (let i = 0, length = myDashboard.DashboardSections.length; i < length; i++) {
      if (myDashboard.DashboardSections[i].ID === data.ID) {
        indexToRemove = i;
      }
    }

    if (indexToRemove !== -1) {
      $(`#${myDashboard.DashboardSections[indexToRemove].SectionWrapperID}`).remove();
      myDashboard.DashboardSections.slice(indexToRemove, 1);
      myDashboard.MarkDirty();
    }
  }

  private ChangeSectionType(e, data) {
    let myDashboard = this;

    let updatedTiles = data.OldSectionInfo.Tiles.map(a => {
      let b = CloneIT(a);
      b.Column = a.Column % data.NewSectionInfo.NumOfColumns;
      return b;
    });

    let newDashboardSection: iDashboardSection = {
      SectionType: data.NewSectionInfo.SectionType,
      Tiles: updatedTiles
    };

    let newSection = DashboardSectionFactory.CreateDashboardSection(newDashboardSection, myDashboard);

    let indexToReplace = -1;

    for (let i = 0, length = myDashboard.DashboardSections.length; i < length; i++) {
      if (myDashboard.DashboardSections[i] === data.OldSectionInfo) {
        indexToReplace = i;
      }
    }

    if (indexToReplace !== -1) {
      $(`#${myDashboard.DashboardSections[indexToReplace].SectionWrapperID}`).empty();

      myDashboard.DashboardSections.push(newSection);

      newSection.Render($(`#${myDashboard.FrameIDs.LayoutContainerID}`));

      $(`#${myDashboard.DashboardSections[myDashboard.DashboardSections.length - 1].SectionWrapperID}`).insertAfter(
        $(`#${myDashboard.DashboardSections[indexToReplace].SectionWrapperID}`)
      );

      $(`#${myDashboard.DashboardSections[indexToReplace].SectionWrapperID}`).remove();

      myDashboard.DashboardSections.splice(indexToReplace, 1);

      myDashboard.RenderTiles();
      myDashboard.UpdateSectionOrder();
      myDashboard.MarkDirty();
    }
  }

  private RenderTiles() {
    let myDashboard = this;
    let immediateTiles = myDashboard.ItemList.filter(function (item, index) {
      return item.ItemLoadStyle == LoadStyle.LoadImmediate ? item : null;
    });

    let delayedTiles = myDashboard.ItemList.filter(function (item, index) {
      return item.ItemLoadStyle == LoadStyle.LoadDelayed ? item : null;
    });

    // TODO:  Continue to work on how this figures out the order and when to render a particular tile.
    myDashboard.RenderTheseTiles(immediateTiles).done(function () {
      myDashboard.RenderTheseTiles(delayedTiles);
    });
  }

  private RenderTheseTiles(tileList: TdFDashboardTile[]): JQueryPromise<any> {
    let myDashboard = this;
    let wrapperDFD: JQueryDeferred<any> = $.Deferred();
    let dfds: JQueryPromise<any>[] = [];

    tileList.forEach(function (item: TdFDashboardTile, index: number) {
      dfds.push(item.Render());
    });

    //TODO:  Handle errors occurring and promises not resolving
    $.when.apply($, dfds).done(function () {
      return wrapperDFD.resolve();
    });

    return wrapperDFD.promise();
  }
}