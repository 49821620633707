import * as Globalize from "globalize";
import * as moment from "moment";
import { itemTypes } from "../../enums/enums";
import { GetPreference } from "../../infrastructure/user/getpreference";
import { Preferences } from "../../infrastructure/user/preferences";
import { Grid } from "../../legacy-centers-grids/grid";
import { TDFRequest } from "../../services/request";
import { TileBase, TileScopeType } from "../dashboards/tilebase";
import { TileSettingsBase } from "../dashboards/tilesettingsbase";
import { Dialog } from "../dialogs/dialog";
import { Notification } from "../dialogs/notification";
import { ItemBase } from "../items/itembase";
import { createitem, openitem } from "../items/factory/itemfactory";
import {
  DomSafeID,
  GetDevice,
  Debounce,
  LegacyFloatingMenu,
  RenderCompleteConvertDialog
} from "../../util/allutils";
import "devextreme/ui/form";
import "devextreme/ui/list";
import "devextreme/ui/tile_view";
import "devextreme/ui/toolbar";
import "devextreme/ui/accordion";
import "devextreme/ui/scheduler";
import dxDataSource from "devextreme/data/data_source";
import dxCustomStore from "devextreme/data/custom_store";
import {
  DisplayInfo,
  CurrentUser,
  LoadUser
} from "../../infrastructure/context";
import { EditGrid } from "../../legacy-centers-grids/editgrid";
export class DailySummaryTileSettings extends TileSettingsBase {
  Month?: number;
  Day?: number;
  Year?: number;
  NavMode?: number;
  UseBatchMode?: boolean;
}

export class DailySummary extends TileBase {
  public HasInteractiveSettings: boolean = true;
  protected _ScopeSupportLevel: TileScopeType = TileScopeType.AlwaysUsesHomeList;
  protected _Name: string = "Daily Summary";

  readonly AllItemsPath = "/core/user/Daily/";
  private SettingsFullItemList: string[];
  private readonly DailyActivityPath: string = "/core/user/DailyActivities/";
  private readonly DailyAppointmentPath: string = "/core/user/DailyAppointments/";
  private readonly DailyExpensePath: string = "/core/user/DailyExpenses/";
  private readonly DailyTaskPath: string = "/core/user/DailyTasks/";
  private readonly DailyReminderPath: string = "/core/user/DailyReminders/";
  private readonly DailyOpportunityPath: string = "/core/user/DailyOpportunities/";
  /*
         * Where 0 = Daily | 1 = Weekly | 2 = Monthly
         * */
  NavMode: number = 0;
  UseBatchMode: boolean;
  AccordionElement: DevExpress.ui.dxAccordion;
  DataStore: DevExpress.data.CustomStoreOptions;
  Month: number;
  Day: number;
  Year: number;
  FocusedDate: { start: moment.Moment; end: moment.Moment };
  Toolbar: DevExpress.ui.dxToolbar;
  DailyData: any[];
  CalData: any[];
  IsCalendarNavigation: boolean = false;

  // IDs
  private DailySummaryContainerID: string;
  private DailyToolbarContainerID: string;
  private DailySpinnerContainerID: string;
  private ActionSheetTargetID: string;

  private get DailySummarySettings(): DailySummaryTileSettings {
    let dailysum = this;
    return <DailySummaryTileSettings>dailysum.Settings;
  }

  private get CurrentView() {
    let dailysum = this;
    switch (dailysum.NavMode) {
      case 0:
        return "day";
      case 1:
        return "week";
      case 2:
        return "month";
    }
    return "month";
  }

  constructor(
    tileInstanceID: string,
    tileSettings: DailySummaryTileSettings,
    settingsClassRef: typeof TileSettingsBase = DailySummaryTileSettings
  ) {
    super(tileInstanceID, tileSettings, settingsClassRef);

    let dailysum = this;
    dailysum.InitializeSettings();
    dailysum.SetIDs();
  }

  protected Render(contentElement: JQuery): JQueryPromise<any> {
    let dailysum = this;
    let dfd: JQueryDeferred<any> = $.Deferred();

    dailysum.UpdateFocusedDate().done(() => {
      let toolbar = dailysum.RenderToolbar();
      let content = $("<div />").append(
        toolbar.element(),
        $(`<div id="${dailysum.DailySpinnerContainerID}"></div>`),
        $(`<div id = "${dailysum.DailySummaryContainerID}"></div>`)
      );

      $(contentElement).append(content);
    });

    dailysum.RequestData().done(() => {
      dfd.resolve();
    });

    //TODO:  Make this resolved more correctly.
    return dfd.promise();
  }

  public GetConfigurableTileSettings(): JQueryPromise<
    Array<
      | DevExpress.ui.dxFormSimpleItem
      | DevExpress.ui.dxFormGroupItem
      | DevExpress.ui.dxFormTabbedItem
      | DevExpress.ui.dxFormEmptyItem
      | DevExpress.ui.dxFormButtonItem
    >
  > {
    let daily = this;
    let dfd: JQueryDeferred<any> = $.Deferred();

    let items = daily.GetSettingItems();
    return dfd.promise(dfd.resolve(items));
  }

  public GetInteractiveTileSettings(): JQueryPromise<
    Array<
      | DevExpress.ui.dxFormSimpleItem
      | DevExpress.ui.dxFormGroupItem
      | DevExpress.ui.dxFormTabbedItem
      | DevExpress.ui.dxFormEmptyItem
      | DevExpress.ui.dxFormButtonItem
    >
  > {
    let daily = this;
    let dfd: JQueryDeferred<any> = $.Deferred();

    let items = daily.GetSettingItems();
    return dfd.promise(dfd.resolve(items));
  }

  private InitializeSettings() {
    let dailysum = this;
    dailysum.Month = dailysum.DailySummarySettings.Month
      ? dailysum.DailySummarySettings.Month
      : moment().month() + 1; // Note:  Moment counts the months with a 0-based index
    dailysum.Day = dailysum.DailySummarySettings.Day
      ? dailysum.DailySummarySettings.Day
      : moment().date();
    dailysum.Year = dailysum.DailySummarySettings.Year
      ? dailysum.DailySummarySettings.Year
      : moment().year();

    LoadUser().done(() => {
      dailysum.NavMode = dailysum.DailySummarySettings.NavMode
        ? dailysum.DailySummarySettings.NavMode
        : parseInt(
          Preferences.GetPreference("DailySummaryNavMode", "TDFMobile")
        );
      dailysum.UseBatchMode =
        Preferences.GetPreference("DailySummaryUseBatchMode", "TDFMobile") ===
        "1";
      dailysum.UseBatchMode = true;

      // Ticket 20153:  Update the starting date if nothing was passed in to setup the Week and Month to show the current week or month, not the next 7 or 30 days.
      if (
        !dailysum.DailySummarySettings.Month &&
        !dailysum.DailySummarySettings.Day &&
        !dailysum.DailySummarySettings.Year
      ) {
        switch (dailysum.NavMode) {
          case 1:
            dailysum.Day = moment()
              .startOf("week")
              .date();
            dailysum.Month =
              moment()
                .startOf("week")
                .month() + 1; // Note:  Moment counts the months with a 0-based index
            dailysum.Year = moment()
              .startOf("week")
              .year();
            break;
          case 2:
            dailysum.Day = moment()
              .startOf("month")
              .date();
            break;
        }
      }
    });
  }

  private SetIDs() {
    let dailySum = this;
    let domSafeTileID = DomSafeID(dailySum.TileInstanceID);

    dailySum.DailySummaryContainerID = `daily-sum-content-${domSafeTileID}`;
    dailySum.DailyToolbarContainerID = `dailytoolbar-${domSafeTileID}`;
    dailySum.DailySpinnerContainerID = `dailyspinner-${domSafeTileID}`;
    dailySum.ActionSheetTargetID = `dailySettings-${domSafeTileID}`;
  }

  private RequestData(): JQueryPromise<any> {
    let dailysum = this;
    let dfd: JQueryDeferred<any> = $.Deferred();

    dailysum.UpdateFocusedDate().done(() => {
      if (dailysum.UseBatchMode) {
        if (dailysum.AccordionElement) {
          dailysum.AccordionElement.option("dataSource", []);
        }

        dailysum.BatchRequest().done(function () {
          return dfd.resolve();
        });
      } else {
        dailysum.UpdateContainer().done(function () {
          return dfd.resolve();
        });
      }
    });

    return dfd.promise();
  }
  //TODO: Cache Data so as the user navigates we dont need to go to the server every time for data we already had.
  private BatchRequest(): JQueryPromise<any> {
    let dailysum = this;
    let d: JQueryDeferred<any> = $.Deferred();
    let settings = {
      Day: dailysum.Day,
      Month: dailysum.Month,
      Year: dailysum.Year,
      NavMode: dailysum.NavMode
    };
    dailysum
      .getEnabledItems()
      .load()
      .done(function (response) {
        let requests: Array<any> = [];
        dailysum.DailyData = response;
        $.each(response, function (key, val) {
          let thisrequest = new TDFRequest({
            url: dailysum["Daily" + val.TypeName + "Path"],
            data: settings,
            type: "GET"
          });
          thisrequest.spinnerContainerID = dailysum.DailySpinnerContainerID;
          requests.push(thisrequest.MakeRequest());
        });

        $.when.apply($, requests).done(function () {
          let length = arguments.length - 1;
          let counter = 0;
          dailysum.DailyData = [];
          for (counter; counter <= length; counter++) {
            dailysum.DailyData.push(arguments[counter]);
            if (!dailysum.CalData) {
              dailysum.CalData = [];
            }
            arguments[counter].Data.forEach(val => {
              let item = dailysum.CalData.filter(k => {
                return k["TDF GUID"] === val["TDF GUID"];
              });
              if (!item.length) {
                dailysum.CalData.push(val);
              }
            });
          }
          dailysum.UpdateFocusedDate().done(() => {
            dailysum.UpdateContainer().done(() => {
              d.resolve();
            });
          });
        });
      });

    return d.promise();
  }

  private UpdateFocusedDate() {
    let dailysum = this;
    let d = $.Deferred();
    let end: moment.Moment;
    let dString: string =
      dailysum.Year + "/" + dailysum.Month + "/" + dailysum.Day;

    switch (dailysum.NavMode) {
      case 0:
        end = moment(dString, "YYYY/MM/DD");
        break;
      case 1:
        // Week
        end = moment(dString, "YYYY/MM/DD")
          .add(1, "week")
          .add("-1", "days"); // Adding a week, then subtracting a day to make the end day the last day of the week.
        break;
      case 2:
        // Month
        end = moment(dString, "YYYY/MM/DD")
          .add(1, "month")
          .add("-1", "days"); // Adding a month, then subtracting a day to make the end day the last day of the month.
        break;
    }
    dailysum.FocusedDate = {
      start: moment(dString, "YYYY/MM/DD"),
      end: end
    };
    return d.promise(d.resolve());
  }

  private getEnabledItems() {
    let dailsum = this;
    let options: DevExpress.data.CustomStoreOptions;
    let lopts: DevExpress.data.LoadOptions;

    return new dxDataSource({
      load: function () {
        let d = $.Deferred();
        let request = new TDFRequest({
          url: "/core/user/DailySummaryItems",
          type: "GET"
        });
        request.spinnerContainerID = dailsum.DailySpinnerContainerID;
        request.MakeRequest().done(function (response: any) {
          dailsum.DailyData = response;
          d.resolve(response);
        });
        if (!dailsum.UseBatchMode && dailsum.DailyData) {
          d.promise(d.resolve(dailsum.DailyData));
        }
        return d.promise();
      }
    });
  }

  private UpdateContainer(): JQueryPromise<any> {
    let dailysum = this;
    let dfd: JQueryDeferred<any> = $.Deferred();
    dailysum.UpdateFocusedDate().done(() => {
      /*
                 * Need To add reminders to the enum and get it in the correct place if the user wants to see it
                 */
      let opts: DevExpress.ui.dxAccordionOptions = {
        collapsible: true,
        selectedIndex: -1
      };

      if (!dailysum.UseBatchMode) {
        opts.dataSource = dailysum.getEnabledItems();
        opts.itemTemplate = function (itemData, itemIndex, itemElement) {
          itemElement.attr("id", "Daily" + itemData.TypeName);
          let request = new TDFRequest({
            url: dailysum["Daily" + itemData.TypeName + "Path"],
            data: {
              Day: dailysum.Day,
              Month: dailysum.Month,
              Year: dailysum.Year,
              NavMode: dailysum.NavMode
            },
            type: "POST"
          });

          request.spinnerContainerID = dailysum.DailySpinnerContainerID;
          request.MakeRequest().done(function (response: any | any) {
            let columns = dailysum[itemData.TypeName + "Cols"]();
            let args = { Data: response.Data, GridContainer: itemElement };
            let opts = {
              columns: columns
            };
            let grid = new Grid(
              args,
              { GridContainer: itemElement },
              {
                selection: {
                  mode: "multiple", // itemData.TypeName === "Appointment" || itemData.TypeName === "Task" ? "multiple" : "none",
                  showCheckBoxesMode: "always"
                },
                height: $(window).innerHeight() * 0.5,
                scrolling: {
                  mode: "virtual"
                },
                groupPanel: { visible: true },
                filterRow: {
                  visible: true,
                  applyFilter: "auto"
                },
                headerFilter: {
                  visible: true
                },
                searchPanel: {
                  visible: $(window).innerWidth() > 480,
                  highlightSearchText: true
                },
                onToolbarPreparing: function (e: any) {
                  dailysum.btnCompleteConvert(itemData, e, grid);
                },
                allowColumnResizing: true,
                columnAutoWidth: true,
                columns: columns,
                showColumnLines: true,
                showRowLines: false,
                rowAlternationEnabled: true,
                showBorders: false,
                grouping: { autoExpandAll: false },
                columnResizingMode: 'nextColumn'
              },
              dailysum
            );
            grid.Render();
          });
        };
      } else {
        opts.items = dailysum.DailyData;
        opts.itemTemplate = function (itemData, itemIndex, itemElement) {
          itemElement.attr("id", "Daily" + itemData.TypeName);
          let columns = dailysum[itemData.TypeName + "Cols"]();
          let args = { Data: itemData.Data, GridContainer: itemElement };
          let opts = {
            columns: columns
          };

          args["GridView"] = {
            Layout: {
              Columns: columns,
              ViewID: ""
            }
          };
          let grid = new Grid(
            args,
            { GridContainer: itemElement },
            {
              selection: {
                mode: "multiple", // itemData.TypeName === "Appointment" || itemData.TypeName === "Task" ? "multiple" : "none",
                showCheckBoxesMode: "always"
              },
              height: $(window).innerHeight() * 0.5,

              scrolling: {
                mode: "virtual"
              },
              groupPanel: { visible: true },
              filterRow: {
                visible: true,
                applyFilter: "auto"
              },
              headerFilter: {
                visible: true
              },
              searchPanel: {
                visible: $(window).innerWidth() > 480,
                highlightSearchText: true
              },
              onToolbarPreparing: function (e: any) {
                dailysum.btnCompleteConvert(itemData, e, grid);
              },
              allowColumnResizing: true,
              columnAutoWidth: true,
              columns: columns,
              showColumnLines: true,
              showRowLines: false,
              rowAlternationEnabled: true,
              showBorders: false,
              grouping: { autoExpandAll: false },
              columnResizingMode: "widget",
              columnFixing: { enabled: true }
            },
            dailysum,
            GetDevice().isDevice
              ? dailysum.CardSeleectionEvent.bind(dailysum)
              : $.noop
          );
          grid.Render();
        };
        opts.onItemClick = e => {
          if ($(e.event.target).hasClass("tdf-dailysummary-new-button")) {
            e.event.stopImmediatePropagation();
            if (e.itemData.ItemType === itemTypes.itemExpense) {
              let args = {
                new: true,
                Description:
                  "New Expense " +
                  Globalize.formatDate(new Date(), { date: "long" }),
                Dirty: true,
                ExpenseAmount: 0,
                ExpenseDate: moment().format("MM/DD/YYYY"),
                MileageID: 0
              };
              createitem(itemTypes.itemExpense, args, true, false);
            } else {
              createitem(e.itemData.ItemType, {}, true, false);
            }
          }

        };
        opts.itemTitleTemplate = function (itemData, index, itemElement) {
          let template = `<div class="recent-favorite-title">    
                                                <div class="daily-new">
                                                <span class="dx-icon dx-icon-plus tdf-dailysummary-new-button"></span>
                                                </div> 
                                                 <div class="daily-icon">
                                                <span class="dx-icon tdfitem${
            itemData.ItemType
            } tdf-dailysummary-item-icon"></span>
                                                </div>                                                  
                                                 <div class="daily-text" >
                                                <strong style="white-space:normal;word-wrap:break-word">${
            itemData.DisplayName
            }</strong>
                                                </div> 
                                                <div class="daily-badge" >
                                                <small class="badge"> ${
            itemData.Data && itemData.Data
              ? itemData.Data.length || 0
              : 0
            }</small>
                                                </div> 
                                   </div>`;

          return template;
        };
      }

      dailysum.AccordionElement = $(`#${dailysum.DailySummaryContainerID}`)
        .dxAccordion(opts)
        .dxAccordion("instance");

      if (dailysum.Toolbar) {
        dailysum.Toolbar.option("items", dailysum.ToolbarItems());
        dailysum.Toolbar.endUpdate();
      }

      return dfd.resolve();
    });

    return dfd.promise();
  }

  private RenderToolbar() {
    let dailysum = this;
    dailysum.Toolbar = $(`<div />`)
      .attr("id", `#${dailysum.DailyToolbarContainerID}`)
      .dxToolbar({
        onItemClick: Debounce(
          e => {
            if (
              e.itemData &&
              e.itemData.options &&
              typeof e.itemData.options.value !== "undefined"
            ) {
              dailysum.NavMode = e.itemData.options.value;
              dailysum.updateNavMode();
            }
          },
          500,
          false
        )
      })
      .dxToolbar("instance");
    dailysum.Toolbar.beginUpdate();

    return dailysum.Toolbar;
  }

  private CardSeleectionEvent(e) {
    let dailysum = this;
    //   if (e.selectedRowsData.length) {
    LegacyFloatingMenu("", e.selectedRowsData);
    //  }
  }

  private updateNavMode() {
    let dailysum = this;
    switch (dailysum.NavMode) {
      case 0:
        dailysum.Day = moment().date();
        break;
      case 1:
        dailysum.Day = moment()
          .startOf("week")
          .date();
        dailysum.Month =
          moment()
            .startOf("week")
            .month() + 1; // Note:  Moment counts the months with a 0-based index
        dailysum.Year = moment()
          .startOf("week")
          .year();
        break;
      case 2:
        dailysum.Day = moment()
          .startOf("month")
          .date();
        break;
    }
    dailysum.RequestData();
    Preferences.SetPreference(
      "DailySummaryNavMode",
      dailysum.NavMode,
      "TDFMobile"
    );
  }

  private BatchDataSource() {
    let dailsum = this;

    return new dxDataSource({
      load: function () {
        let d = $.Deferred();
        let request = new TDFRequest({
          url: "/core/user/DailySummaryItems",
          type: "GET"
        });
        request.MakeRequest().done(function (response: any) {
          dailsum.DailyData = response;

          $.when(function () {
            let dfd = $.Deferred();

            return dfd.promise();
          }).done(function () {
            d.resolve(response);
          });

          //d.resolve(response)
        });
        return d.promise();
      }
    });
  }

  private Today() {
    let dailysum = this;

    dailysum.Month = moment().month() + 1;
    dailysum.Day = moment().date();
    dailysum.Year = moment().year();
    dailysum.NavMode = 0;

    dailysum.RequestData();
  }

  private MoveDay(args) {
    let dailysum = this;

    let thedate = dailysum.FocusedDate.start;
    thedate = thedate.add(dailysum.ConvertNavMode() as string, args.value);
    dailysum.Month = thedate.month() + 1;
    dailysum.Day = thedate.date();
    dailysum.Year = thedate.year();
    dailysum.RequestData();
  }

  private ProperDateField(type) {
    switch (type) {
      case 2:
        return "TDF_ActivityDate";
      case 3:
        return "TDF_StartTime";
      case 8:
        return "ProjectedClose";
      case 10:
        return "TDF_DueDate";
      case 12:
        return "ExpenseDate";
      case 996:
        return "Reminder";
    }
  }

  private ShowEventsCalendar() {
    let dailysum = this;
    let div = $("<div />").attr("id", "UserCalendar");
    let legend = $("<div />");
    let container = $("<div />");
    let itemOpened = false;
    container.append(legend).append(div);
    //  dxOverlay.baseZIndex(2000);
    let cal = new Dialog({
      body: container,
      size: "size-wide",
      closable: true,
      title: "Calendar"
    });
    cal.open().done(function () {
      $(div).dxScheduler(<any>{
        dataSource: new dxDataSource({
          store: new dxCustomStore({
            load: function (options: any) {
              let d = $.Deferred();
              //let request = new TDFRequest({
              //    url: "/home/calendar/", type: "POST", data: {
              //        justmine: 'true',
              //        start: moment(options.dxScheduler.startDate).format("YYYY/MM/DD"),
              //        enddate: moment(options.dxScheduler.endDate).format("YYYY/MM/DD")
              //    }
              //});
              //request.MakeRequest().done(function (response: any) {
              //    if (true) {
              //        d.resolve(response)
              //    }
              //});
              if (dailysum.IsCalendarNavigation) {
                dailysum.BatchRequest().done(() => {
                  d.resolve(dailysum.CalData);
                });
              } else {
                d.promise(d.resolve(dailysum.CalData));
              }
              //d.resolve(dailysum.CalData);
              return d.promise();
            }
          }),
          map: function (dataItem) {
            let allDay = [2, 10, 12, 996, 8];
            dataItem.allDay =
              $.inArray(parseInt(dataItem.ItemType), allDay) > -1;
            dataItem.startDate =
              dataItem[dailysum.ProperDateField(parseInt(dataItem.ItemType))];
            dataItem.endDate = !(
              $.inArray(parseInt(dataItem.ItemType), allDay) > -1
            )
              ? dataItem["TDF_EndTime"]
              : dataItem[dailysum.ProperDateField(parseInt(dataItem.ItemType))];
            dataItem.text =
              dataItem.Subject ||
              dataItem.ReminderSubject ||
              dataItem.Description;
            dataItem.description = dataItem.Notes;

            //dataItem.allDay = moment(dataItem.EndTime).get("hour") - moment(dataItem.StartTime).get("hour") >= 12;
            //dataItem.startDate = dataItem.StartTime;
            //dataItem.endDate = dataItem.EndTime;
            //dataItem.text = dataItem.Subject;
            //dataItem.description = dataItem.Notes;
            return dataItem;
          }
        }),
        currentDate: dailysum.FocusedDate.start.toDate(),
        views: [
          "agenda",
          "day",
          "month",
          { name: "Months", type: "month", intervalCount: 2 },
          "timelineDay",
          "timelineWeek",
          "timelineWorkWeek",
          "timelineMonth",
          "week",
          "workWeek"
        ],
        currentView: dailysum.CurrentView,
        useDropDownViewSwitcher: true,
        shadeUntilCurrentTime: true,
        showCurrentTimeIndicator: true,
        firstDayOfWeek: 0,
        //  groups:GetDevice().isDevice ? [] : ["ItemType"],
        appointmentTooltipTemplate: function (data, content) { },
        onAppointmentDblClick(e) {
          e.cancel = true;
        },
        onAppointmentClick: Debounce(
          function (e) {
            e.cancel = true;
            openitem(parseInt(e.appointmentData.ItemType), e.appointmentData['TDF GUID']);
            itemOpened = true;

          },
          500,
          true
        ),
        //dropDownAppointmentTemplate(data, index, container) {

        //    var markup = $("<div class='appointment-content'>" +
        //        "<div class='appointment-badge' >" + data.text[0] +
        //        "</div>" +
        //        "<div class='appointment-text'>" +
        //        data.text +
        //        "</div>" +
        //        "<div class='appointment-dates'>" + Globalize.formatDate(new Date(data.startDate), { skeleton: "MMMd" }) +
        //        " , " + Globalize.formatDate(new Date(data.startDate), { time: "short" }) +
        //        " - " + Globalize.formatDate(new Date(data.endDate), { time: "short" }) +
        //        "</div>" +
        //        "</div>");

        //    return markup;
        //},
        onAppointmentFormOpening: data => {
          if (itemOpened) {
            div.dxScheduler("instance").hideAppointmentPopup(false);
            itemOpened = false;
          } else {
            let form: DevExpress.ui.dxForm = data.form;


            let popup = $($(".dx-scheduler-appointment-popup")[0]).dxPopup(
              "instance"
            );

            popup.option("toolbarItems", []);
            popup.option("height", "600px");

            let tileViewContainer = $("<div />");

            form.option("items", [
              {
                template: (data, itemElement) => {
                  itemElement.append(tileViewContainer);
                }
              }
            ]);

            let tiles = [];

            new TDFRequest({ url: "/Settings/DailySummaryItems/" })
              .MakeRequest()
              .done(function (response: any) {
                $.each(response, (k, v) => {
                  tiles.push(<DevExpress.ui.dxTileViewItemTemplate>{
                    //text: 'Create ' + v.singularDisplayText,
                    onClick: e => {
                      if (
                        v.ItemType === itemTypes.itemReminder ||
                        v.ItemType === itemTypes.itemExpense
                      ) {

                        createitem(v.ItemType, {
                          TypeName: v.itemTypeName,
                          ItemType: v.ItemType,
                          isNew: true,
                          PresetDate: new Date(data.appointmentData.startDate)
                        }, true)
                        //TODO:Figure this out when not in namespace TDF
                        // let newItem: ItemBase = new window[v.itemTypeName]({
                        //   TypeName: v.itemTypeName,
                        //   ItemType: v.ItemType,
                        //   isNew: true,
                        //   PresetDate: new Date(data.appointmentData.startDate)
                        // });
                        // newItem.Create();
                      } else {
                        //TODO:Figure this out when not in namespace TDF
                        // let newItem: ItemBase = new window[v.itemTypeName]({
                        //   TypeName: v.itemTypeName,
                        //   ItemType: v.ItemType,
                        //   isNew: true
                        // });

                        createitem(v.ItemType, {
                          TypeName: v.itemTypeName,
                          ItemType: v.ItemType,
                          isNew: true
                        }, true)
                          .done((newItem) => {
                            newItem.ItemForm.dfd.done(() => {
                              let startDateControl;
                              let endDateControl;

                              switch (v.ItemType) {
                                case itemTypes.itemActivity:
                                  startDateControl = newItem.GetControlByTDFField(
                                    "ActivityDate"
                                  );
                                  break;

                                case itemTypes.itemAppointment:
                                  startDateControl = newItem.GetControlByTDFField(
                                    "StartTime"
                                  );
                                  endDateControl = newItem.GetControlByTDFField(
                                    "EndTime"
                                  );
                                  break;

                                case itemTypes.itemTask:
                                  //Not sure if this needs to be due date instead.
                                  startDateControl = newItem.GetControlByTDFField(
                                    "StartDate"
                                  );
                                  break;

                                case itemTypes.itemOpportunity:
                                  startDateControl = newItem.GetControlByTDFField(
                                    "ProjectedClose"
                                  );
                                  break;
                              }

                              if (startDateControl) {
                                startDateControl.Dx.option(
                                  "value",
                                  new Date(data.appointmentData.startDate)
                                );
                              }
                              if (endDateControl) {
                                endDateControl.Dx.option(
                                  "value",
                                  new Date(data.appointmentData.endDate)
                                );
                              }
                            });
                          });
                      }
                      popup.hide();
                    },
                    html:
                      '<div style="text-align: center; padding-top: 40%" >Create ' +
                      v.singularDisplayText +
                      "</div>"
                  });
                });

                let tileview: DevExpress.ui.dxTileView = tileViewContainer
                  .dxTileView(<DevExpress.ui.dxTileViewOptions>{
                    items: tiles,
                    direction: "vertical"
                  })
                  .dxTileView("instance");
              });
          }
        },
        onAppointmentRendered: () => { },
        startDayHour: 6,
        endDayHour: 19,
        resources: [
          {
            field: "ItemType",
            allowMultiple: false,
            dataSource: [
              {
                text: "Activity",
                id: 2,
                color: "#cb6bb2"
              },
              {
                text: "Appointment",
                id: 3,
                color: "#56ca85"
              },
              {
                text: "Task",
                id: 10,
                color: "#1e90ff"
              },
              {
                text: "Expense",
                id: 12,
                color: "#ff9747"
              },
              {
                text: "Reminder",
                id: 996,
                color: "#495057"
              }
            ],

            label: "Type"
          }
        ],
        onOptionChanged(e) {
          if (e.name && e.name === "currentDate") {
            e.component.option("dataSource").items([]);
            dailysum.Year = (e.value as Date).getFullYear();
            dailysum.Month = (e.value as Date).getMonth() + 1;
            dailysum.Day = (e.value as Date).getDate();
            dailysum.IsCalendarNavigation = true;
          }
          if (e.name && e.name === "currentView") {
            (dailysum.NavMode as any) = dailysum.ConvertNavMode(e.value);
            dailysum.updateNavMode();
          }
        },
        editing: {
          allowDragging: false,
          allowUpdating: false
        },
        //onCellClick: function (e) {
        //    // TODO: Get some indicator the user has selected  the date then allow them to close and update the summary...
        //    //let d = e.cellData.startDate;
        //    //dailysum.FocusedDate.start = moment(d);
        //    //dailysum.UpdateFocusedDate();

        //    // Note:  This bypasses the double-click event that allows items to be added via the calender view.
        //    // We have not connected the code for that to work, so we're just going to prevent it for now.
        //   // e.cancel = true;
        //},
        height: cal.getModalBody().height() - 75
      });

      $.when(
        DisplayInfo(2),
        DisplayInfo(3),
        DisplayInfo(10),
        DisplayInfo(12),
        DisplayInfo(996)
      ).done((info2, info3, info10, info12, info996) => {
        $(legend)
          .css("text-align", "center")
          .append(
            $("<strong />")
              .text(info2.DisplayNamePlural)
              .css({ color: "#cb6bb2", "margin-right": "3px" })
          )
          .append(
            $("<strong />")
              .text(info3.DisplayNamePlural)
              .css({ color: "#56ca85", "margin-right": "3px" })
          )
          .append(
            $("<strong />")
              .text(info10.DisplayNamePlural)
              .css({ color: "#1e90ff", "margin-right": "3px" })
          )
          .append(
            $("<strong />")
              .text(info12.DisplayNamePlural)
              .css({ color: "#ff9747", "margin-right": "3px" })
          )
          .append(
            $("<strong />")
              .text(info996.DisplayNamePlural)
              .css({ color: "#495057", "margin-right": "3px" })
          );

        $("<div />")
          .css("text-align", "center")
          .text("(Double click a cell to create one of the above items)")
          .insertAfter(legend);
      });
    });
  }

  private ToolbarItems() {
    let dailysum = this;

    let toolbarItems: DevExpress.ui.dxToolbarItemTemplate[] = [
      dailysum.btnDailyNavBack(),
      dailysum.btnDailyCalendar(),
      dailysum.btnDailNavForward(),
      dailysum.txtCurrentDateRange(),
      dailysum.btnDailyToday(),
      dailysum.btnDailyRefresh(),
      dailysum.btnNavModeDaily(),
      dailysum.btnNavModeWeekly(),
      dailysum.btnNavModeMonthly()
    ];
    return toolbarItems;
  }

  private txtCurrentDateRange(): DevExpress.ui.dxToolbarItemTemplate {
    let dailysum = this;

    //let html = `<div>
    //            <small>${Globalize.formatDate(dailysum.FocusedDate.start.toDate(), { date: "short" })}</small>
    //            ${dailysum.FocusedDate.end.diff(dailysum.FocusedDate.start) ? `<small> - ${Globalize.formatDate(dailysum.FocusedDate.end.toDate(), { date: "short" })}</small>`:''}
    //            </div>`;
    let device = GetDevice().device;
    return {
      location: "center",
      locateInMenu: "never", // device.phone?"always":"auto",

      text: `${Globalize.formatDate(dailysum.FocusedDate.start.toDate(), {
        date: "short"
      })} 
${
        dailysum.FocusedDate.end &&
          dailysum.FocusedDate.end.diff(dailysum.FocusedDate.start)
          ? `- ${Globalize.formatDate(dailysum.FocusedDate.end.toDate(), {
            date: "short"
          })}`
          : ""
        }`
      //html:html
      //template: function (itemData, itemIndex, itemElement) {
      //    let mnuItem = $("<div />");
      //    $(mnuItem).append($("<span />").text(Globalize.formatDate(dailysum.FocusedDate.start.toDate(), { date: "short" })));
      //    if (dailysum.FocusedDate.end.diff(dailysum.FocusedDate.start) !== 0) {
      //        $(mnuItem).append($("<span />").text(" - " + Globalize.formatDate(dailysum.FocusedDate.end.toDate(), { date: "short" })));
      //    }
      //    itemElement.append(mnuItem);
      //}
    };
  }

  private btnNavModeMonthly(): DevExpress.ui.dxToolbarItemTemplate {
    let dailysum = this;
    //let html = `<div class='${dailysum.NavMode === 2 ?'bg-success':''}'>
    //            Monthly
    //            </div>
    //            `;
    return {
      locateInMenu: GetDevice().isDevice ? "always" : "auto",
      widget: "dxButton",
      location: "after",
      options: {
        text: "Monthly",
        icon: dailysum.NavMode === 2 ? "fa fa-check" : "",
        type: dailysum.NavMode === 2 ? "warning" : "normal",
        value: 2
      }
      //locateInMenu: 'always',
      //options: {
      //    value: 2
      //},
      //html:html
    };
  }

  private btnNavModeWeekly(): DevExpress.ui.dxToolbarItemTemplate {
    let dailysum = this;
    //let html = `<div class='${dailysum.NavMode === 1 ? 'bg-success' : ''}'>
    //            Weekly
    //            </div>`;
    return {
      locateInMenu: GetDevice().isDevice ? "always" : "auto",
      widget: "dxButton",
      location: "after",
      options: {
        text: "Weekly",
        icon: dailysum.NavMode === 1 ? "fa fa-check" : "",
        type: dailysum.NavMode === 1 ? "warning" : "normal",
        value: 1
      }
      //locateInMenu: 'always',
      //options: {
      //    value: 1
      //},
      //html:html
    };
  }

  private btnNavModeDaily(): DevExpress.ui.dxToolbarItemTemplate {
    let dailysum = this;
    let html = `<div class='${dailysum.NavMode === 0 ? "bg-success" : ""}'>
                        Daily
                        </div>`;
    return {
      locateInMenu: GetDevice().isDevice ? "always" : "auto",
      widget: "dxButton",
      location: "after",
      options: {
        text: "Daily",
        icon: dailysum.NavMode === 0 ? "fa fa-check" : "",
        type: dailysum.NavMode === 0 ? "warning" : "normal",
        value: 0
      }

      //html:html
    };
  }

  private btnDailyToday(): DevExpress.ui.dxToolbarItemTemplate {
    let dailysum = this;
    return {
      location: "before",
      locateInMenu: GetDevice().device.phone ? "always" : "auto",
      widget: "dxButton",
      options: {
        type: "default",
        icon: "fa fa-calendar-check-o",
        text: "Today",
        onClick: Debounce(
          function () {
            dailysum.Today();
          },
          500,
          false
        )
      }
    };
  }

  private btnDailNavForward(): DevExpress.ui.dxToolbarItemTemplate {
    let dailysum = this;
    return {
      location: "before",
      widget: "dxButton",
      options: {
        type: "normal",
        icon: "chevronright",
        onClick: Debounce(
          function () {
            dailysum.MoveDay({ value: 1 });
          },
          500,
          false
        )
      }
    };
  }

  private btnDailyCalendar(): DevExpress.ui.dxToolbarItemTemplate {
    let dailysum = this;
    return {
      location: "before",
      locateInMenu: "auto", //GetDevice().device.phone ? "always":"auto",
      widget: "dxButton",
      showText: GetDevice().isDevice ? "inMenu" : "always",
      options: {
        type: "normal",
        icon: "fa fa-calendar-o",
        text: "Calendar",
        onClick: () => {
          dailysum.ShowEventsCalendar();
        }
      }
    };
  }

  private btnDailyNavBack(): DevExpress.ui.dxToolbarItemTemplate {
    let dailysum = this;
    return {
      location: "before",
      widget: "dxButton",
      options: {
        type: "normal",
        icon: "chevronleft",
        onClick: Debounce(
          function () {
            dailysum.MoveDay({ value: -1 });
          },
          500,
          false
        )
      }
    };
  }

  private btnDailyRefresh(): DevExpress.ui.dxToolbarItemTemplate {
    let dailysum = this;
    return {
      location: "after",
      widget: "dxButton",
      locateInMenu: GetDevice().device.phone ? "always" : "auto",
      showText: "inMenu",
      options: {
        type: "normal",
        icon: "fa fa-refresh ",
        hint: "Refresh Summary Data",
        text: "Refresh",
        onClick: Debounce(
          function () {
            dailysum.CalData = null;
            dailysum.RequestData();
          },
          500,
          false
        )
      }
    };
  }

  protected btnCompleteConvert(itemData, e: any, grid: EditGrid) {
    let dailysum = this;
    if (
      itemData.ItemType === itemTypes.itemAppointment ||
      itemData.ItemType === itemTypes.itemTask
    ) {
      e.toolbarOptions.items.push({
        location: "center",
        locateInMenu: "auto",
        widget: "dxButton",
        options: {
          icon: "icon-completeConvert completeConvert",
          text: "Complete and Convert Selected",
          onClick: function (e) {
            let theData = grid.GridObject.getSelectedRowsData();
            let ids = [];

            $.each(theData, function (k, v) {
              if (!v["ConvertedToActivity"]) {
                ids.push(v["TDF GUID"]);
              }
            });
            if (ids.length) {
              dailysum.CompleteAndConvertItems(ids, itemData.ItemType);
            } else {
              new Notification({
                message: `Please select one or more ${
                  itemData.DisplayName
                  } that have not already been converted.`,
                type: "warning"
              });
            }
          }
        }
      });
    }
  }

  private CompleteAndConvertItems(items, type) {
    let dailysum = this;
    if (items.length <= 0) {
      new Notification({
        type: "warning",
        shading: true,
        displayTime: 5000,
        message: "Please select one or more Items."
      });
      return;
    }
    RenderCompleteConvertDialog(type, items);
  }

  private ConvertNavMode(mode?) {
    let dailysum = this;
    if (mode) mode = mode.toLowerCase();
    let theMode = mode || dailysum.NavMode;
    switch (theMode) {
      case 0:
        return "days";
      case 1:
        return "weeks";
      case 2:
        return "months";
      case "days":
      case "day":
      case "timelineDay":
        return 0;
      case "weeks":
      case "week":
      case "workWeek":
      case "timelineWeek":
      case "timelineWorkWeek":
      case "agenda":
        return 1;
      case "months":
      case "month":
      case "timelineMonth":
        return 2;
    }
  }

  private AddToList(response: any) {
    let dailysum = this;
    if (!dailysum.DailyData) dailysum.DailyData = [];
    dailysum.DailyData.push(response);
    if (dailysum.DailyData.length === 5) {
      dailysum.UpdateContainer();
    }
  }

  private GetSettingItems(): Array<
    | DevExpress.ui.dxFormSimpleItem
    | DevExpress.ui.dxFormGroupItem
    | DevExpress.ui.dxFormTabbedItem
    | DevExpress.ui.dxFormEmptyItem
    | DevExpress.ui.dxFormButtonItem
  > {
    let daily = this;

    let items: Array<
      | DevExpress.ui.dxFormSimpleItem
      | DevExpress.ui.dxFormGroupItem
      | DevExpress.ui.dxFormTabbedItem
      | DevExpress.ui.dxFormEmptyItem
      | DevExpress.ui.dxFormButtonItem
    > = [];

    items = [
      <DevExpress.ui.dxFormButtonItem>{
        name: "ConfigureDailySumBtn",
        label: { visible: false },
        editorType: "dxButton",
        editorOptions: {
          type: "info",
          text: "Configure Settings",
          onClick: () => {
            daily.GetDailySummarySettings();
          }
        }
      }
    ];
    return items;
  }

  private GetDailySummarySettings() {
    let daily = this;

    let formitems = [
      {
        name: "DailySummaryTasksToDisplay",
        label: { text: "Task Filter" },
        editorType: "dxSelectBox",
        colSpan: 1,
        dataField: "DailySummaryTasksToDisplay",
        editorOptions: {
          dataSource: [
            { value: 0, text: "Show Tasks Assigned To Me" },
            { value: 1, text: "Show Tasks I am the Owner" },
            {
              value: 2,
              text: "Show Tasks I am Owner Or that are Assigned To Me"
            },
            { value: 3, text: "Show All Tasks In Home List" }
          ],
          displayExpr: "text",
          valueExpr: "value",
          value: parseInt(
            Preferences.GetPreference("DailySummaryTasksToDisplay", "TDFMobile")
          ),
          onValueChanged: function (e) {
            Preferences.SetPreference(
              "DailySummaryTasksToDisplay",
              e.value,
              "TDFMobile"
            );
          }
        }
      },
      {
        name: "ShowPastDueTasks",
        label: { text: "Include Past Due Tasks" },
        editorType: "dxCheckBox",
        colSpan: 2,
        dataField: "ShowPastDueTasks",
        editorOptions: {
          value:
            parseInt(
              Preferences.GetPreference("ShowPastDueTasks", "TDFMobile")
            ) === 1,
          onValueChanged: function (e) {
            let val = e.value === true ? 1 : 0;
            Preferences.SetPreference("ShowPastDueTasks", val, "TDFMobile");
          }
        }
      },
      {
        name: "DailySummaryItemSource",
        label: { text: "Other Summary Item Filter " },
        editorType: "dxSelectBox",
        colSpan: 1,
        dataField: "DailySummaryItemSource",
        editorOptions: {
          dataSource: [
            { value: 0, text: "All In Home List" },
            { value: 1, text: "I am Owner Or I Am Attending" }
          ],
          value: parseInt(
            Preferences.GetPreference("DailySummaryItemSource", "TDFMobile")
          ),
          displayExpr: "text",
          valueExpr: "value",
          onValueChanged: function (e) {
            Preferences.SetPreference(
              "DailySummaryItemSource",
              e.value,
              "TDFMobile"
            );
          }
        }
      },
      {
        name: "DailySummaryItemOrder",
        label: { text: "Summary Item Order" },
        editorType: "dxList",
        colSpan: 1,
        dataField: "DailySummaryItemOrder",
        editorOptions: {
          elementAttr: { id: "lstItemOrder" },
          dataSource: new dxCustomStore({
            load: function (loadOptions) {
              let d = $.Deferred();
              new TDFRequest({ url: "/Settings/DailySummaryItems/" })
                .MakeRequest()
                .done(function (response: any) {
                  daily.SettingsFullItemList = response.map(k => {
                    return k.ItemType;
                  });
                  d.resolve(response);
                });
              return d.promise();
            },
            remove(key) {
              let d = $.Deferred<void>();
              return d.promise(d.resolve(key));
            }
          }),
          allowItemReordering: true,
          allowItemDeleting: true,
          itemTemplate(data, index, element) {
            element.append(
              $("<div />")
                .text(data.text)
                .css({ float: "left" })
            );
            if (!data.enabled) {
              let opts: DevExpress.ui.dxCheckBoxOptions = {
                value: data.enabled,
                text: "Include In Summary",
                onValueChanged(e) {
                  let order = [];
                  let list = $("#lstItemOrder").dxList("instance");
                  $.each(list.option("items"), function (k, v) {
                    if (v.ItemType === data.ItemType) {
                      v.enabled = e.value;
                    }
                    if (v.enabled) {
                      order.push(v.ItemType);
                    }
                  });
                  let value = order.join(",");
                  Preferences.SetPreference(
                    "DailySummaryItemOrder",
                    value,
                    "TDFMobile"
                  );
                }
              };

              element.append(
                $("<div />")
                  .dxCheckBox(opts)
                  .css({ "margin-left": "15px" })
              );
            }
          },
          onItemReordered: function (e) {
            let order = [];
            $.each(e.component.option("items"), function (k, v) {
              if (v.enabled) order.push(v.ItemType);
            });
            let value = order.join(",");
            // Preferences.SetPreference(
            //   "DailySummaryItemOrder",
            //   value,
            //   "TDFMobile"
            // );
          },
          onItemDeleted: function (e) {
            let order = [];
            $.each(e.component.option("items"), function (k, v) {
              if (v.enabled) order.push(v.ItemType);
            });
            let value = order.join(",");
            // Preferences.SetPreference(
            //   "DailySummaryItemOrder",
            //   value,
            //   "TDFMobile"
            // ).done(() => {
            e.itemData.enabled = false;
            (e.component as DevExpress.ui.dxList).reload();
            // });
          }
        }
      }
    ];

    let form: DevExpress.ui.dxForm = $("<div />").dxForm({
      items: formitems,
      labelLocation: "top"
    }).dxForm('instance');

    let dailysettings = new Dialog(
      {
        body: form.element(),
        id: "summarysettings",
        title: "Daily Summary Settings",
        closable: true,
        fullScreen: GetDevice().isDevice,
        size: "size-normal",
        type: "type-primary",
        buttons: [
          {
            widget: 'dxButton',
            location: 'after',
            toolbar: 'bottom',
            options: <DevExpress.ui.dxButtonOptions>{
              text: 'OK',
              icon: 'check',
              type: 'success',
              onClick: (e) => {
                Preferences.SetPreferences([
                  { ModuleName: 'TDFMobile', PropertyName: 'DailySummaryTasksToDisplay', PropertyValue: form.getEditor('DailySummaryTasksToDisplay').option('value') },
                  { ModuleName: 'TDFMobile', PropertyName: 'ShowPastDueTasks', PropertyValue: form.getEditor('ShowPastDueTasks').option('value') ? 1 : 0 },
                  { ModuleName: 'TDFMobile', PropertyName: 'DailySummaryItemSource', PropertyValue: form.getEditor('DailySummaryItemSource').option('value') },
                  {
                    ModuleName: 'TDFMobile',
                    PropertyName: 'DailySummaryItemOrder',
                    PropertyValue: form.getEditor('DailySummaryItemOrder').option('items').map((a) => {
                      if (a.enabled) {
                        return a.ItemType;
                      }
                    }).join(',')
                  }
                ]);

                dailysettings.close();
              }
            }
          }, {
            widget: 'dxButton',
            location: 'after',
            toolbar: 'bottom',
            options: <DevExpress.ui.dxButtonOptions>{
              text: 'Cancel',
              icon: 'remove',
              type: 'danger',
              onClick: (e) => {
                dailysettings.close();
              }
            }
          }
        ],
        onHidden() {
          daily.CalData = null;
          daily.RequestData();
        }
      },
      null,
      true,
      true
    );
    dailysettings.open();
  }

  private AppointmentCols() {
    let dailysum = this;
    let AppointmentColumns: DevExpress.ui.dxDataGridColumn[] = [
      {
        dataField: "TDF_StartTime",
        dataType: "datetime",
        caption: "Start",
        allowSearch: false,
        visible: true,
        format: { type: "shortDateShortTime" }
      },
      {
        dataField: "TDF_EndTime",
        dataType: "datetime",
        visible: true,
        caption: "End ",
        allowSearch: false,
        format: { type: "shortDateShortTime" }
      },
      { dataField: "TDF_AttachmentCount", visible: false },
      {
        dataField: "TDF GUID",
        caption: "",
        allowFiltering: false,
        allowSorting: false,
        width: 30,
        visible: false
      },
      {
        dataField: "Subject",
        caption: "Subject",
        allowFiltering: true,

        width: "150px",
        visible: true,
        cellTemplate: function (container, options) {
          let data = options.data;
          $("<a />")
            .text(options.value)
            .appendTo(container)
            .on("click", function () {
              createitem(itemTypes.itemAppointment, { ItemID: data["TDF GUID"] }, false, true);
            });
        },
        visibleIndex: 0
      },
      { dataField: "AcctPkg", caption: "AcctPkg", visible: true },
      { dataField: "Owner", caption: "Owner", visible: true },
      { dataField: "ParentItemId", visible: false },
      { dataField: "ParentItemType", visible: false },
      {
        dataField: "AccountName",
        caption: "Account Name",
        visible: true,
        cellTemplate: function (container, options) {
          let data = options.data;
          $("<a />").text(options.value).appendTo(container).on("click", function () {
            createitem(itemTypes.itemAccount, { ItemID: data["ParentAccountId"] }, false, true);
          });
        }
      },
      {
        dataField: "ConvertedToActivity",
        caption: "Converted",
        dataType: "boolean",
        trueText: "Converted",
        falseText: "",
        visible: true
      }
    ];

    return AppointmentColumns;
  }

  private ActivityCols() {
    let dailysum = this;

    let ActivityColumns = [
      { dataField: "Pending", visible: false },
      { dataField: "Activity Type", caption: "Activity Type", visible: true },
      {
        dataField: "TDF_ActivityDate",
        allowSearch: false,
        dataType: "date",
        caption: "Activity Date ",
        format: { type: "shortDate" },
        visible: true
      },
      { dataField: "TDF_AttachmentCount", visible: false },
      {
        dataField: "TDFGUID",
        caption: "",
        allowFiltering: false,
        allowSorting: false,
        width: 30,
        visible: false

        //cellTemplate: getActionCell()
      },
      {
        dataField: "Subject",
        caption: "Subject",
        visible: true,
        cellTemplate: function (container, options) {
          let data = options.data;
          $("<a />")
            .text(options.value)
            .appendTo(container)
            .on("click", function () {
              createitem(itemTypes.itemActivity, { ItemID: data["TDF GUID"] }, false, true);
            });
        },
        visibleIndex: 0
      },
      { dataField: "Owner", caption: "Owner", visible: true },
      { dataField: "ParentItemId", visible: false },
      { dataField: "ParentItemType", visible: false },
      {
        dataField: "AccountName",
        caption: "Account Name",
        visible: true,
        cellTemplate: function (container, options) {
          let data = options.data;
          $("<a />")
            .text(options.value)
            .appendTo(container)
            .on("click", function () {
              createitem(itemTypes.itemAccount, { ItemID: data["ParentAccountId"] }, false, true);
            });
        }
      },
      { dataField: "AcctPkg", caption: "AcctPkg", visible: true }
    ];

    return ActivityColumns;
  }

  private TaskCols() {
    let dailysum = this;
    let TaskColumns = [
      dailysum.AcctPkgCol(),
      {
        dataField: "TDF_DueDate",
        format: { type: "shortDate" },
        caption: "Due Date",
        dataType: "date",
        visibleIndex: 5,
        allowSearch: false,
        visible: true,
        sortOrder: "desc",
        width: 100
      },
      dailysum.AttachmentCol(),
      dailysum.TDFGuidCol(),
      dailysum.OwnerCol(),
      dailysum.ParentIDCol(),
      dailysum.ParentTypeCol(),
      dailysum.AccountNameCol(),
      dailysum.PrimaryParentCol(),
      {
        dataField: "TDF_Priority",
        caption: "Priority",
        visibleIndex: 3,
        width: 75,
        visible: true
      },
      dailysum.SecondaryParentCol(),
      {
        dataField: "TDF_Status",
        visibleIndex: 2,
        width: 75,
        caption: "Status",
        visible: true
      },
      dailysum.SubjectCol(),
      dailysum.ConvertedCol()
    ];

    return TaskColumns;
  }

  private OpportunityCols() {
    let dailysum = this;
    let OpportunityColumns: DevExpress.ui.dxDataGridColumn[] = [
      dailysum.AcctPkgCol(),
      {
        dataField: "TDF_ProjectedClose",
        format: { type: "shortDate" },
        caption: "Projected Close",
        dataType: "date",
        visibleIndex: 5,
        allowSearch: false,
        visible: true,
        sortOrder: "desc",
        width: 100
      },
      dailysum.AttachmentCol(),
      dailysum.TDFGuidCol(),
      dailysum.OwnerCol(),
      dailysum.ParentIDCol(),
      dailysum.ParentTypeCol(),
      dailysum.AccountNameCol(),
      dailysum.PrimaryParentCol(),
      dailysum.SecondaryParentCol(),
      dailysum.SubjectCol(),
      {
        dataField: "OpportunityValue",
        caption: "Opportunity Value",
        visible: true,

        visibleIndex: 1,
        format: { type: "currency", currency: CurrentUser.Currency }
      },
      {
        dataField: "TDF_ProbableValue",
        caption: "Probable Value",
        visible: true,

        visibleIndex: 2,
        format: { type: "currency", currency: CurrentUser.Currency }
      },
      {
        dataField: "TDF_SalesStage",
        caption: "Sales Satge",
        visible: true,

        visibleIndex: 3
        //format: { type: "currency", currency:CurrentUser.Currency },
      }
    ];

    return OpportunityColumns;
  }

  private ExpenseCols() {
    let dailysum = this;

    let ExpenseColumns = [
      {
        dataField: "Description",
        caption: "Subject",
        cellTemplate: function (container, options) {
          let data = options.data;
          $("<a />")
            .text(options.value)
            .appendTo(container)
            .on("click", function () {
              let url = "";
              url =
                window.location.origin + "/expense/?itemid=" + data["TDF GUID"];

              if (url) {
                if (!GetDevice().isDevice) {
                  window.open(url, "_blank");
                } else {
                  window.location.href = url;
                }
              }
            });
        },
        visibleIndex: 0,
        visible: true
      },
      { dataField: "Expense Type", caption: "Expanse Type" },
      {
        dataField: "ExpenseDate",
        caption: "Expense Date",
        format: { type: "shortDate" },
        allowSearch: false,
        dataType: "date",
        visible: true
      },
      {
        dataField: "ExpenseAmount",
        caption: "Amount",
        dataType: "number",
        visible: true,
        format: {
          type: "currency",
          precision: 2,
          currency: CurrentUser.Currency
        }
      },
      {
        dataField: "TDF GUID",
        caption: "",
        allowFiltering: false,
        allowSorting: false,
        width: 30,
        visible: false
      },
      { dataField: "TDF_AttachmentCount", visible: false },

      { dataField: "PrimaryParent", visible: false },
      { dataField: "SecondaryParent", visible: false },
      { dataField: "Owner", caption: "Owner", visible: true },
      { dataField: "AcctPkg", caption: "AcctPkg", visible: true }
    ];

    return ExpenseColumns;
  }

  private ReminderCols() {
    let dailysum = this;
    let ReminderColumns = [
      {
        dataField: "TDF GUID",
        caption: "",
        allowFiltering: false,
        allowSorting: false,
        width: 30,
        visible: false,
        visibleIndex: 0
      },
      {
        visible: true,
        dataField: "ReminderSubject",
        caption: "Subject",
        visibleIndex: 0,
        cellTemplate: function (container, options) {
          let data = options.data;
          $("<a />")
            .text(options.value)
            .appendTo(container)
            .on("click", function () {
              createitem(itemTypes.itemReminder, { ItemID: data["TDF GUID"] }, false, true);
            });
        }
      },
      { dataField: "LinkItemType", visible: false },
      { dataField: "ReminderText", caption: "Description", visible: true },
      {
        dataField: "ParentName",
        caption: "Parent",
        visible: true,
        cellTemplate: function (container, options) {
          let data = options.data;
          $("<a />")
            .text(options.value)
            .appendTo(container)
            .on("click", function () {
              createitem(parseInt(data["LinkItemType"]), { ItemID: data["LinkItemID"] }, false, true);
            });
        }
      },
      { dataField: "SentBy", caption: "Sender", visible: true },
      { dataField: "Creation", visible: false },
      { dataField: "LinkItemID", visible: false },
      {
        dataField: "Reminder",
        caption: "Reminder",
        visible: true,
        dataType: "date",
        format: { type: "shortDateshortTime" },
        allowSearch: false
      }
    ];

    return ReminderColumns;
  }

  private AcctPkgCol() {
    return {
      dataField: "AcctPkg",
      visibleIndex: 6,
      caption: "AcctPkg",
      visible: true
    };
  }

  private TDFGuidCol() {
    return {
      dataField: "TDF GUID",
      caption: "",
      allowFiltering: false,
      allowSorting: false,
      width: 30,
      visible: false
    };
  }

  private AttachmentCol() {
    return { dataField: "TDF_AttachmentCount", visible: false };
  }

  private ConvertedCol() {
    return {
      dataField: "ConvertedToActivity",
      caption: "Converted",
      dataType: "boolean",
      visible: true
    };
  }

  private SubjectCol() {
    return {
      dataField: "Subject",
      caption: "Subject",
      visible: true,
      cellTemplate: function (container, options) {
        let data = options.data;
        $("<a />")
          .text(options.value)
          .appendTo(container)
          .on("click", function () {
            createitem(itemTypes.itemTask, { ItemID: data["TDF GUID"] }, false, true);
          });
      },
      visibleIndex: 0
    };
  }

  private SecondaryParentCol() {
    return { dataField: "SecondaryParent", visible: false };
  }

  private PrimaryParentCol() {
    return { dataField: "PrimaryParent", visible: false };
  }

  private AccountNameCol() {
    return {
      dataField: "AccountName",
      caption: "Account Name",
      visible: true,
      cellTemplate: function (container, options) {
        let data = options.data;
        $("<a />")
          .text(options.value)
          .appendTo(container)
          .on("click", function () {
            createitem(itemTypes.itemAccount, { ItemID: data["ParentAccountId"] }, false, true);
          });
      }
    };
  }

  private ParentTypeCol() {
    return { dataField: "ParentItemType", visible: false };
  }

  private ParentIDCol() {
    return { dataField: "ParentItemId", visible: false };
  }

  private OwnerCol() {
    return {
      dataField: "Owner",
      caption: "Owner",
      visibleIndex: 4,
      width: 175,
      visible: true
    };
  }
}

//TileFactory.RegisterTileType(
//  "DailySummary",
//  "A tile that can be used to display the standard Daily Summary that has always existed on the TdF Web home page.",
//  "fa fa-calendar-o",
//  DailySummaryTileSettings,
//  DailySummary
//);
