import { DashboardSectionType } from "../../enums/dashboards/enums";
import { iColumnInfo, iTdFDashboardTile } from "../../interfaces/dashboards/idashboarddescriptor";
import { Dashboard } from "./dashboard";
import { DashboardSection } from "./dashboardsection";
import { TdFDashboardTile } from "./tdfdashboardtile";
export class WholeDashboardSection extends DashboardSection {
  public SectionType: DashboardSectionType = DashboardSectionType.Whole;
  private designerTiles: iTdFDashboardTile[];
  private myColumns: iColumnInfo[];
  protected readonly SectionClassName: string = "tdfWholeDashboardSection";
  // Column IDs
  private ColOneID: string;
  // Columns
  private columnOne: JQuery;
  protected get ColumnOne(): JQuery {
    let myDashboardSection = this;
    if (!myDashboardSection.columnOne) {
      myDashboardSection.columnOne = $(`#${myDashboardSection.ColOneID}`);
    }
    return (myDashboardSection.columnOne);
  }
  constructor(tiles: iTdFDashboardTile[], parentDashboard: Dashboard) {
    super(tiles, parentDashboard);
    let myDashboardSection = this;
    myDashboardSection.SetIDs();
  }
  private SetIDs() {
    let myDashboardSection = this;
    myDashboardSection.ColOneID = `colOne_${myDashboardSection.SectionID}`;
  }
  protected get ColumnInfo(): iColumnInfo[] {
    let myDashboardSection = this;
    if (!myDashboardSection.myColumns) {
      myDashboardSection.myColumns = [];
      let col1 = { ColumnID: myDashboardSection.ColOneID, ColumnName: 0, Class: "col-md-12" };
      myDashboardSection.myColumns.push(col1);
    }
    return myDashboardSection.myColumns;
  }
  protected GetTileContainer(node: TdFDashboardTile) {
    let myDashboardSection = this;
    let targetContainer: JQuery = myDashboardSection.ColumnOne;
    return targetContainer;
  }
}
