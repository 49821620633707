import { DataScopeSelection } from "../dashboards/dynamicdatascopeselector";
import { TileBase } from "../dashboards/tilebase";
import { TileFactory } from "../dashboards/tilefactory";
import { TileSettingsBase } from "../dashboards/tilesettingsbase";
import { RaiseEvent2 } from "../../infrastructure/events/ui_events";
import { TDFRequest } from "../../services/request";
import dxDataSource from "devextreme/data/data_source";
import { eventNameSpace, EventTypes } from "../../enums/webevents/enums";
import { GetDevice, DomSafeID } from "../../util/allutils";
import dxArrayStore from "devextreme/data/array_store";
import "devextreme/ui/select_box";
import "devextreme/viz/chart";
import "devextreme/viz/pie_chart";

interface JQuery {
  removeData(name?: string): JQuery;
}

// Note:  These were added because DevExpress's documentation indicates the series should be an Array of dxChartSeries objects.
// Their typings file did not allow that.
// I had to comment out the series property in the Scripts\typings\devextreme\dx.all.d.ts file in order to get this to compile.
// If a new version of DevExpress is taken, this code will probably fail the compiler, and the series property will again need removed from the Scripts\typings\devextreme\dx.all.d.ts file.
//////declare module DevExpress.viz.charts {
//////    export interface dxChartOptions extends DevExpress.viz.charts.BaseChartOptions {
//////        series?: Array<DevExpress.viz.charts.dxChartSeries> | DevExpress.viz.charts.dxChartSeries;
//////    }
//////    export interface dxPieChartOptions extends DevExpress.viz.charts.BaseChartOptions {
//////        series?: DevExpress.viz.charts.dxPieChartSeries | DevExpress.viz.charts.dxPieChartSeries[];
//////    }
//////}

export class ChartTileSettings extends TileSettingsBase {
  ChartName: string = "";
  ChartViewType: string = "";
  DisplayChartNameInChart: boolean = false;
}

export class Charts extends TileBase {
  public HasInteractiveSettings: boolean = true;
  protected _Name: string = "BI Visualization";
  ModuleName: string = "TDFMobile";
  AutoExpands: boolean = true;

  RendererID: string = "";
  ChartType: number = -1;
  ChartViewType: string = "";
  ChartData: any = {};

  ChartContainerID: string = "";
  ChartToolbarID: string = "";
  ChartSelectorID: string = "";
  ChartViewTypeSelectorID: string = "";
  ChartTitleInChartID: string = "";
  ChartDefaultBtnID: string = "";
  ChartAreaSpinnerID: string = "";

  TheChart: DevExpress.viz.BaseChart = null;

  Data = {};
  ChartModel: any;
  SelectedChart: any = null;
  SelectedView: string = "";
  private HasBeenRendered: boolean = false;

  /*
     * chartRendererID = a general ID for the Chart Renderer itself.  
     * containerID = the ID of the element that the Chart Renderer displays in.
     */
  constructor(
    tileInstanceID: string,
    tileSettings: Partial<ChartTileSettings>,
    settingsClassRef: typeof TileSettingsBase = ChartTileSettings
  ) {
    super(tileInstanceID, tileSettings, settingsClassRef);

    let chartRenderer = this;
    chartRenderer.SetIDs();
  }

  protected Render(contentElement: JQuery): JQueryPromise<any> {
    let chartRenderer = this;
    chartRenderer.HasBeenRendered = true;
    let dfd: JQueryDeferred<any> = $.Deferred();

    let content: string = `<div id="${
      chartRenderer.ChartAreaSpinnerID
      }" > </div>
                    <div id = "${chartRenderer.ChartContainerID}"> </div>`;

    $(contentElement).append(content);

    chartRenderer.Data = {};

    chartRenderer.GetScopeSettings().done(function (scopeSettings) {
      chartRenderer.LoadChartModel(scopeSettings).done(function () {
        chartRenderer.RenderChart(scopeSettings).done(function () {
          return dfd.resolve();
        });
      });
    });

    return dfd.promise();
  }

  public RefreshUI() {
    let chartRenderer = this;
    chartRenderer.TheChart.render();
  }

  public GetInteractiveTileSettings(): JQueryPromise<
    Array<
      | DevExpress.ui.dxFormSimpleItem
      | DevExpress.ui.dxFormGroupItem
      | DevExpress.ui.dxFormTabbedItem
      | DevExpress.ui.dxFormEmptyItem
      | DevExpress.ui.dxFormButtonItem
    >
  > {
    let chartRenderer = this;
    let dfd: JQueryDeferred<any> = $.Deferred();

    //TODO:  Provide a way to set the defaults again for the user's preferences so they can still run the global home page with their own preference for chart types.
    chartRenderer.GetScopeSettings().done(function (scopeSettings) {
      chartRenderer.LoadChartModel(scopeSettings).done(function () {
        let items = [
          chartRenderer.ChartNameSelector,
          chartRenderer.ChartViewTypeSelector
        ];

        return dfd.resolve(items);
      });
    });

    return dfd.promise();
  }

  public get ChartNameSelector(): DevExpress.ui.dxFormSimpleItem {
    let chartRenderer = this;

    let myChartSelectorChanged = function () {
      chartRenderer.ChartSelectorChanged();
    };

    let item: DevExpress.ui.dxFormSimpleItem = {
      dataField: "TileSettings.ChartName",
      editorType: "dxSelectBox",
      editorOptions: {
        elementAttr: {
          id: chartRenderer.ChartSelectorID
        },
        dataSource: new dxDataSource({
          store: new dxArrayStore({
            data: chartRenderer.ChartModel.ChartChoices
          })
        }),
        displayExpr: "Title",
        valueExpr: "Title",
        width: GetDevice().isDevice ? "125px" : "300px",
        onItemClick: function (selectedItem) {
          myChartSelectorChanged();
        }
      }
    };

    return item;
  }

  public get ChartViewTypeSelector(): DevExpress.ui.dxFormSimpleItem {
    let chartRenderer = this;

    let myViewSelectorChanged = function () {
      chartRenderer.ViewSelectorChanged();
    };

    let item: DevExpress.ui.dxFormSimpleItem = {
      dataField: "TileSettings.ChartViewType",
      editorType: "dxSelectBox",
      editorOptions: {
        elementAttr: {
          id: chartRenderer.ChartViewTypeSelectorID
        },
        dataSource: new dxDataSource({
          store: new dxArrayStore({
            data: chartRenderer.ChartModel.ChartViewTypeChoices
          })
        }),
        displayExpr: "Title",
        valueExpr: "Value",
        width: GetDevice().isDevice ? "100px" : "200px",
        onItemClick: function (selectedItem) {
          myViewSelectorChanged();
        }
      }
    };

    return item;
  }

  public get DisplayChartNameOption(): DevExpress.ui.dxFormSimpleItem {
    let chartRenderer = this;
    let item: DevExpress.ui.dxFormSimpleItem = {
      label: {
        text: "Display Chart Name in Chart"
      },
      dataField: "TileSettings.DisplayChartNameInChart",
      editorType: "dxCheckBox",
      colSpan: 1,
      editorOptions: {
        elementAttr: {
          id: chartRenderer.ChartTitleInChartID
        }
      }
    };

    return item;
  }

  public GetConfigurableTileSettings(): JQueryPromise<
    Array<
      | DevExpress.ui.dxFormSimpleItem
      | DevExpress.ui.dxFormGroupItem
      | DevExpress.ui.dxFormTabbedItem
      | DevExpress.ui.dxFormEmptyItem
      | DevExpress.ui.dxFormButtonItem
    >
  > {
    let chartRenderer = this;

    let dfd: JQueryDeferred<
      Array<
        | DevExpress.ui.dxFormSimpleItem
        | DevExpress.ui.dxFormGroupItem
        | DevExpress.ui.dxFormTabbedItem
        | DevExpress.ui.dxFormEmptyItem
        | DevExpress.ui.dxFormButtonItem
      >
    > = $.Deferred();

    chartRenderer.GetScopeSettings().done(function (scopeSettings) {
      chartRenderer.LoadChartModel(scopeSettings).done(function () {
        let items = [
          chartRenderer.ChartNameSelector,
          chartRenderer.ChartViewTypeSelector,
          chartRenderer.DisplayChartNameOption
        ];

        return dfd.resolve(items);
      });
    });

    return dfd.promise();
  }

  public ApplyNewSettings(newSettings: TileSettingsBase) {
    let chartRenderer = this;
    super.ApplyNewSettings(newSettings);

    let filteredCharts = chartRenderer.ChartModel.ChartChoices.filter(function (
      e
    ) {
      return e.Title === chartRenderer.ChartSettings.ChartName;
    });
    if (filteredCharts.length > 0) {
      chartRenderer.SelectedChart = filteredCharts[0];
    }

    chartRenderer.SelectedView = chartRenderer.ChartSettings.ChartViewType;

    //tileBase.Settings = new tileBase.SettingsClassRef();
    //tileBase.Settings.InitSettings(newSettings);
  }

  private UpdateViewTypeList(chartListElement, viewTypeListElement) {
    let chartRenderer = this;

    let chartSelector = $(chartListElement).dxSelectBox("instance");
    let viewSelector: any = $(viewTypeListElement).dxSelectBox("instance");

    let oldViewSelection = viewSelector.option("selectedItem") || "";

    let currentChartSelection = chartSelector.option("selectedItem") || "";

    if (currentChartSelection.Title !== "None") {
      viewSelector._dataSource.filter(
        "SupportedCharts",
        "contains",
        currentChartSelection.ChartType
      );
      viewSelector._dataSource.load();
    } else {
      viewSelector._dataSource.filter("Title", "=", "None");
      viewSelector._dataSource.load();
    }

    if (oldViewSelection !== undefined) {
      if (
        viewSelector._dataSource._items.filter(function (e) {
          return e.Value === oldViewSelection.Value;
        }).length > 0
      ) {
        viewSelector.option("value", oldViewSelection.Value);
      } else {
        viewSelector.option(
          "value",
          currentChartSelection.DefaultChartViewType
        );
      }
    } else {
      viewSelector.option("value", currentChartSelection.DefaultChartViewType);
    }
  }

  private ViewSelectorChanged() {
    let chartRenderer = this;
    let chartListElement = $("#" + chartRenderer.ChartSelectorID)[0];
    let viewTypeListElement = $("#" + chartRenderer.ChartViewTypeSelectorID)[0];

    chartRenderer.SelectedChart = $(chartListElement)
      .dxSelectBox("instance")
      .option("selectedItem");
    chartRenderer.SelectedView = $(viewTypeListElement)
      .dxSelectBox("instance")
      .option("value");
    if (chartRenderer.HasBeenRendered) {
      RaiseEvent2(
        EventTypes.DashboardEventTypes.RefreshTile,
        DomSafeID(chartRenderer.ID),
        eventNameSpace.dashboard
      );
      //chartRenderer.RenderChart();
    }
  }

  private ChartSelectorChanged() {
    let chartRenderer = this;
    let chartListElement = $("#" + chartRenderer.ChartSelectorID)[0];
    let viewTypeListElement = $("#" + chartRenderer.ChartViewTypeSelectorID)[0];

    chartRenderer.UpdateViewTypeList(chartListElement, viewTypeListElement);

    chartRenderer.SelectedChart = $(chartListElement)
      .dxSelectBox("instance")
      .option("selectedItem");
    chartRenderer.SelectedView = $(viewTypeListElement)
      .dxSelectBox("instance")
      .option("value");
    if (chartRenderer.HasBeenRendered) {
      RaiseEvent2(
        EventTypes.DashboardEventTypes.RefreshTile,
        DomSafeID(chartRenderer.ID),
        eventNameSpace.dashboard
      );
      //chartRenderer.RenderChart();
    }
  }

  private LoadChartModel(
    scopeSettings: DataScopeSelection
  ): JQueryPromise<any> {
    let chartRenderer = this;
    let dfd: JQueryDeferred<any> = $.Deferred();

    if (chartRenderer.ChartModel) {
      return dfd.promise(dfd.resolve());
    }

    let params: any = {
      moduleName: chartRenderer.ModuleName,
      biListing: scopeSettings.CurrentlySelectedItemIDsAsString(),
      biLevel: scopeSettings.ActiveSelectionLevel
    };

    //TODO:  Cache this model info somehow for all chart tiles.  Except it takes into account the BI Level, so we can't always cache it...
    let request = new TDFRequest({
      url: "/BI/GetChartInfo",
      type: "POST",
      data: params
    });
    request.spinnerContainerID = chartRenderer.ChartAreaSpinnerID;
    request.MakeRequest().done(function (response) {
      chartRenderer.ChartModel = response;

      // Set default values
      let selectedChartName =
        chartRenderer.ChartModel.ChartChoices.length > 0
          ? chartRenderer.ChartModel.ChartChoices[0].Title
          : "";
      if (chartRenderer.ChartSettings.ChartName) {
        selectedChartName = chartRenderer.ChartSettings.ChartName;
      } else if (chartRenderer.ChartModel.DefaultChartName) {
        selectedChartName = chartRenderer.ChartModel.DefaultChartName;
      }
      let filteredCharts = chartRenderer.ChartModel.ChartChoices.filter(
        function (e) {
          return e.Title === selectedChartName;
        }
      );
      if (filteredCharts.length > 0) {
        chartRenderer.SelectedChart = filteredCharts[0];
      }

      chartRenderer.SelectedView =
        chartRenderer.ChartModel.ChartViewTypeChoices.length > 0
          ? chartRenderer.ChartModel.ChartViewTypeChoices[0].Value
          : "";
      if (chartRenderer.ChartSettings.ChartViewType) {
        chartRenderer.SelectedView = chartRenderer.ChartSettings.ChartViewType;
      } else if (chartRenderer.ChartModel.DefaultChartViewType) {
        chartRenderer.SelectedView =
          chartRenderer.ChartModel.DefaultChartViewType;
      }

      return dfd.resolve();
    });

    return dfd.promise();
  }

  private RenderDataIntoChart(
    dataResponse,
    title,
    dataFormat,
    dataPrecision,
    dxChartType
  ) {
    let chartRenderer = this;

    let seriesList: any = [];
    let argumentField = "";
    let keyElementFound = false;
    let seriesCount = 0;
    let bestGuessForHeight = 400;

    for (let key in dataResponse[0]) {
      if (!keyElementFound) {
        argumentField = key; // Hack to skip over first element, which is the argument element.
        keyElementFound = true;
      } else {
        let series: DevExpress.viz.ChartSeries = {
          valueField: key,
          name: key,
          showInLegend: true
        };
        seriesList.push(series);
      }

      seriesCount = seriesCount + 1;
    }
    // This is a rough guess on how to resize the height based on the number of columns that will fit in the legend and the number of rows needed to display the legend.
    bestGuessForHeight =
      bestGuessForHeight +
      Math.ceil(
        seriesCount /
        (Math.floor($("#" + chartRenderer.ChartContainerID).width() - 100) /
          230)
      ) *
      40;

    if (!chartRenderer.AutoExpands) {
      $("#" + chartRenderer.ChartContainerID)
        .parent()
        .parent()
        .height(
          bestGuessForHeight +
          $("#" + chartRenderer.ChartContainerID).height() +
          10
        );
    }

    chartit();

    function chartit() {
      let chart;
      $("#" + chartRenderer.ChartContainerID).empty();
      $("#" + chartRenderer.ChartContainerID).removeData();

      function toggleVisibility(item) {
        item.isVisible() ? item.hide() : item.show();
      }

      if (dxChartType.toLowerCase() !== "pie") {
        chart = $("#" + chartRenderer.ChartContainerID).dxChart({
          animation: {
            duration: 500,
            enabled: true
          },
          seriesSelectionMode: "multiple",
          dataSource: dataResponse,
          commonSeriesSettings: {
            argumentField: argumentField,
            type: dxChartType
          },
          commonAxisSettings: {
            valueMarginsEnabled: true
          },
          argumentAxis: {
            label: {
              alignment: "right",
              rotationAngle: -45,
              overlappingBehavior: "rotate"
            }
          },
          palette: "Harmony Light",
          valueAxis: {
            label: {
              format: {
                type: dataFormat,
                precision: dataPrecision
              }
            }
          },
          series: <DevExpress.viz.ChartSeries>seriesList,
          size: {
            height: bestGuessForHeight
          },
          title: {
            text: chartRenderer.ChartSettings.DisplayChartNameInChart
              ? chartRenderer.SelectedChart.Title
              : ""
          },
          legend: {
            verticalAlignment: "bottom",
            horizontalAlignment: "center",
            visible: true
          },
          tooltip: {
            enabled: true,
            format: {
              type: dataFormat,
              precision: dataPrecision
            },
            //zIndex: zIndex,
            customizeTooltip: function (pointInfo) {
              return {
                text: pointInfo.seriesName + "<BR>" + pointInfo.valueText
              };
            }
          },
          dataPrepareSettings: {
            convertToAxisDataType: true,
            checkTypeForAllData: true
          },
          onSeriesClick: function (e) {
            let series = e.target;
            series.isVisible() ? series.hide() : series.show();
          }
        });
        chartRenderer.TheChart = chart.dxChart("instance");
      } else {
        let width = $("#" + chartRenderer.ChartContainerID).innerWidth();
        let horizontalPosition: "right" | "left" | "center" = "right";
        let verticalPosition: "bottom" | "top" = "top";
        if (width < 560) {
          horizontalPosition = "center";
          verticalPosition = "bottom";
        }
        let options: DevExpress.viz.charts.dxPieChartOptions = {
          dataSource: dataResponse,
          commonSeriesSettings: {
            argumentField: argumentField,
            type: dxChartType,
            label: {
              visible: true,
              connector: {
                visible: true,
                width: 1
              },
              format: {
                type: dataFormat,
                precision: dataPrecision
              },
              customizeText: function (pointInfo) {
                return pointInfo.argument + "<BR>" + pointInfo.valueText;
              }
            },
            selectionMode: "onlyPoint"
          },
          palette: "Harmony Light",
          series: <DevExpress.viz.PieChartSeries>seriesList,
          size: {
            height: bestGuessForHeight
          },
          //title: {
          //    text: title
          //},
          type: "pie",
          legend: {
            visible: true,
            horizontalAlignment: horizontalPosition,
            verticalAlignment: verticalPosition
          },
          tooltip: {
            enabled: true,
            format: {
              type: dataFormat,
              precision: dataPrecision
            },
            //zIndex: zIndex,
            customizeTooltip: function (pointInfo) {
              return {
                text: pointInfo.argument + "<BR>" + pointInfo.valueText
              };
            }
          },
          onPointClick: function (e) {
            let series = e.target;
            toggleVisibility(series);
          },
          onLegendClick: function (e) {
            let arg = e.target;
            toggleVisibility(this.getAllSeries()[0].getPointsByArg(arg)[0]);
          }
        };
        chart = $("#" + chartRenderer.ChartContainerID).dxPieChart(options);

        chartRenderer.TheChart = chart.dxPieChart("instance");
      }
    }
  }

  //TODO:  Get rid of None options?  Maybe?  Or leave them for the case where a user wants the tile without any preset chart.
  private RenderChart(scopeSettings: DataScopeSelection) {
    let chartRenderer = this;
    let dfd: JQueryDeferred<any> = $.Deferred();

    let chartChoice: any = chartRenderer.SelectedChart;
    let viewTypeChoice: string = chartRenderer.SelectedView;
    if (chartChoice) {
      if (chartChoice.Title === "None" || viewTypeChoice === "none") {
        $("#" + chartRenderer.ChartContainerID).empty();
        $("#" + chartRenderer.ChartContainerID).removeData();
      } else {
        let chartHeight = 0;
        let chartWidth = 0;
        let title = chartChoice.Title;
        let yearOffset = chartChoice.YearOffset;
        let chartType = chartChoice.ChartType;
        let dataFormat = chartChoice.ChartDataFormat; //Accepted Values: 'currency' | 'fixedPoint' | 'percent' | 'decimal' | 'exponential' | 'largeNumber' | 'thousands' | 'millions' | 'billions' | 'trillions' | 'longDate' | 'longTime' | 'monthAndDay' | 'monthAndYear' | 'quarterAndYear' | 'shortDate' | 'shortTime' | 'millisecond' | 'day' | 'month' | 'quarter' | 'year'
        let dataPrecision = chartChoice.ChartDataPrecision;
        let dxChartType = viewTypeChoice;
        if (dxChartType === "") {
          dxChartType = chartChoice.getAttribute("DefaultChartViewType");
        }

        if (
          chartRenderer.Data[title] !== undefined &&
          dxChartType.toLowerCase() !== "pie"
        ) {
          chartRenderer.RenderDataIntoChart(
            chartRenderer.Data[title],
            title,
            dataFormat,
            dataPrecision,
            dxChartType
          );
          return dfd.resolve();
        } else {
          let params: any = {
            chartType: chartType,
            title: title,
            yearoffset: yearOffset,
            dxChartType: dxChartType,
            biListing: scopeSettings.CurrentlySelectedItemIDsAsString(),
            biLevel: scopeSettings.ActiveSelectionLevel
          };

          let request = new TDFRequest({
            url: "/BI/BIChart",
            type: "POST",
            data: params
          });
          request.spinnerContainerID = chartRenderer.ChartAreaSpinnerID;
          request.ShowErrors = false;
          request.MakeRequest().done(function (response) {
            try {
              
                if (!response) {
                  $("#" + chartRenderer.ChartContainerID).append(
                    $("<div>" + response.Message + "</div>")
                  );
                  return;
                }

              if (dxChartType.toLowerCase() !== "pie") {
                chartRenderer.Data[title] = response;
              }
              chartRenderer.RenderDataIntoChart(
                response,
                title,
                dataFormat,
                dataPrecision,
                dxChartType
              );
              return dfd.resolve();
            } catch (e) {
              console.error(e.message, e.stack);
            }
          });
        }

        return dfd.promise();
      }
    }

    return dfd.promise(dfd.resolve());
  }

  // TODO: This needs to be handled by the tile wizard.
  //SetDefaultVisualizationSettings (chartElement, viewTypeElement) {
  //    let chartRenderer = this;

  //    let chartName = $(chartElement).dxSelectBox("instance").option("value");
  //    let chartViewType = $(viewTypeElement).dxSelectBox("instance").option("value");

  //    // TODO:  Re-work this to send both preferences at once.
  //    Preferences.SetPreference("DefaultChartName", chartName, chartRenderer.ModuleName);
  //    Preferences.SetPreference("DefaultChartViewType", chartViewType, chartRenderer.ModuleName);
  //}

  private SetIDs() {
    let chartRenderer = this;

    let domSafeTileID = DomSafeID(chartRenderer.TileInstanceID);

    chartRenderer.ChartAreaSpinnerID = "chartAreaSpinner_" + domSafeTileID;
    chartRenderer.ChartContainerID = "chartarea_" + domSafeTileID;
    chartRenderer.ChartToolbarID = "chart-toolbar-" + domSafeTileID;
    chartRenderer.ChartSelectorID = "chart-selector-" + domSafeTileID;
    chartRenderer.ChartViewTypeSelectorID = "chart-view-type-" + domSafeTileID;
    chartRenderer.ChartTitleInChartID =
      "chart-includeChartTitleInChart-" + domSafeTileID;
    chartRenderer.ChartDefaultBtnID =
      "btnSetDefaultVisualizationSettings-" + domSafeTileID;
  }

  private get ChartSettings(): ChartTileSettings {
    let chartRenderer = this;
    return <ChartTileSettings>chartRenderer.Settings;
  }
}

// TileFactory.RegisterTileType("Charts", "A tile that can be used to display a BI Visualization.", "fa fa-pie-chart", ChartTileSettings, Charts);
