import { ItemMain } from "../components/items/helpers/itemmain";
import { Grid } from "../legacy-centers-grids/grid";
import {
  itemTypes,
  menuActions,
  ActionDisplayOptions,
  tdfViews,
  GridContainerTypes,
  EnumUDFFormat,
  enumscope
} from "../enums/enums";
import {
  BIMenuSection,
  GapRollUpType,
  BSIGrids,
  BSIGoalType,
  ISummary
} from "../enums/bi/enums";
import {
  BaseToolbar,
  GridToolbar
} from "../components/grids/gridtoolbars/gridtoolbar";
export interface Array<T> {
  unique(): T[];
}

"use strict";

// Note:  This interface can be expanded to match the  DevExpress.ui.dxFormOptions
export interface IFormOptions {
  id?: string;
  class?: string;

  /** Provides the Form's data. Gets updated every time form fields change. */
  formData?: any;
  /** Holds an array of form items. */
  items?: Array<
    | DevExpress.ui.dxFormSimpleItem
    | DevExpress.ui.dxFormGroupItem
    | DevExpress.ui.dxFormTabbedItem
    | DevExpress.ui.dxFormEmptyItem
    | DevExpress.ui.dxFormButtonItem
  >;
  /** The count of columns in the form layout. */
  colCount?: number | "auto";
  /** Specifies dependency between the screen factor and the count of columns in the form layout. */
  colCountByScreen?: any;
  elementAttr?: any;
  /** Specifies the widget's height. */
  height?: number | string | (() => number | string);
  /** A Boolean value specifying whether to enable or disable form scrolling. */
  scrollingEnabled?: boolean;
  /** Specifies whether all editors on the form are read-only. Applies only to non-templated items. */
  readOnly?: boolean;
  /** Specifies whether or not a colon is displayed at the end of form labels. */
  showColonAfterLabel?: boolean;
  /** The minimum column width used for calculating column count in the form layout. */
  minColWidth?: number;
  /** Specifies the location of a label against the editor. */
  labelLocation?: "left" | "right" | "top";
  /** A function that is executed when the value of a formData object field is changed. */
  onFieldDataChanged?: ((
    e: {
      component?: DevExpress.ui.dxForm;
      element?: DevExpress.core.dxElement;
      model?: any;
      dataField?: string;
      value?: any;
    }
  ) => any);
  /** A function that is executed when the widget's content is ready and each time the content is changed. */
  onContentReady?: ((
    e: {
      component?: DevExpress.ui.Widget;
      element?: DevExpress.core.dxElement;
      model?: any;
    }
  ) => any);
  /** Specifies whether or not all root item labels are aligned. */
  alignItemLabels?: boolean;
}
export interface IDialogOptions {
  id?: string;
  type?:
  | "type-primary"
  | "type-warning"
  | "type-default"
  | "type-success"
  | "type-danger"
  | "type-info";
  size?:
  | "size-wide"
  | "size-large"
  | "size-normal"
  | "size-medium"
  | "size-small";
  title: string;
  body?: string | JQuery;
  //content?: JQuery;
  buttons?: DevExpress.ui.dxPopupToolbarItem[];
  onHiding?(e: any): any;
  onHidden?(e: any): any;
  closable: boolean;
  fullScreen?: boolean;
  showHelpButton?: boolean;
  showStandardButtons?: "yes" | "no";
    modal?: "yes" | "no";
    hideTitle?: boolean;
}
export enum DialogResult {
  /**
   * Nothing is returned from the dialog box. This means that the modal dialog continues running
   */
  None = 0,
  /**         
             The dialog box return value is OK (usually sent from a button labeled OK)
             */
  OK = 1,
  /**         
             The dialog box return value is Cancel (usually sent from a button labeled Cancel)
             */
  Cancel = 2,
  /**         
             The dialog box return value is Abort (usually sent from a button labeled Abort)
             */
  Abort = 3,
  /**         
             The dialog box return value is Retry (usually sent from a button labeled Retry)
             */
  Retry = 4,
  /**         
             The dialog box return value is Ignore (usually sent from a button labeled Ignore)
             */
  Ignore = 5,
  /**         
             The dialog box return value is Yes (usually sent from a button labeled Yes)
             */
  Yes = 6,
  /**         
             The dialog box return value is No (usually sent from a button labeled No)
             */
  No = 7
}
export interface ShowDataArgs {
  Data: any;
  VisibleColumns: string[];
  AllowEdit: boolean;
  DataCaption: string;
  Title: string;
  LongDescription: string;
  OKCaption: string;
  CancelCaption: string;
}
export interface IAdvancedDialogManager {
  ShowData(args: ShowDataArgs);
}
export interface IFollowupTask {
  ShowDialog(): DialogResult;
  DoNotShow(): boolean;
}

export interface IProgressDialog {
  TotalSteps: number;
  CurrentStep: number;
  ProcessCancelled();
  SetProgress(stepNum: number, bigText: string, smallText: string);
  CloseDialog();
  AddMessage(key: string, message: string);
  ClearStatus();
  MarkComplete(bComplete: boolean);
  AllowCancellation(bAllow: boolean);
  Indeterminate: boolean;
  SetTitle(title: string);
  SetKeyLookupData(
    listing: any,
    valueMember: string,
    displaymember: string,
    keyColumnCaption: string
  );
}
export interface ICancellableProcess {
  SetTrackingDialog(dlg: IProgressDialog);
}

export interface IDlgDoNotShowAgain {
  /**
         *Show a "Do Not Show Again" dialog - an OK/cancel dialog that the user can choose never to show again.
        @param msg the message to show
        @param regKey the TDF_UserPreferences/PropertyName (with ModuleName=Dialogs) to hold the value indicating whether the dialog should be shown again
        @param title the title bar text Default "Notice"
        @param defaultResponse the response that should be returned by default if the dialog is not shown Default "OK"
        @param defaultDoNotAskText  Default "Stop displaying this message."
        @param buttons The text to show in the "Do not ask again" portion
        @param bShowModal Default "true"           
        */
  ShowDialog(
    msg: string,
    regKey: string,
    title?: string,
    defaultResponse?: DialogResult,
    defaultDoNotAskText?: string,
    buttons?,
    bShowModal?: boolean
  ): DialogResult;
  RespectUserPreferences: boolean;
}

export interface IWaitForm {
  SetCaption(caption: string);
  SetDescription(description: string);
  WaitForm_Show(status: string, caption: string);
  WaitForm_Close();
}

export interface ITDFInputBox {
  ShowDialog(
    prompt: string,
    title?: string,
    defaultResponse?: string,
    icon?: string
  ): DialogResult;
  Response: string;
  Password: boolean;
}

export interface IDialogManager {
  CreateDoNotShowAgain(
    okCaption: string,
    cancelCaption: string
  ): IDlgDoNotShowAgain;

  CreateFollupTaskDialog(): IFollowupTask;

  CreateTDFInputBox(): ITDFInputBox;

  Show(
    message: string,
    title: string,
    buttons: DevExpress.ui.dxButtonOptions
  ): DialogResult;

  CreateWaitForm(): IWaitForm;

  CreateProgressDialog(totalsteps: number): IProgressDialog;

  ShowNotificationBar(message: string, parentControl, noteName: string): any;

  ShowWaitForm(smallText: string, bigText: string);

  CloseWaitForm();

  Yes(message: string, title: string): boolean;

  OK(message: string, title: string): boolean;

  SecurePrompt(message: string, title: string): boolean;
}
export enum MsgBoxStyle {
  //
  // Summary:
  //     OK button only (default). This member is equivalent to the Visual Basic constant
  //     vbOKOnly.
  OkOnly = 0,
  //
  // Summary:
  //     First button is default. This member is equivalent to the Visual Basic constant
  //     vbDefaultButton1.
  DefaultButton1 = 0,
  //
  // Summary:
  //     Application modal message box. This member is equivalent to the Visual Basic
  //     constant vbApplicationModal.
  ApplicationModal = 0,
  //
  // Summary:
  //     OK and Cancel buttons. This member is equivalent to the Visual Basic constant
  //     vbOKCancel.
  OkCancel = 1,
  //
  // Summary:
  //     Abort, Retry, and Ignore buttons. This member is equivalent to the Visual Basic
  //     constant vbAbortRetryIgnore.
  AbortRetryIgnore = 2,
  //
  // Summary:
  //     Yes, No, and Cancel buttons. This member is equivalent to the Visual Basic constant
  //     vbYesNoCancel.
  YesNoCancel = 3,
  //
  // Summary:
  //     Yes and No buttons. This member is equivalent to the Visual Basic constant vbYesNo.
  YesNo = 4,
  //
  // Summary:
  //     Retry and Cancel buttons. This member is equivalent to the Visual Basic constant
  //     vbRetryCancel.
  RetryCancel = 5,
  //
  // Summary:
  //     Critical message. This member is equivalent to the Visual Basic constant vbCritical.
  Critical = 16,
  //
  // Summary:
  //     Warning query. This member is equivalent to the Visual Basic constant vbQuestion.
  Question = 32,
  //
  // Summary:
  //     Warning message. This member is equivalent to the Visual Basic constant vbExclamation.
  Exclamation = 48,
  //
  // Summary:
  //     Information message. This member is equivalent to the Visual Basic constant vbInformation.
  Information = 64,
  //
  // Summary:
  //     Second button is default. This member is equivalent to the Visual Basic constant
  //     vbDefaultButton2.
  DefaultButton2 = 256,
  //
  // Summary:
  //     Third button is default. This member is equivalent to the Visual Basic constant
  //     vbDefaultButton3.
  DefaultButton3 = 512,
  //
  // Summary:
  //     System modal message box. This member is equivalent to the Visual Basic constant
  //     vbSystemModal.
  SystemModal = 4096,
  //
  // Summary:
  //     Help text. This member is equivalent to the Visual Basic constant vbMsgBoxHelp.
  MsgBoxHelp = 16384,
  //
  // Summary:
  //     Foreground message box window. This member is equivalent to the Visual Basic
  //     constant vbMsgBoxSetForeground.
  MsgBoxSetForeground = 65536,
  //
  // Summary:
  //     Right-aligned text. This member is equivalent to the Visual Basic constant vbMsgBoxRight.
  MsgBoxRight = 524288,
  //
  // Summary:
  //     Right-to-left reading text (Hebrew and Arabic systems). This member is equivalent
  //     to the Visual Basic constant vbMsgBoxRtlReading.
  MsgBoxRtlReading = 1048576
}

export interface IWizardScreen {
  Screen: DevExpress.ui.dxMultiViewItemTemplate;
  DisableForward?: boolean;
  DisableBackward?: boolean;
}
export interface ICustomAcctPkgSelectorModel {
  CompanyCaption: string;
  Countries: string[];
  CountriesWithStates: { [index: string]: IStateInfo[] };
  MyCountry: string;
  UDF1: IUDFInfo;
  UDF2: IUDFInfo;
  UDF3: IUDFInfo;
}
export interface IStateInfo {
  Abv: string;
  Name: string;
}
export interface IUDFInfo {
  Label: string;
  PossibleValues: string[];
  UDF_ID: string;
}
export interface ICreateDocumentArgs {
  FileName: string;
  FileType: string;
  SecondaryTDFItemID: string;
  SecondaryTDFItemType: number;
  TDFItemID: string;
  TDFItemType: number;
  TemplateID: number;
}
export interface ITemplateItem {
  CategoryName: string;
  DisplayName: string;
  PlaceHolder: number;
  TemplateID: number;
  TemplateName: string;
  TemplateType: number;
}
export interface IFormTypeInfo {
  AllFormTypes: IFormType[];
  AvailableControlSets: number[];
  DefaultItemFormTypeId: number;
  DefaultItemFormTypeName: string;
  ItemType: itemTypes;
  UseItemFormTypes: boolean;
  UserDefaultTypeID: number;
  UserDefaultTypeName: string;
}
export interface IFormType {
  ID: number;
  ControlSet: number;
  Name: string;
}
export interface EventDetails {
  Name?: string,
  Description: string,
  Organizer: string,
  Start: Date,
  End: Date,
  Type: number,
  EnrollmentFee: number,
  EnrollmentType: number,
  EnrollmentLimit: number
}
export interface ExpenseDetails {
  Description?: string;
  ExpenseDate?: string;
  ExpenseAmount?: number;
  MileageID?: number;
  TotalMiles?: number;
  Reimbursement?: boolean;
  ExpenseAge?: number;
  ExpenseMileage: ExpenseMileage;
}
export interface ExpenseMileage {
  MileageID?: string;
  MileageRate?: number;
  MileageCap?: number;
  MileageRate2?: number;
  UseMileageRate2: boolean,
  OdometerEnd?: number;
  OdometerStart?: number;
  TotalMileageAmount?: number;
    TotalMiles?: number;
    UseMileageRate2?: boolean;
    MileageRate2?: number;
    MileageCap?: number;
}
export interface IItemInfo {
  ItemId: string;
  ItemType: itemTypes;
  iMain: ItemMain;
}
export interface IProductInfo {
  ItemCode: string;
  ItemCodeAlternative: string;
  ProductGroup: string;
  WarehouseID: string;
  CompanyID: string;
}

export interface PendingParent {
  ItemID: string;
  ItemName: string;
  ItemType: number;
}
export interface ItemParent {
  Parent: string;
  ParentId: string;
  ParentType: number;
  ParentLevel: number;
}
export interface PrimaryParent {
  Parent: string;
  ParentId: string;
  ParentType: number;
  ParentLevel: number;
}
export interface ERPInfo {
  BSICompanyID: string;
  BSIMasterAcctID: string;
  BSICustomerID: string;
  BSIShipToID: string;
}
export interface IMain {
  PendingParent: PendingParent;
  FolderStructureLevel: number;
  ItemSubject: string;
  ItemId: string;
  ItemType: number;
  ItemKey: string;
  ItemOwner: string;
  ItemOwnerId: string;
  ItemCreation: Date;
  ItemModDate: Date;
  ItemCreatedBy: string;
  ItemModifiedBy: string;
  ItemDeleted: number;
  ItemParentAccountId: string;
  ItemActive: number;
  ItemAccountType: number;
  ItemAccountTypeName: string;
  ItemFormType: number;
  ItemFormName: string;
  FolderLevel4: string;
  FolderLevel4Id: string;
  FolderLevel4Owner: string;
  FolderLevel4SalesPersonId: string;
  FolderLevel4TerritoryId: string;
  FolderLevel3: string;
  FolderLevel3Id: string;
  FolderLevel3Owner: string;
  FolderLevel3SalesPersonId: string;
  FolderLevel3TerritoryId: string;
  FolderAcctRoot: string;
  FolderAcctRootId: string;
  FolderAcctRootOwner: string;
  FolderAcctRootSalesPersonId: string;
  FolderAcctRootTerritoryId: string;
  FolderAcctPkg: string;
  FolderAcctPkgId: string;
  FolderAcctPkgOwner: string;
  FolderAcctPkgSalesPersonId: string;
  FolderAcctPkgTerritoryId: string;
  FolderName: string;
  FolderId: string;
  FolderOwner: string;
  FolderSalesPersonId: string;
  FolderTerritoryId: string;
  FolderShare: string;
  DefaultFormType: number;
  DefaultFormName: string;
  ItemConvertedToActivity: number;
  ItemAttachmentCount: number;
  ItemBSICompanyID: string;
  ItemParents: ItemParent[];
  PrimaryParent: PrimaryParent;
  SecondaryParent?: any;
  ERPInfo: ERPInfo;
}
export interface ActionList {
  GroupName: string;
  MenuText: string;
  MenuText2?: any;
  disabled?: boolean;
  Action: menuActions | string;
  RelevantType: itemTypes;
  icon: string;
  AvailableInMobile: boolean;
  SupportsMultipleItems: boolean;
  SortOrder: number;
  DisplayOptions: ActionDisplayOptions;
  ShouldAppearInFormMenu: boolean;
  ShouldAppearInGrid: boolean;
  SHouldAppearInBreadcrumbs: boolean;
  GridSourceItemIndicatorItemType: number;
  ErrorMessageWhenUnavailable: string;
  Enabled: boolean;
  Visibility: number;
}
export interface ActionsModel {
  iMain: IMain;
  ParentWebAddress: string;
  IsFave: boolean;
  ActionList: ActionList[];
}
export interface ActionRequestResponse {
  AllowChildrenAndLinks: boolean;
  response: Response;
  ActionsModel: ActionsModel;
  UseCustomAcctPkgLookup: boolean;
}

export interface IActionItem {
  Action: menuActions | string;
  AvailableInMobile: boolean;
  DisplayOptions: ActionDisplayOptions;
  Enabled: boolean;
  ErrorMessageWhenUnavailable: string;
  GroupName: string;
  MenuText: string;
  MenuText2: string;
  RelevantType: itemTypes;
  SHouldAppearInBreadcrumbs: boolean;
  ShouldAppearInFormMenu: boolean;
  ShouldAppearInGrid: boolean;
  SortOrder: number;
  SupportsMultipleItems: boolean;
  Visibility: number;
}
export interface IViewItem {
  Action?: tdfViews;
  DisplayOption?: ActionDisplayOptions;
  Enabled?: boolean;
  ErrorMessageWhenUnavailable?: string;
  MenuText?: string;
  RelevantType: itemTypes;
  Visibility?: number;
  iteminfo?: any;
}
export interface ILinkedItemsArgs {
  TDFContainerType: GridContainerTypes;
  GridContainer: string;
  ToolbarContainer: string;
  GridItemType: itemTypes;
  ContainerItemID: string;
  ContainerItemType: itemTypes;
  GridHeight?: number;
  HasToolbar?: boolean;
  DataFilter?: string;
}

export interface ITDFGridOptions {
  GridContainer?: JQuery;
  GridToolbar?: GridToolbar;
}
export interface IGridDataResponse {
  ClientSideFilter?: any[];
  AdditionalSummaryInfo?: IAdditionalSummaryInfo[];
  AdditionalFooterSummaryInfo?: any[];
  Data?: any; //DataTable
  GridView?: IGridViewDefinition;
  // Messages: any;          // List(of String)
  Meta?: IGridDataResponseMeta;
  // Request: any;           //Models.GridDataRequestModel
  // Schema: any;            //List(Of String)
  TotalRecords?: number;
  //  TotalSummaries?: any;   //GridManagement.TotalSummaryMap
  TotalTime?: number;
  // UseGlobalViews: boolean;
  //  Groups?: IGridDataGroup[];           //List(Of GridDataGroup)

  ViewID?: string;
}
export interface IAdditionalSummaryInfo {
  Count: number;
  GroupFieldName: string;
  GroupNameValue: string;
}
export interface IGridSortInfo {
  Field: string;
  Direction: string;
  Order: number;
}
export interface IGridGroupInfo {
  Field: string;
  Order: number;
}
export interface IGridDataResponseMeta {
  CurrentPage: number;
  TotalPages: number;
  RecordsOnPage: number;
  TotalRecords: number;
  CanExport: boolean;
}
export interface IUDFInfo {
  AcctRootSpecific: boolean;
  ControlType: number;
  Control_ID: string;
  DefaultValue: string;
  FormatString: string;
  IsChild: boolean;
  IsParent: boolean;
  ItemFormTypeSpecific: boolean;
  Locked: boolean;
  PropertyFormat: number;
  RelatedField: boolean;
  RelatedFieldUDF_ID: string;
  Required: boolean;
  SystemField: string;
  SystemTable: string;
  TDFField: string;
  UDF: string;
  UDFFormat: EnumUDFFormat;
  UDF_ID: string;
}
export interface IExtendedDataGridColumn
  extends DevExpress.ui.dxDataGridColumn {
  itemTemplateType?: string;
  bools?: number;
  UDFInfo?: IUDFInfo;
}
interface ColumnBoolean {
  ID: number;
  Display: string;
}
export interface IGridViewLayout {
  ColumnBooleans: ColumnBoolean[];
  Columns: IExtendedDataGridColumn[];
  Name: string;
  ViewID: string;
}
export interface IGridViewDefinition {
  Formatting: any;
  Layout: IGridViewLayout;
  SummaryMap: any;
  GroupSummaryMap: any;
  GroupList?: IGridGroupInfo[];
  SortList?: IGridSortInfo[];
}
export interface IGridDataGroup {
  Items: any; // DataTable
  Field: string;
  Value: string;
  HasSubgroups: boolean;
  Aggregates: any;
}
export interface IGridViewMenuItem extends IGridViewOld {
  Active?: boolean;
  Grid?: Grid;
  Icon: string;
  ItemType: number;
  Load?: boolean;
  isGlobalScope?: boolean;
  Scope: "Global" | "Personal";
  visible?: boolean;
}
export interface IGridInfo {
  GridView: IGridViewMenuItem;
  GridData: IGridDataResponse;
}
export interface ITDFGridOptions {
  GridContainer?: JQuery;
  GridToolbar?: GridToolbar;
}

export interface IDisplayInfoOptions {
  SupportsAttachments: boolean;
  SupportsCopying: boolean;
  SupportsTemplateGeneration: boolean;
}
export interface IDisplayInfo {
  DisplayName: string;
  DisplayNamePlural: string;
  Options?: IDisplayInfoOptions;           //Currently has "SupportsAttachments", "SupportsCopying", and "SupportsTemplateGeneration"
  TypeName: string;
  TypeNamePlural: string;
  TypeNum: itemTypes;
}
export interface IGridToolbarRequest {
  type: itemTypes;
  containertype: GridContainerTypes;
  scope?: enumscope;
  advancedItemViews?: boolean;
}
export interface ICenterItem {
  /**The plural display name of the itemtype  */
  Caption: string;
  /** Think of this as the <Center> Item Type.  Generally speaking, it relates to ItemType OR BI.BSIGrids */
  Type?: number;

  ToolbarModel?: IGridToolbarModel;

  Toolbar?: BaseToolbar;

  /** On the infocenter this is an <li> on BICenter this is a <tr>*/
  jQMenuItem?: JQuery;
  /**The currently active gridview */
  CurrentView?: IGridViewMenuItem;
  /**The index of this tab in the tab panel */
  CurrentTabIndex?: number;
  /** The list of available views for this type */
  ViewList?: IGridViewMenuItem[];
  /** the recently loaded grid response for this type and the view information for each and  */
  LoadedGrids: IGridInfo[];
  /** Some BI Grids do not use a toolbar */
  UsesToolbar: boolean;
  /**Some  grids do not have the same behavior as most other grids */
  UsesDefaultGridBehavior: boolean;
}
export interface IGridToolbarWOViewsModel {
  ToolbarContainerID?: string;
  Options?: DevExpress.ui.dxToolbarOptions;
  ExtraItems?: any;
}
export interface IRecentFavArgs {
  GridContainer: JQuery;
  ToolbarContainer: string;
  ChildOrSourceItemType: itemTypes;
  PreferredTypeToShow: itemTypes;
}
export interface IGridToolbarModel extends IGridToolbarWOViewsModel {
  CanExportData: boolean;
  CategoryList: Array<string>;
  DefaultView: string;
  CurrentView: string;
  DefaultViewName: string;
  GlobalViews: Array<IGridViewOld>;
  GridID: string;
  GridItemType: itemTypes;
  PersonalViews: Array<IGridViewOld>;
  TDFContainerType: GridContainerTypes;
  UsePersonalViews: boolean;
  GridDataRequestType: string;
  //  LinkInfo: any;
  // Linking: boolean;
  // ChangingParent: boolean;
  //  Child: string;
}
export interface IGridViewOld {
  IsDefault: boolean;
  ViewCategory: string;
  ViewGUID: string;
  ViewName: string;
  ItemType: itemTypes;
}
export interface IInfoCenterItem extends ICenterItem {
  TypeName?: string;
}
export interface IBiCenterItem extends ICenterItem {
  MenuSection: BIMenuSection;
  BILevelsUsed: Array<ISummary.SummaryInterfaces.cBSID.EnumType>;
  UsesGoals: boolean;
}
export interface IAdvancedGroupSummaryItem {
  Caption: string;
  Index: number; //This relates to the index selected by the acct field selector.  This is all built up dynamically, and there is no set correlation between an ID and a specific acct field.
  ID?: string;
  ViewList?: IGridViewMenuItem[];
  LoadedGrids: IGridInfo[];
  ToolbarModel?: any;
  CurrentView?: IGridViewMenuItem;
  CurrentTabIndex?: number;
  Type?: number;
}
export interface IGapGroupSummaryItem extends IAdvancedGroupSummaryItem {
  RollUpType: GapRollUpType;
  Measure: number;
}
export interface IAdvancedGroupSummaryBasicItem {
  Caption: string;
  ID?: string;
  Index: number;
}
export interface IGapGroupSummaryBasicItem
  extends IAdvancedGroupSummaryBasicItem {
  RollUpType: number;
  Measure: number;
}
export interface IAdvancedTabItems {
  AdvancedSummaries: any;
  Items: IAdvancedGroupSummaryItem[];
}
export interface IRequestTypeGoalInfo {
  RequestType: BSIGrids;
  GoalList: iNumToGoalList;
}
export interface iNumToGoalList {
  [level: number]: iGoalInfo;
}
export interface iGoalInfo {
  Caption: string;
  GoalType: BSIGoalType;
  UseIfSalespersonOnly: Boolean;
}
export interface DataScopeSelectorModel {
  IncludeSalespersonTab: boolean;
  ListingSourceType: GridContainerTypes;
  ShowLevel3: boolean;
  ShowLevel4: boolean;
  ShowOther: boolean;
  ShowSalesRep: boolean;
  ShowSavedSelections: boolean;
  Tabs: any[];
}
export interface ItemListingNameModel {
  ItemListWithNames: { ID: string; Name: string }[];
}
export interface ProfileInfo {
  SalesmanID: string;
  EmailAddress: string;
  PhoneNumber: string;
  CellPhoneNumber: string;
  Title: string;
  Department: string;
  Office: string;
  InternalExtension: string;
  Extension: string;
  TimeZone: string;
}
export interface IChatUser {
  FullName: string;
  ID: string;
}
export interface IChangeLogData {
  ChangeDate: string;
  CurrentValue: string;
  PreviousValue: string;
  TimeSpan: number;
  UDFName: string;
  UserName: string;
  UserType: string;
}
export interface IControlGroup {
  BackColor: string;
  ContolSet: number;
  ControlGroupID: string;
  ControlGroupOrder: number;
  CustomControlEnabled: boolean;
  DefaultTab: boolean;
  DesignMode: { CanUserEditTabType: boolean };
  EditDisplayName: string;
  FrameCaption: string;
  GroupName: string;
  IsCustomTab: boolean;
  ItemType: number;
  MaxRows: number;
  Name: string;
  TDFGridEnabled: boolean;
  TabName: string;
  UDFEnabled: boolean;
  Visible: boolean;
}
export interface ItemControls {
  [key:string]: IControlData[]; // basically Dict(Of String, List(Of IControlData))
  //ControlDataList: Array<IControlData>;
}

// Represents a single control.
export interface IControlData {
  Properties: IControlProperties;
  DataValue: IUDFSimpleValue;
  SaveValue?: any;
  Positions: string;
  PossibleValues: any;
  ChildItemType?: itemTypes;
  ViewGUID?: string;
  Dx?: DevExpress.ui.Widget;
}
export interface IUDFSimpleValue {
  ValueName?: any;
  Value_ID?: any;
  ValueID?: number;
}
export interface IPrepopulationData {
  TDFObjects?: { TDFField: string; Value: string }[];
  AddressData?: ITDFAddressBlock;
  UDFData?: { UDF_ID: string; SimpleUdfValue: IUDFSimpleValue }[];
}
export interface IControlProperties {
  AcctRoot: number;
  Alignment: number;
  BackColor: string;
  ControlGroup_ID: string;
  ControlSet: number;
  Control_Column: number;
  Control_ID: string;
  Control_Order: number;
  Control_Row: number;
  Control_Type: number;
  DefaultValue: string;
  Enabled: boolean;
  FieldTrackingType: number;
  FieldTrackingValue: number;
  ForeColor: string;
  ItemFormType: number;
  LabelBackColor: string;
  LabelCaption: string;
  LabelForeColor: string;
  Locked: number;
  MainUDF: string;
  MaxDataLength: number;
  OutlookFieldID: number;
  OutlookSelectedField: number;
  PropertyFormat: number;
  ReadOnly: boolean;
  Required: boolean;
  RowsHigh: number;
  ShowOnEmail: boolean;
  ShowOnPrint: boolean;
  ShowOnWeb: boolean;
  SubUDF: string;
  TDFAction: number;
  TDFField: string;
  ToolTip: string;
  UDFFormat: number;
  UDFFormula: string;
  UDFLink_ID: string;
  UDFType: number;
  UDF_ID: string;
  Visible: boolean;
  Position: string;
}
export interface ISubUDF {
  BackColor: string;
  ForeColor: string;
  SubValueID: number;
  SubValueName: string;
  SubValueOrder: number;
  SubValue_ID: string;
  UDFID: number;
  UDF_ID: string;
  ValueID: number;
  Value_ID: string;
}
export interface IUDFItem {
  AcctRoot: number;
  MainUDF: string;
  Name: string;
  RUDF: number;
  SubUDF: string;
  Type: number;
  TypeSpecificInd: number;
  UDF_ID: string;
}
export interface IMainUDF {
  AccountRoot: string;
  AcctTypeID: string;
  BackColor: string;
  ForeColor: string;
  ImagePath: string;
  UDF_ID: string;
  ValueName: string;
  ValueOrder: number;
  Value_ID: string;
}
export interface ITDFAddressBlock {
  AddressString?: string;
  City?: string;
  Country?: string;
  County?: string;
  IsMailingAddress?: boolean;
  Latitude?: number;
  Longitude?: number;
  MapAddress?: string;
  PostalCode?: string;
  State?: string;
  Street?: string;
  Street2?: string;
  Street3?: string;
  Zip2?: string;
}
export interface IItemTypeDescriptor {
  DisplayName: string;
  TypeID: number;
  SystemName: string;
}
export interface IManageItems {
  Description: string;
  ItemList?: Array<IItemList>;
  EditableFields?: Array<string>;
  ReadonlyFields?: Array<string>;
  HiddenFields?: Array<string>;
}
export interface IItemList {
  TypeID: number;
    TypeName: string;
    TypeNamePlural: string;
    DisplayName: string;
    PluralizedDisplayName: string;
    InUse: number;
    BackColor: string;
    TextColor: string;
    BackColorHTML: string;
    TextColorHTML: string;
}
export interface IGoooleResult {
  results: Array<IGoogleGeoAddressResults>;
  status:
  | "OK"
  | "ZERO_RESULTS"
  | "OVER_QUERY_LIMIT"
  | "REQUEST_DENIED"
  | "INVALID_REQUEST"
  | "UNKNOWN_ERROR";
}
export interface IGoogleGeoAddressResults {
  address_components?: Array<IAddressComponent>;
  formatted_address?: string;
  place_id?: string;
  geometry?: IGeometry;
  partial_match?: any;
  postcode_localities?: Array<string>;
  types?: Array<string>;
}
export interface IAddressComponent {
  long_name: string;
  short_name: string;
  types: Array<
    | "street_address"
    | "route"
    | "intersection"
    | "political"
    | "country"
    | "administrative_area_level_1"
    | "administrative_area_level_2"
    | "administrative_area_level_3"
    | "administrative_area_level_4"
    | "administrative_area_level_5"
    | "colloquial_area"
    | "locality"
    | "ward"
    | "sublocality"
    | "neighborhood"
    | "premise"
    | "subpremise"
    | "postal_code"
    | "natural_feature"
    | "airport"
    | "park"
    | "point_of_interest"
    | "floor"
    | "establishment"
    | "postal_town"
    | "parking"
    | "post_box"
    | "room"
    | "street_number"
    | "bus_station"
    | "train_station"
    | "transit_station"
  >;
}
export interface IGeometry {
  bounds?: IBounds;
  viewport?: IViewPort;
  location?: ILocation;
  location_type?:
  | "ROOFTOP"
  | "RANGE_INTERPOLATED"
  | "GEOMETRIC_CENTER"
  | "APPROXIMATE";
}
export interface ILocation {
  lat: number;
  lng: number;
}
export interface IViewPort {
  northeast: ILocation;
  southwest: ILocation;
}
export interface IBounds {
  northeast: ILocation;
  southwest: ILocation;
}
export interface IZipLookupData {
  City: string;
  State: string;
  StateAbv: string;
  County: string;
  Zip: string;
  Country: string;
}
export interface ILinkedItemCounterResult {
  Result: number;
  ChildFilter: string;
  ChildType: number;
  ControlID: string;
}
export interface IShortcutInfo {
  Action: string;
  KeyCombo: string;
  Html: string;
  Description: string;
  Function: any;
}
export interface IWebSyncItems {
  SyncAppointments: number;
  SyncContacts: number;
  SyncTasks: number;
}
export interface IExtendedDxDataGridColumn
  extends DevExpress.ui.dxDataGridColumn {
  itemTemplateType?: string;
  bools?: number;
  UDFInfo?: IUDFInfo;
}

/**
 * Represents a job running on the web serverr
 * */
export interface ILongJob {
    JobId: string;
    InfoURL: string;
    CancelURL: string;
}



// declare module DevExpress.ui {
//     export interface dxActionSheetOptions {
//         deferRendering?: boolean;
//     }
// }
