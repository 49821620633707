import { Notification } from "../dialogs/notification";
import { DashboardVisibility } from "../../enums/dashboards/enums";
import { TDFRequest } from "../../services/request";
import { iDashboard, iDashboardDescriptor } from "../../interfaces/dashboards/idashboarddescriptor";
import { Dashboard } from "./dashboard";
import { MyDashboard } from "./mydashboards";

 
	export class DashboardService {
		static DashboardList(): JQueryPromise<iDashboardDescriptor[]> {

			let d: JQueryDeferred<iDashboardDescriptor[]> = $.Deferred();
			new TDFRequest({ url: TDFRequest.ApiPath + "/Dashboard/List", type: "GET", data: {} }).MakeRequest().done(function (dashboardList: iDashboardDescriptor[]) {
				if ((dashboardList.length === 0) || (dashboardList.filter(function (board, index) { if (board.Visibility === DashboardVisibility.Global) { return board } }).length === 0)) {
					let globalDashboard = MyDashboard.HomepageStructure;
					dashboardList.push({
						ID: globalDashboard.ID,
						Name: globalDashboard.Name,
						Visibility: globalDashboard.Visibility
					});
				}

				//TODO:  Push an empty option
				d.resolve(dashboardList);
			})
			return d.promise();
		}

		static GetDashboard(dashboardID: string): JQueryPromise<iDashboard> {
			let d: JQueryDeferred<iDashboard> = $.Deferred();

			if (dashboardID === MyDashboard.HomepageStructure.ID) {
				return d.promise(d.resolve(MyDashboard.HomepageStructure));
			}

			new TDFRequest({ url: `${TDFRequest.ApiPath}/Dashboard/${dashboardID}`, type: "GET", data: {} }).MakeRequest().done(function (response: iDashboard) {
				d.resolve(response);
      }).fail((a) => {
        if (a.status === 404) {
          new Notification({ message: 'No Dashboard exists with the given key', type: 'error' });
        }
      });
			return d.promise();
		}

    static SaveDashboard(dashboard: Dashboard): JQueryPromise<any> {

			let d: JQueryDeferred<any> = $.Deferred();
			new TDFRequest({ url: `${TDFRequest.ApiPath}/Dashboard/Save`, type: "POST", data: dashboard }).MakeRequest().done(function (response) {
				d.resolve(response);
			});
			return d.promise();
		}

		static DeleteDashboard(dashboardID: string): JQueryPromise<any> {
			let d: JQueryDeferred<any> = $.Deferred();

			new TDFRequest({ url: `${TDFRequest.ApiPath}/Dashboard/${dashboardID}`, type: "DELETE", data: {} }).MakeRequest().done(function (response: iDashboard) {
				d.resolve(response);
			});

			return d.promise();
		}

		static GetGlobalDashboardCount(): JQueryPromise<number> {
			let d: JQueryDeferred<number> = $.Deferred();

			new TDFRequest({ url: `${TDFRequest.ApiPath}/Dashboard/GlobalCount`, type: "GET", data: {} }).MakeRequest().done(function (response: number) {
				d.resolve(response);
			});

			return d.promise();
		}

		static GetPersonalDashboardCount(): JQueryPromise<number> {
			let d: JQueryDeferred<number> = $.Deferred();

			new TDFRequest({ url: `${TDFRequest.ApiPath}/Dashboard/PersonalCount`, type: "GET", data: {} }).MakeRequest().done(function (response: number) {
				d.resolve(response);
			});

			return d.promise();
		}
	}
