import dxCustomStore from 'devextreme/data/custom_store';
import dxDataSource from 'devextreme/data/data_source';
import query from 'devextreme/data/query';
import 'devextreme/ui/button';
import 'devextreme/ui/context_menu';
import 'devextreme/ui/drop_down_box';
import 'devextreme/ui/list';
import 'devextreme/ui/text_box';
import 'devextreme/ui/action_sheet';
import 'devextreme/ui/scroll_view';
import 'devextreme/ui/popover';
import 'devextreme/ui/form';
import 'devextreme/ui/radio_group';
import 'devextreme/ui/toolbar';
import 'devextreme/ui/switch';
import { Dialog } from '../dialogs/dialog';
import { Notification } from '../dialogs/notification';
import { tdfRights } from '../../enums/enums';
import { eventNameSpace, EventTypes } from '../../enums/webevents/enums';

import { WebEvents } from '../../infrastructure/events/ui_events';
import { Preferences } from '../../infrastructure/user/preferences';
import { iDashboardDescriptor } from '../../interfaces/dashboards/idashboarddescriptor';
import { DialogResult } from '../../interfaces/interfaces';
import { DomSafeID, GetDevice, GimmeGUID } from '../../util/allutils';
import { Form } from '../controls/form';
import { EmailGenerator } from '../items/emailgenerator';
import { Dashboard } from './dashboard';

import { DashboardService } from './dashboardservice';
import { DataScopeSelection } from './dynamicdatascopeselector';
import {
  DoesUserHaveRight,
  LoadCompany,
  LoadUser
} from '../../infrastructure/context';
import { DashboardSectionType } from '../../enums/dashboards/enums';

export enum DashboardMode {
  Viewer = 0,
  Designer = 1
}

export enum DashboardVisibility {
  Global = 0,
  Personal = 1
}

export class DashboardSaveOptions {
  DashboardName: string;
  SaveAsOption: DashboardSaveAsOption;
  SummarySettingOption: DashboardSummarySettingSaveOption;
}

export enum DashboardSummarySettingSaveOption {
  UseHomeList = 0,
  UseActiveSettings = 1,
  UsePreviouslySavedSettings = 2
}

export enum DashboardSaveAsOption {
  Overwrite = 0,
  CreateAsPersonal = 1,
  CreateAsGlobal = 2
}

export class DashboardEngineFrameIDs {
  public DashboardContainerID: string = 'dashboardFrame'; // TODO: this is set in the view.  Allow this to be passed in rather than assumed.
  public DashboardToolbarContainerID: string; //TODO:  Remove Designer from these names
  public DashboardToolbarID: string;
  public DashboardToolbarSaveButtonID: string;
  public DashboardToolbarAddSectionButtonID: string;
  public DashboardToolbarAddTileButtonID: string;
  public DashboardLayoutContainerID: string;
  public DashboardToolbarSectionMenuID: string;
  public DashboardToolbarEditSwitchID: string;
  public DashboardToolbarShareButtonID: string;
  public DashboardToolbarSummarySettingsButtonID: string;
  public DashboardToolbarEditDashNameID: string;
  public DashboardSharePopoverID: string;
  public DashboardSummarySettingsMenuID: string;
  public DashboardSummarySettingsPopoverID: string;
  public DashboardSummarySettingsPopoverContentID: string;
  public DashboardSummarySettingsTagCloudID: string;
  public DashboardSummarySettingsChangeScopeBtnID: string;
  public ToolbarDashboardSelectorID: string;
  public ToolbarDashboardSelectorListID: string;
  public SaveFormBodyID: string;
  public SaveFormCancelButtonID: string;
  public SaveFormSaveButtonID: string;
}

export class DashboardEngine {
  private DashboardToolbar: DevExpress.ui.dxToolbar;
  private ToolbarElement: JQuery;
  private DashboardContentElement: JQuery;

  private TheDashboard: Dashboard;

  private _Mode: DashboardMode;
  public get Mode(): DashboardMode {
    let myDashboard = this;
    return myDashboard._Mode;
  }

  private myFrameIDs: DashboardEngineFrameIDs;
  public get FrameIDs(): DashboardEngineFrameIDs {
    let dashEngine = this;
    if (!dashEngine.myFrameIDs) {
      let domSafeDashboardID = DomSafeID(GimmeGUID());

      dashEngine.myFrameIDs = new DashboardEngineFrameIDs();
      dashEngine.myFrameIDs.DashboardToolbarContainerID = `designerToolbarContainer_${domSafeDashboardID}`;
      dashEngine.myFrameIDs.DashboardToolbarID = `designerToolbar_${domSafeDashboardID}`;
      dashEngine.myFrameIDs.DashboardToolbarSaveButtonID = `saveBtn_${domSafeDashboardID}`;
      dashEngine.myFrameIDs.DashboardToolbarAddTileButtonID = `addTileBtn_${domSafeDashboardID}`;
      dashEngine.myFrameIDs.DashboardToolbarAddSectionButtonID = `addSectionBtn_${domSafeDashboardID}`;
      dashEngine.myFrameIDs.DashboardLayoutContainerID = `dashboardLayout_${domSafeDashboardID}`;
      dashEngine.myFrameIDs.DashboardToolbarSectionMenuID = `sectionChoiceMenu_${domSafeDashboardID}`;
      dashEngine.myFrameIDs.DashboardToolbarEditSwitchID = `modeSwitch_${domSafeDashboardID}`;
      dashEngine.myFrameIDs.DashboardToolbarShareButtonID = `shareBtn_${domSafeDashboardID}`;
      dashEngine.myFrameIDs.DashboardToolbarSummarySettingsButtonID = `summarySettingsBtn_${domSafeDashboardID}`;
      dashEngine.myFrameIDs.DashboardToolbarEditDashNameID = `editDashName_${domSafeDashboardID}`;
      dashEngine.myFrameIDs.DashboardSharePopoverID = `sharePopover_${domSafeDashboardID}`;
      dashEngine.myFrameIDs.DashboardSummarySettingsMenuID = `summarySettingsMenu_${domSafeDashboardID}`;
      dashEngine.myFrameIDs.DashboardSummarySettingsPopoverID = `summarySettingsPopover_${domSafeDashboardID}`;
      dashEngine.myFrameIDs.DashboardSummarySettingsPopoverContentID = `summarySettingsPopoverContent_${domSafeDashboardID}`;
      dashEngine.myFrameIDs.DashboardSummarySettingsTagCloudID = `summarySettingsTagCloud_${domSafeDashboardID}`;
      dashEngine.myFrameIDs.DashboardSummarySettingsChangeScopeBtnID = `changeDashboardSummarySettingsBtn_${domSafeDashboardID}`;
      dashEngine.myFrameIDs.ToolbarDashboardSelectorID = `changeDashboardSelector_${domSafeDashboardID}`;
      dashEngine.myFrameIDs.ToolbarDashboardSelectorListID = `changeDashboardSelectorList_${domSafeDashboardID}`;
      dashEngine.myFrameIDs.SaveFormBodyID = `dashboardSaveForm_${domSafeDashboardID}`;
      dashEngine.myFrameIDs.SaveFormCancelButtonID = `dashboardSaveFormCancelButton_${domSafeDashboardID}`;
      dashEngine.myFrameIDs.SaveFormSaveButtonID = `dashboardSaveFormSaveButton_${domSafeDashboardID}`;
    }

    return dashEngine.myFrameIDs;
  }

  private get UserCanEditGlobal() {
    return DoesUserHaveRight(tdfRights.rightEditGlobalDashboard);
  }

  private get UserCanEditPersonal() {
    return DoesUserHaveRight(tdfRights.rightEditPersonalDashboards);
  }

  private get MaxGlobalDashboardCount(): number {
    let count: number = parseInt(
      Preferences.GetCompanyPreference('MaxGlobalDashboardCount', 'TDFMobile')
    );
    return count;
  }

  private get MaxPersonalDashboardCount(): number {
    let count: number = parseInt(
      Preferences.GetCompanyPreference('MaxPersonalDashboardCount', 'TDFMobile')
    );
    return count;
  }

  private get UsersPersonalDashboardCount(): JQueryPromise<number> {
    return DashboardService.GetPersonalDashboardCount();
  }

  private get GlobalDashboardCount(): JQueryPromise<number> {
    return DashboardService.GetGlobalDashboardCount();
  }

  constructor(id?: string) {
    let dashEngine = this;
    dashEngine._Mode = DashboardMode.Viewer;

    dashEngine.Render(id ? id : null);
  }

  private Render(overrideDefaultDashboardID?: string) {
    let dashEngine = this;

    // TODO:  This needs created differently and we probably need to pass in the content element when we instantiate the dashboard engine to know where to create our stuff.
    $(`#${dashEngine.FrameIDs.DashboardContainerID}`).append(
      `<div id='${
      dashEngine.FrameIDs.DashboardToolbarContainerID
      }' /><hr/><div id='${dashEngine.FrameIDs.DashboardLayoutContainerID}' />`
    );

    dashEngine.DashboardContentElement = $(
      `#${dashEngine.FrameIDs.DashboardLayoutContainerID}`
    );
    dashEngine.ToolbarElement = $(
      `#${dashEngine.FrameIDs.DashboardToolbarContainerID}`
    );

    $.when(LoadCompany(), LoadUser()).done(function () {
      dashEngine.RenderDesignerToolbar(
        overrideDefaultDashboardID ? overrideDefaultDashboardID : null
      );
    });
  }

  private dashboardListPromise: JQueryDeferred<iDashboardDescriptor[]>;
  private GetDashboardList(): JQueryPromise<iDashboardDescriptor[]> {
    let dashEngine = this;
    let d: JQueryDeferred<iDashboardDescriptor[]> = $.Deferred();

    if (dashEngine.dashboardListPromise) {
      return <any>dashEngine.dashboardListPromise;
    }

    DashboardService.DashboardList().done(function (
      dashboardList: iDashboardDescriptor[]
    ) {
      dashEngine.dashboardList = dashboardList;
      d.resolve(dashEngine.dashboardList);
    });

    dashEngine.dashboardListPromise = d;
    return d.promise();
  }

  private dashboardList: iDashboardDescriptor[];
  private RenderDesignerToolbar(overrideDefaultDashboardID?: string) {
    let dashEngine = this;
    let toolbarOptions: DevExpress.ui.dxToolbarOptions = {
      items: <Array<DevExpress.ui.dxToolbarItemTemplate>>[
        {
          visible: true,
          widget: 'dxDropDownBox',
          options: <DevExpress.ui.dxDropDownBoxOptions>{
            width: 300,
            visible: true,
            displayExpr: 'Name',
            valueExpr: 'ID',
            elementAttr: { id: dashEngine.FrameIDs.ToolbarDashboardSelectorID },
            hint: 'Right click for options.',
            deferRendering: false,
            dataSource: new dxDataSource({
              store: new dxCustomStore({
                loadMode: 'raw',
                key: 'ID',
                load: function () {
                  let dfd = $.Deferred();

                  dashEngine
                    .GetDashboardList()
                    .done(function (dashboardList: iDashboardDescriptor[]) {
                      dfd.resolve(dashEngine.dashboardList);
                    });

                  return dfd.promise();
                },
                byKey: function (key) {
                  var d = $.Deferred();
                  dashEngine
                    .GetDashboardList()
                    .done(function (dashboardList: iDashboardDescriptor[]) {
                      var items = query(dashEngine.dashboardList)
                        .filter(['ID', '=', key])
                        .toArray();
                      d.resolve(items.length ? items[0] : null);
                    });

                  return d.promise();
                }
              })
            }),
            dropDownOptions: {
              fullScreen: GetDevice().isDevice,
              width: GetDevice().isDevice ? $(window).innerWidth() : 300,
              minHeight: 250,
              resizeEnabled: true
              // TAB:  FYI - showing the title will cause the other buttons from the toolbar to show up in the dropdown's title bar.
              // https://www.devexpress.com/Support/Center/Question/Details/T701545/dxtoolbar-embedded-with-a-dxdropdownbox-will-show-toolbar-elements-in-the-dropdown-s
              //showCloseButton: true,
              //title: "Available Dashboards",
              //showTitle: true
            },
            contentTemplate(e, el: JQuery) {
              e.component
                .option('dataSource')
                .store()
                .byKey(e.component.option('value'))
                .done(v => {
                  el.append(
                    $(
                      `<div id='${
                      dashEngine.myFrameIDs.ToolbarDashboardSelectorListID
                      }' />`
                    )
                  );

                  let dataList = $(
                    `#${dashEngine.myFrameIDs.ToolbarDashboardSelectorListID}`
                  ).dxList({
                    dataSource: dashEngine.DashboardSelector.option(
                      'dataSource'
                    ),
                    keyExpr: 'ID',
                    searchMode: 'contains',
                    searchExpr: 'Name',
                    searchEnabled: true,
                    selectionMode: 'single',
                    itemTemplate(itemData, index, itemElement) {
                      let SetAsDefaultBtn: DevExpress.ui.dxButton = $('<div />')
                        .dxButton({
                          icon:
                            dashEngine.DefaultDashboardID === itemData.ID
                              ? 'fa fa-check-square-o'
                              : 'fa fa-square-o',
                          onClick: e => {
                            e.event.stopPropagation();
                            dashEngine.SetDefaultDashboard(itemData.ID);
                          },
                          stylingMode: 'text',
                          hint: 'Set as Default'
                        })
                        .dxButton('instance');

                      let DeleteBtn: DevExpress.ui.dxButton = $('<div />')
                        .dxButton({
                          icon: 'fa fa-trash-o',
                          onClick: e => {
                            e.event.stopPropagation();

                            //TODO:  Prevent deleting global dashboards if user doesn't have rights.
                            DashboardService.DeleteDashboard(itemData.ID);

                            dashEngine.dashboardList = dashEngine.dashboardList.filter(
                              item => item.ID != itemData.ID
                            );
                            if (
                              dashEngine.DashboardSelector.option('value') ===
                              itemData.ID
                            ) {
                              if (dashEngine.dashboardList.length > 0) {
                                dashEngine.DashboardSelector.option(
                                  'value',
                                  dashEngine.dashboardList[0].ID
                                );
                              }
                            }
                            dashEngine.DashboardSelector.option(
                              'dataSource'
                            ).reload();
                          },
                          stylingMode: 'text',
                          hint: 'Delete Dashboard'
                        })
                        .dxButton('instance');

                      let buttonDiv: JQuery = $('<div />')
                        .css('float', 'right')
                        .append(SetAsDefaultBtn.element(), DeleteBtn.element());

                      itemElement.attr({ title: 'Choose this dashboard' });
                      itemElement.css('display', 'inline-block;');
                      itemElement.append(
                        $(
                          `<div style='float: left; white-space: normal; width: calc(100% - 80px);'><span class="${
                          itemData.Visibility === DashboardVisibility.Global
                            ? 'fa fa-globe text-info'
                            : 'fa fa-home text-info'
                          }" style="margin-right:10px;" />${
                          itemData.Name
                          }</div>`
                        ),
                        buttonDiv
                      );
                    },
                    onSelectionChanged: function (e) {
                      if (e.addedItems.length > 0) {
                        dashEngine.DashboardSelector.option(
                          'value',
                          e.addedItems[0].ID
                        );
                      }

                      dashEngine.DashboardSelector.close();
                    }
                  });

                  let dataListInstance = dataList.dxList('instance');
                  return dataList;
                });
            },
            onValueChanged: function (data: any) {
              DashboardService.GetDashboard(data.value).done(function (
                dashboardModel
              ) {
                dashEngine.TheDashboard = new Dashboard(
                  dashboardModel,
                  dashEngine.Mode
                );
                $(dashEngine.DashboardContentElement).empty();
                dashEngine.TheDashboard.Render(
                  dashEngine.DashboardContentElement
                );
              });
            },
            onInitialized(e) {
              $('#dashboard-set-default').remove();
              $('#tdfbody').append($("<div id='dashboard-set-default' />"));
              $('#dashboard-set-default').dxContextMenu(<
                DevExpress.ui.dxContextMenuOptions
                >{
                  target: e.element,
                  onShowing: e => {
                    e.component.option(
                      'items',
                      dashEngine.Mode === DashboardMode.Designer
                        ? [
                          { text: 'Set Current Dashboard as your default' },
                          { text: 'Rename Current Dashboard' }
                        ]
                        : [{ text: 'Set Current Dashboard as your default' }]
                    );
                  },
                  onItemClick(ev) {
                    switch (ev.itemIndex) {
                      case 0:
                        Preferences.SetPreference(
                          'DefaultDashboardID',
                          e.component.option('value'),
                          'TDFMobile'
                        );
                        dashEngine.SetDefaultDashboard(
                          e.component.option('value')
                        );
                        break;

                      case 1:
                        dashEngine.DashboardSelector.option(
                          'visible',
                          !dashEngine.DashboardSelector.option('visible')
                        );
                        dashEngine.DashboardRenameTextbox.option(
                          'value',
                          dashEngine.TheDashboard.Name
                        );
                        dashEngine.DashboardRenameTextbox.option(
                          'visible',
                          !dashEngine.DashboardRenameTextbox.option('visible')
                        );
                        dashEngine.DashboardRenameTextbox.element()
                          .find('.dx-texteditor-container')
                          .width('auto');
                        break;
                    }
                  }
                });
            }
          },
          location: 'before',
          locateInMenu: 'never'
        },
        {
          widget: 'dxTextBox',
          location: 'before',
          locateInMenu: 'never',
          options: <DevExpress.ui.dxTextBoxOptions>{
            elementAttr: {
              id: dashEngine.FrameIDs.DashboardToolbarEditDashNameID
            },
            visible: false,
            onInitialized: e => {
              let button: DevExpress.ui.dxButton = $('<div />')
                .css('float', 'right')
                .dxButton(<DevExpress.ui.dxButtonOptions>{
                  icon: 'fa fa-pencil',
                  onClick: () => {
                    dashEngine.TheDashboard.Name = e.component.option('value');

                    for (
                      let i = 0, length = dashEngine.dashboardList.length;
                      i < length;
                      i++
                    ) {
                      if (
                        dashEngine.dashboardList[i].ID ===
                        dashEngine.TheDashboard.ID
                      ) {
                        dashEngine.dashboardList[i].Name = e.component.option(
                          'value'
                        );
                      }
                    }

                    e.component.option('visible', false);
                    dashEngine.DashboardSelector.option('visible', true);
                    dashEngine.DashboardSelector.getDataSource()
                      .reload()
                      .done(() => {
                        let z = dashEngine.DashboardSelector.option(
                          'onValueChanged'
                        );
                        dashEngine.DashboardSelector.option(
                          'onValueChanged',
                          null
                        );
                        dashEngine.DashboardSelector.option('value', '');
                        dashEngine.DashboardSelector.option(
                          'value',
                          dashEngine.TheDashboard.ID
                        );
                        dashEngine.DashboardSelector.on('onValueChanged', z);

                        WebEvents.Event.RaiseEvent(
                          EventTypes.DashboardEventTypes.MarkDashboardDirty,
                          eventNameSpace.dashboard
                        );
                      });
                  }
                })
                .dxButton('instance');

              return e.element.append(button.element());
            },
            width: '100%'
          }
        },
        {
          visible: dashEngine.Mode === DashboardMode.Designer,
          widget: 'dxButton',
          options: {
            text: 'Add Dashboard Section',
            elementAttr: {
              id: dashEngine.FrameIDs.DashboardToolbarAddSectionButtonID
            },
            type: 'normal',
            onClick(e) {
              if (
                $(`#${dashEngine.FrameIDs.DashboardToolbarSectionMenuID}`)
                  .length
              ) {
                $(
                  `#${dashEngine.FrameIDs.DashboardToolbarSectionMenuID}`
                ).remove();
              }
              $('body').append(
                $(
                  `<div id='${
                  dashEngine.FrameIDs.DashboardToolbarSectionMenuID
                  }' />`
                )
              );

              let sectionTypes: any[] = [];
              sectionTypes.push({
                Name: 'Whole',
                SectionType: DashboardSectionType.Whole,
                Icon: 'icomoon icon-Whole'
              });
              sectionTypes.push({
                Name: 'One Half - One Half',
                SectionType: DashboardSectionType.OneHalfOneHalf,
                Icon: 'icomoon icon-Half-Half'
              });
              sectionTypes.push({
                Name: 'Two Thirds - One Third',
                SectionType: DashboardSectionType.TwoThirdsOneThird,
                Icon: 'icomoon icon-TwoThird-OneThird'
              });
              sectionTypes.push({
                Name: 'One Third - Two Thirds',
                SectionType: DashboardSectionType.OneThirdTwoThirds,
                Icon: 'icomoon icon-OneThird-TwoThird'
              });
              sectionTypes.push({
                Name: 'Three Equal Columns',
                SectionType: DashboardSectionType.ThreeEqualColumns,
                Icon: 'icomoon icon-Thirds'
              });
              sectionTypes.push({
                Name: 'Six Equal Columns',
                SectionType: DashboardSectionType.SixEqualColumns,
                Icon: 'icomoon icon-Sixths'
              });

              let sectionMenu = $(
                `#${dashEngine.FrameIDs.DashboardToolbarSectionMenuID}`
              )
                .dxActionSheet({
                  items: sectionTypes,
                  showTitle: false,
                  itemTemplate: function (data, index, el) {
                    let btn = $('<div />').dxButton({
                      hint: data.Name,
                      width: 'auto',
                      icon: `${data.Icon} fa-3x`,
                      template: (data, element) => {
                        return $('<i></i>').addClass(data.icon);
                      },
                      onClick: function (e) {
                        WebEvents.Event.RaiseEvent2(
                          EventTypes.DashboardEventTypes.AddSection,
                          dashEngine.TheDashboard.ID,
                          eventNameSpace.dashboard,
                          { SectionInfo: data }
                        );
                      }
                    });

                    el.append(btn);
                  },
                  onContentReady(e) {
                    let container = (e.component as any)._$itemContainer;
                    $(container).dxScrollView({ height: '100%' });
                  },
                  width: 'auto'
                })
                .dxActionSheet('instance');

              if (!GetDevice().isDevice) {
                sectionMenu.option('target', e.element.children()[0]);
                sectionMenu.option('usePopover', true);
              }

              sectionMenu.show();
            }
          },
          location: 'before',
          locateInMenu: GetDevice().isDevice ? 'always' : 'auto'
        },
        {
          visible: dashEngine.Mode === DashboardMode.Designer,
          widget: 'dxButton',
          options: {
            icon: 'fa fa-share-alt',
            elementAttr: {
              id: dashEngine.FrameIDs.DashboardToolbarShareButtonID
            },
            type: 'normal',
            onClick: e => {
              if ($(`#${dashEngine.FrameIDs.DashboardSharePopoverID}`).length) {
                $(`#${dashEngine.FrameIDs.DashboardSharePopoverID}`).remove();
              }

              let popover: JQuery = $(
                `<div id='${dashEngine.FrameIDs.DashboardSharePopoverID}' />`
              );
              $('body').append(popover);

              let sharePopup = $(
                `#${dashEngine.FrameIDs.DashboardSharePopoverID}`
              )
                .dxPopover({
                  target: `#${
                    dashEngine.FrameIDs.DashboardToolbarShareButtonID
                    }`,
                  closeOnOutsideClick: true,
                  width: '500px',
                  contentTemplate: e => {
                    let shareForm: DevExpress.ui.dxForm = $('<div />')
                      .dxForm(<DevExpress.ui.dxFormOptions>{
                        items: [
                          {
                            dataField: 'shareKey',

                            editorType: 'dxTextBox',
                            editorOptions: <DevExpress.ui.dxTextBoxOptions>{
                              readOnly: true,
                              value: dashEngine.TheDashboard.ID
                            }
                          },
                          {
                            itemType: 'button',
                            buttonOptions: {
                              hint: 'Email Link',
                              icon: 'dx-icon icon-email',
                              onClick: e => {
                                new EmailGenerator(
                                  undefined,
                                  `Share Link to my '${
                                  dashEngine.TheDashboard.Name
                                  }' Dashboard`,
                                  `${
                                  window.location.origin
                                  }/Home/Dashboard?id=${
                                  dashEngine.TheDashboard.ID
                                  }`
                                );
                              }
                            }
                          },
                          {
                            template: () => {
                              return '<hr />';
                            }
                          },
                          {
                            dataField: 'receiveDashboard'
                          },
                          {
                            itemType: 'button',
                            buttonOptions: {
                              text: 'Load Dashboard',
                              onClick: e => {
                                let dashboardKey = shareForm.option('formData')
                                  .receiveDashboard;

                                if (
                                  dashboardKey &&
                                  dashboardKey.length === 36
                                ) {
                                  dashEngine.LoadSharedDashboard(
                                    shareForm.option('formData')
                                      .receiveDashboard
                                  );
                                  sharePopup.hide();
                                } else {
                                  new Notification({
                                    message:
                                      'Dashboard Key is not in the correct format',
                                    type: 'error'
                                  });
                                }
                              }
                            }
                          }
                        ]
                      })
                      .dxForm('instance');

                    return shareForm.element();
                  }
                })
                .dxPopover('instance');

              sharePopup.show();
            }
          },
          location: 'after',
          locateInMenu: GetDevice().isDevice ? 'always' : 'auto'
        },
        {
          visible: true, // TODO:  For now, always display the switch, but this should eventually be dependent on user rights for modifying the current dashboard.
          widget: 'dxButton',
          options: {
            text: 'Summary Selection',
            elementAttr: {
              id: dashEngine.FrameIDs.DashboardToolbarSummarySettingsButtonID
            },
            type: 'normal',
            onClick(e) {
              if (
                $(`#${dashEngine.FrameIDs.DashboardSummarySettingsPopoverID}`)
                  .length
              ) {
                $(
                  `#${dashEngine.FrameIDs.DashboardSummarySettingsPopoverID}`
                ).remove();
              }

              let popover: JQuery = $(
                `<div id='${
                dashEngine.FrameIDs.DashboardSummarySettingsPopoverID
                }' />`
              );
              $('body').append(popover);

              if (
                $(`#${dashEngine.FrameIDs.DashboardSummarySettingsMenuID}`)
                  .length
              ) {
                $(
                  `#${dashEngine.FrameIDs.DashboardSummarySettingsMenuID}`
                ).remove();
              }
              $('body').append(
                $(
                  `<div id='${
                  dashEngine.FrameIDs.DashboardSummarySettingsMenuID
                  }'/>`
                )
              );

              let scopeMenu = $(
                `#${dashEngine.FrameIDs.DashboardSummarySettingsMenuID}`
              )
                .dxPopover({
                  target: `#${
                    dashEngine.FrameIDs.DashboardToolbarSummarySettingsButtonID
                    }`,
                  contentTemplate: function (popEl) {
                    let html = `<div id='${
                      dashEngine.FrameIDs
                        .DashboardSummarySettingsPopoverContentID
                      }' />`;
                    popEl.append(html);

                    let eventData = {
                      elem: $(
                        `#${
                        dashEngine.FrameIDs
                          .DashboardSummarySettingsPopoverContentID
                        }`
                      )
                    };
                    WebEvents.Event.RaiseEvent2(
                      EventTypes.DashboardEventTypes.DisplayDashboardScope,
                      dashEngine.TheDashboard.ID,
                      eventNameSpace.request,
                      eventData
                    );
                  },
                  onShowing(e) {
                    $('.tileUsesDashboardScope').addClass(
                      'ShowTilesUsingDashboardScope'
                    );
                  },
                  onHiding(e) {
                    $('.tileUsesDashboardScope').removeClass(
                      'ShowTilesUsingDashboardScope'
                    );
                  },
                  closeOnOutsideClick: true,
                  width: '500px'
                })
                .dxPopover('instance');

              scopeMenu.show();
            }
          },
          location: 'after',
          locateInMenu: GetDevice().isDevice ? 'always' : 'auto'
        },
        {
          visible:
            DoesUserHaveRight(tdfRights.rightEditGlobalDashboard) ||
            DoesUserHaveRight(tdfRights.rightEditPersonalDashboards), // TODO:  For now, always display the switch, but this should eventually be dependent on user rights for modifying the current dashboard.
          widget: 'dxSwitch',
          options: {
            text: 'Edit',
            switchedOffText: 'View Mode',
            switchedOnText: 'Designer Mode',
            height: '50%',
            width: '100px',
            elementAttr: {
              id: dashEngine.FrameIDs.DashboardToolbarEditSwitchID
            },
            //icon: "",
            value: dashEngine.Mode === DashboardMode.Designer,
            onValueChanged(e) {
              dashEngine.SwitchMode(
                e.value ? DashboardMode.Designer : DashboardMode.Viewer
              );
            }
          },
          location: 'after',
          locateInMenu: GetDevice().isDevice ? 'always' : 'auto'
        },
        {
          visible: dashEngine.Mode === DashboardMode.Designer,
          widget: 'dxButton',
          options: {
            hint: 'Save',
            icon: 'save',
            elementAttr: {
              id: dashEngine.FrameIDs.DashboardToolbarSaveButtonID
            },
            type: 'normal',
            onClick(e) {
              dashEngine.PromptForSaving();
            }
          },
          location: 'before',
          locateInMenu: GetDevice().isDevice ? 'always' : 'auto'
        }
      ]
    };

    $(`#${dashEngine.FrameIDs.DashboardToolbarContainerID}`).append(
      $(`<div id='${dashEngine.FrameIDs.DashboardToolbarID}' />`)
    );
    dashEngine.DashboardToolbar = $(
      `#${dashEngine.FrameIDs.DashboardToolbarID}`
    )
      .dxToolbar(toolbarOptions)
      .dxToolbar('instance');

    if (overrideDefaultDashboardID) {
      dashEngine.LoadSharedDashboard(overrideDefaultDashboardID, true);
    } else {
      dashEngine.LoadDefaultDashboard();
    }
  }

  private SetDefaultDashboard(ID: any) {
    let dashEngine = this;
    Preferences.SetPreference('DefaultDashboardID', ID, 'TDFMobile');
    dashEngine.DashboardSelector.getDataSource().reload();
  }

  private get DefaultDashboardID() {
    let dashEngine = this;
    let defaultID = Preferences.GetPreference(
      'DefaultDashboardID',
      'TDFMobile'
    );
    return defaultID;
  }

  private LoadDefaultDashboard() {
    let dashEngine = this;
    let defaultID = dashEngine.DefaultDashboardID;

    dashEngine.LoadDashboard(defaultID);
  }

  private LoadDashboard(id: string) {
    let dashEngine = this;

    if (dashEngine.DashboardSelector.option('value') !== id) {
      dashEngine.DashboardSelector.option('dataSource')
        .store()
        .byKey(id)
        .done(function (result) {
          if (result) {
            dashEngine.DashboardSelector.option('value', id);
          } else {
            dashEngine.DashboardSelector.option('dataSource')
              .store()
              .load()
              .done(function (data) {
                if (data && data.length && data.length > 0) {
                  dashEngine.DashboardSelector.option('value', data[0].ID);
                }
              });
          }
        });
    }
  }

  private LoadSharedDashboard(
    id: string,
    sharedOnInitialLoad: boolean = false
  ) {
    let dashEngine = this;

    DashboardService.GetDashboard(id).done(function (dashboardModel) {
      dashEngine.TheDashboard = new Dashboard(
        dashboardModel,
        dashEngine.Mode,
        sharedOnInitialLoad
      );
      $(dashEngine.DashboardContentElement).empty();
      dashEngine.TheDashboard.Render(dashEngine.DashboardContentElement);

      new Notification({
        message:
          'Shared Dashboard settings loaded. This dashboard still needs to be saved before it will show in your list of available dashboards.',
        type: 'warning',
        displayTime: 10e3
      });
    });
  }

  private _dashboardRenameTextbox: DevExpress.ui.dxTextBox = null;
  private get DashboardRenameTextbox(): DevExpress.ui.dxTextBox {
    let dashEngine = this;
    if (!dashEngine._dashboardRenameTextbox) {
      dashEngine._dashboardRenameTextbox = $(
        `#${dashEngine.FrameIDs.DashboardToolbarEditDashNameID}`
      ).dxTextBox('instance');
    }

    return dashEngine._dashboardRenameTextbox;
  }

  private _dashboardSelector: DevExpress.ui.dxSelectBox = null;
  private get DashboardSelector(): DevExpress.ui.dxSelectBox {
    let dashEngine = this;
    if (!dashEngine._dashboardSelector) {
      dashEngine._dashboardSelector = $(
        `#${dashEngine.FrameIDs.ToolbarDashboardSelectorID}`
      ).dxDropDownBox('instance');
    }

    return dashEngine._dashboardSelector;
  }

  private DoSave(saveOptions: DashboardSaveOptions) {
    let dashEngine = this;
    dashEngine.TheDashboard.Save(saveOptions).done(function () {
      dashEngine.SwitchMode(dashEngine.Mode); //resets the save button to hidden if we are in view mode.
    });
  }

  private saveDialog: Dialog;
  private PromptForSaving() {
    let dashEngine = this;

    $.when(
      dashEngine.GlobalDashboardCount,
      dashEngine.UsersPersonalDashboardCount
    ).done(function (
      globDashboardCount: number,
      personalDashboardCount: number
    ) {
      let maxPersonalDashboardsHit: boolean =
        dashEngine.MaxPersonalDashboardCount <= personalDashboardCount;
      let maxGlobalDashboardsHit: boolean =
        dashEngine.MaxGlobalDashboardCount <= globDashboardCount;

      let bodyContent = `<div id='${dashEngine.FrameIDs.SaveFormBodyID}' />`;
      let canceled: boolean = false;

      let formOptions: DashboardSaveOptions = {
        DashboardName: dashEngine.TheDashboard.Name,
        SummarySettingOption: dashEngine.TheDashboard.SummarySettings
          .UseDashboardSpecificSettings
          ? DashboardSummarySettingSaveOption.UsePreviouslySavedSettings
          : DashboardSummarySettingSaveOption.UseHomeList,
        SaveAsOption: 0
      };

      dashEngine.saveDialog = new Dialog({
        title: 'Save Dashboard...',
        body: bodyContent,
        size: 'size-large',
        closable: false,
        showStandardButtons: 'no',
        buttons: [
          <DevExpress.ui.dxPopupToolbarItem>{
            toolbar: 'bottom',
            location: 'after',
            widget: 'dxButton',
            options: {
              text: 'Save',
              icon: 'save',
              elementAttr: { id: dashEngine.FrameIDs.SaveFormSaveButtonID },
              type: 'success',
              onClick(e) {
                canceled = false;

                dashEngine
                  .HandleMaxDashboardForType(
                    formOptions,
                    maxGlobalDashboardsHit,
                    maxPersonalDashboardsHit
                  )
                  .done(function (allowSave) {
                    if (allowSave) {
                      dashEngine.TheDashboard.Save(formOptions).done(
                        function () {
                          let listItems = dashEngine.dashboardList.filter(
                            function (dshbrdItem, index) {
                              if (
                                dshbrdItem.ID === dashEngine.TheDashboard.ID
                              ) {
                                dshbrdItem.Name = dashEngine.TheDashboard.Name;
                                dshbrdItem.Visibility =
                                  dashEngine.TheDashboard.Visibility;
                                return dshbrdItem;
                              }
                              return null;
                            }
                          );

                          if (listItems && listItems.length === 0) {
                            dashEngine.dashboardList.push({
                              ID: dashEngine.TheDashboard.ID,
                              Name: dashEngine.TheDashboard.Name,
                              Visibility: dashEngine.TheDashboard.Visibility
                            });
                          }

                          dashEngine.DashboardSelector.option(
                            'dataSource'
                          ).reload();

                          // Need to set the option to the correct item, but we don't want the onValueChanged to re-load the dashboard because we have essentially just cahnged the current dashboard to BE the new dashboard and it's already open.
                          let z = dashEngine.DashboardSelector.option(
                            'onValueChanged'
                          );
                          dashEngine.DashboardSelector.option(
                            'onValueChanged',
                            null
                          );
                          dashEngine.DashboardSelector.option(
                            'value',
                            dashEngine.TheDashboard.ID
                          );
                          dashEngine.DashboardSelector.option(
                            'onValueChanged',
                            z
                          );

                          dashEngine.saveDialog.close(
                            undefined,
                            DialogResult.OK
                          );
                        }
                      );
                    }
                  });
              }
            },
            locateInMenu: GetDevice().isDevice ? 'always' : 'auto'
          },
          <DevExpress.ui.dxPopupToolbarItem>{
            toolbar: 'bottom',
            location: 'after',
            widget: 'dxButton',
            options: {
              text: 'Cancel',
              icon: 'remove',
              elementAttr: { id: dashEngine.FrameIDs.SaveFormCancelButtonID },
              type: 'danger',
              onClick(e) {
                canceled = true;
                dashEngine.saveDialog.close(undefined, DialogResult.Cancel);
              }
            },
            locateInMenu: GetDevice().isDevice ? 'always' : 'auto'
          }
        ]
      });

      dashEngine.saveDialog.open().done(function () {
        dashEngine.RenderSaveForm(formOptions);
      });
    });
  }
  private RenderSaveForm(formOptions: DashboardSaveOptions) {
    let dashEngine = this;

    let saveFormBody = $(`#${dashEngine.FrameIDs.SaveFormBodyID}`);

    let dashboardInfoGroupName = 'Dashboard Info';
    let summaryGroupName = 'Summary Settings';
    let SaveOptionsGroupName = 'Save Options';

    let form = new Form(saveFormBody, {
      labelLocation: 'top',
      formData: formOptions,
      colCountByScreen: {
        lg: 1,
        md: 1,
        sm: 1,
        xs: 1
      },
      colCount: 1,
      items: [
        {
          itemType: 'group',
          caption: dashboardInfoGroupName,
          colCount: 1,
          visibleIndex: 0,
          colSpan: 1,
          items: [
            {
              label: {
                text: 'Name'
              },
              editorType: 'dxTextBox',
              dataField: 'DashboardName',
              colSpan: 1
            }
          ]
        },
        {
          itemType: 'group',
          caption: summaryGroupName,
          colCount: 1,
          visibleIndex: 0,
          colSpan: 1,
          items: [dashEngine.SummarySettingsOptions]
        },
        {
          itemType: 'group',
          caption: SaveOptionsGroupName,
          colCount: 1,
          visibleIndex: 0,
          colSpan: 1,
          items: [dashEngine.SaveOptions]
        }
      ]
    });
  }

  private get SaveOptions() {
    let dashEngine = this;

    let options = [];

    if (
      !dashEngine.TheDashboard.SharedAsInitialLoad &&
      ((dashEngine.TheDashboard.IsGlobal && dashEngine.UserCanEditGlobal) ||
        (!dashEngine.TheDashboard.IsGlobal && dashEngine.UserCanEditPersonal))
    ) {
      options.push({ Text: 'Overwrite', Value: 0 });
    }

    if (dashEngine.UserCanEditPersonal) {
      options.push({ Text: 'Create as New Personal', Value: 1 });
    }
    if (dashEngine.UserCanEditGlobal) {
      options.push({ Text: 'Create as New Global', Value: 2 });
    }

    let item = {
      editorType: 'dxRadioGroup',
      dataField: 'SaveAsOption',
      colSpan: 1,
      label: { text: '' },
      editorOptions: {
        dataSource: options,
        displayExpr: 'Text',
        displayValue: 'Value',
        valueExpr: 'Value'
      }
    };

    return item;
  }
  private get SummarySettingsOptions() {
    let dashEngine = this;

    let options = [
      {
        Text: "Dynamically use the user's Home List setting at runtime",
        Value: DashboardSummarySettingSaveOption.UseHomeList
      }
    ];

    if (
      !dashEngine.TheDashboard.SummarySettings.SavedScope ||
      (dashEngine.TheDashboard.SummarySettings.SavedScope &&
        dashEngine.TheDashboard.SummarySettings.SavedScope.CurrentlySelectedItemIDsAsString() !=
        dashEngine.TheDashboard.SummarySettings.ActiveScope.CurrentlySelectedItemIDsAsString())
    ) {
      options.push({
        Text: 'Use current interactive settings',
        Value: DashboardSummarySettingSaveOption.UseActiveSettings
      });
    }

    if (dashEngine.TheDashboard.SummarySettings.SavedScope) {
      options.push({
        Text: 'Use previously saved custom setting',
        Value: DashboardSummarySettingSaveOption.UsePreviouslySavedSettings
      });
    }

    let item = {
      editorType: 'dxRadioGroup',
      dataField: 'SummarySettingOption',
      colSpan: 1,
      label: { text: '' },
      editorOptions: {
        dataSource: options,
        displayExpr: 'Text',
        displayValue: 'Value',
        valueExpr: 'Value',
        itemTemplate: function (itemData, itemIndex, itemElement) {
          $(itemElement).append(`<label>${itemData.Text}</label>`);

          switch (itemData.Value) {
            case 0:
              break;
            case 1:
              let currentValueElement = $('<div />');
              $(itemElement).append(currentValueElement);
              dashEngine.TheDashboard.SummarySettings.ActiveScope.WriteTagCloud(
                currentValueElement
              );
              break;
            case 2:
              let prevValueElement = $('<div />');
              $(itemElement).append(prevValueElement);
              if (dashEngine.TheDashboard.SummarySettings.SavedScope) {
                dashEngine.TheDashboard.SummarySettings.SavedScope.WriteTagCloud(
                  prevValueElement
                );
              }
              break;
          }
        }
      }
    };
    return item;
  }

  private HandleMaxDashboardForType(
    saveOptions: DashboardSaveOptions,
    maxGlobalDashboardsHit: boolean,
    maxPersonalDashboardsHit: boolean
  ): JQueryPromise<boolean> {
    let dashEngine = this;

    let dfd: JQueryDeferred<boolean> = $.Deferred();
    let allowSave = false;

    let saveType: DashboardVisibility =
      saveOptions.SaveAsOption === DashboardSaveAsOption.Overwrite
        ? dashEngine.TheDashboard.Visibility
        : saveOptions.SaveAsOption === DashboardSaveAsOption.CreateAsGlobal
          ? DashboardVisibility.Global
          : DashboardVisibility.Personal;

    if (
      //(((dashEngine.TheDashboard.IsGlobal) && (saveOptions.SaveAsOption === DashboardSaveAsOption.Overwrite)) && maxGlobalDashboardsHit) ||
      //(((!dashEngine.TheDashboard.IsGlobal) && (saveOptions.SaveAsOption === DashboardSaveAsOption.Overwrite)) && maxPersonalDashboardsHit) ||
      (saveOptions.SaveAsOption === DashboardSaveAsOption.CreateAsGlobal &&
        maxGlobalDashboardsHit) ||
      (saveOptions.SaveAsOption === DashboardSaveAsOption.CreateAsPersonal &&
        maxPersonalDashboardsHit)
    ) {
      dashEngine
        .SelectDashboardToOverwriteDialog(saveType, saveOptions)
        .done(function (dashboardToOverwriteSelected) {
          if (dashboardToOverwriteSelected) {
            allowSave = true;
          } else {
            allowSave = false;
          }

          dfd.resolve(allowSave);
        });
    } else {
      allowSave = true;
      return dfd.promise(dfd.resolve(allowSave));
    }

    return dfd.promise();
  }

  private SelectDashboardToOverwriteDialog(
    saveType: DashboardVisibility,
    saveOptions: DashboardSaveOptions
  ): JQueryPromise<boolean> {
    let dashEngine = this;

    let dfd: JQueryDeferred<boolean> = $.Deferred();
    let dashboardToOverwriteSelected: boolean = false;

    let saveTypeString: string = DashboardVisibility[saveType];
    let count: number =
      saveType === DashboardVisibility.Global
        ? dashEngine.MaxGlobalDashboardCount
        : dashEngine.MaxPersonalDashboardCount;

    let dshBrdListID = 'dshBordList';
    let message = `<p>The limit of ${count} ${saveTypeString} dashboards has been met already. Please select a dashboard to <strong>replace</strong>.</p>`;
    let html = `<div>${message}</div><div id='${dshBrdListID}' />`;

    let dashboardSelectDialog = new Dialog(
      {
        title: `Limit Hit for ${saveTypeString} Dashboards`,
        body: html,
        size: 'size-normal',
        closable: false,
        showStandardButtons: 'no',
        buttons: [
          {
            toolbar: 'bottom',
            location: 'after',
            widget: 'dxButton',
            options: <DevExpress.ui.dxButtonOptions>{
              text: 'Save',
              type: 'success',
              icon: 'save',
              onClick: e => {
                let dshBrdList = $(`#${dshBrdListID}`).dxRadioGroup('instance');
                let selectedItem = dshBrdList.option('value');

                if (selectedItem) {
                  dashboardToOverwriteSelected = true;
                  saveOptions.SaveAsOption = DashboardSaveAsOption.Overwrite; // We are updating the save option to overwrite the dashboard we have selected.
                  dashEngine.TheDashboard.ID = selectedItem.ID; // As such, we also have to update the ID
                  dashEngine.TheDashboard.Visibility = saveType; // and visibility to match the dashboard we selected.

                  dashboardSelectDialog.close();
                  dfd.resolve(dashboardToOverwriteSelected);
                }
              }
            }
          },
          {
            toolbar: 'bottom',
            location: 'after',
            widget: 'dxButton',
            options: <DevExpress.ui.dxButtonOptions>{
              text: 'Cancel',
              type: 'danger',
              icon: 'remove',
              onClick: e => {
                dashboardSelectDialog.close();
                dfd.resolve(dashboardToOverwriteSelected);
              }
            }
          }
        ]
      },
      null,
      false,
      false
    );

    dashboardSelectDialog.open().done(function () {
      let dashboardList: DevExpress.ui.dxRadioGroup = $(`#${dshBrdListID}`)
        .dxRadioGroup(<DevExpress.ui.dxRadioGroupOptions>{
          items: dashEngine.dashboardList.filter(a => {
            return a.Visibility === saveType;
          }),
          itemTemplate: (data, index, element) => {
            return data.Name;
          }
        })
        .dxRadioGroup('instance');
    });

    return dfd.promise();
  }

  private GetScopeSettings(): JQueryPromise<DataScopeSelection> {
    let dashEngine = this;

    let myDFD: JQueryDeferred<DataScopeSelection> = $.Deferred();

    let sendDFD: JQueryDeferred<DataScopeSelection> = $.Deferred();

    WebEvents.Event.RaiseEvent2(
      EventTypes.DashboardEventTypes.DashboardScope,
      DomSafeID(dashEngine.TheDashboard.ID),
      eventNameSpace.request,
      { deferred: sendDFD }
    );

    sendDFD.done(function (scopeSettings) {
      myDFD.resolve(scopeSettings);
    });

    return myDFD.promise();
  }

  private SwitchMode(newMode: DashboardMode) {
    let dashEngine = this;
    dashEngine._Mode = newMode;

    // TODO: do this some other way.
    dashEngine.TheDashboard._Mode = newMode;

    let viewerVisibleItems: string[] = [
      dashEngine.FrameIDs.DashboardToolbarSummarySettingsButtonID,
      dashEngine.FrameIDs.ToolbarDashboardSelectorID,
      dashEngine.FrameIDs.DashboardToolbarEditSwitchID
    ];
    if (dashEngine.TheDashboard.IsDirty) {
      viewerVisibleItems.push(dashEngine.FrameIDs.DashboardToolbarSaveButtonID);
    }

    let designerHiddenItems: string[] = [];

    $.each(dashEngine.DashboardToolbar.option('items'), function (index, item) {
      let toolbarItemID: string = dashEngine.DashboardToolbar.option(
        `items[${index.toString()}].options.elementAttr.id`
      );

      let itemShouldBeVisible: boolean = false;

      switch (dashEngine.Mode) {
        case DashboardMode.Designer:
          // Right now, everything is visible in the designer unless it is in the hidden item list.
          itemShouldBeVisible =
            designerHiddenItems.filter(function (designerItem, index, array) {
              return designerItem === toolbarItemID ? designerItem : null;
            }).length === 0;

          break;
        case DashboardMode.Viewer:
          // Right now, everything is hidden in view mode unless it is in the visible item list.
          itemShouldBeVisible =
            viewerVisibleItems.filter(function (viewerItem, index, array) {
              return viewerItem === toolbarItemID ? viewerItem : null;
            }).length >= 1;
          break;
      }

      dashEngine.DashboardToolbar.option(
        `items[${index.toString()}].visible`,
        itemShouldBeVisible
      );
    });

    WebEvents.Event.RaiseEvent(
      EventTypes.DashboardEventTypes.dashboardModeChanged,
      eventNameSpace.dashboard,
      { CurrentMode: dashEngine.Mode }
    );
  }
}
