import { DataScopeInitializer, DataScopeSelection } from "./dynamicdatascopeselector";


export class DashboardTileSettings {
	public Title?: string = "";
	public UseTileSpecificSettings?: boolean = false;
	public InitialScopeSettings?: DataScopeInitializer;
	public Height: string;
	public UseTileMaxHeight: boolean = false;
	public LoadCollapsed: boolean = false;

	private _ScopeSettings?: DataScopeSelection;
	public get ScopeSettings(): DataScopeSelection {
		let theTile = this;
		return theTile._ScopeSettings;
	}

	constructor(initArgs?: Partial<DashboardTileSettings>) {
		let theSettings = this;

		$.each(initArgs, function (k, v) {
			theSettings[<string>k] = v;

		});

		theSettings._ScopeSettings = new DataScopeSelection();
		if (initArgs && initArgs.InitialScopeSettings) {
			theSettings.ScopeSettings.InitSettings(initArgs.InitialScopeSettings);
		}
	}

	ApplyClone(clone: Partial<DashboardTileSettings>) {
		let theSettings = this;
		theSettings.Title = clone.Title;
		theSettings.UseTileSpecificSettings = clone.UseTileSpecificSettings;
		if (clone.ScopeSettings) {
			theSettings.ScopeSettings.ApplyClone(clone.ScopeSettings);
		}
	}

	toJSON(key) {
		let theSettings = this;

		let it: any = {
			Title: theSettings.Title,
			UseTileSpecificSettings: theSettings.UseTileSpecificSettings,
			UseTileMaxHeight: theSettings.UseTileMaxHeight,
			Height: (theSettings.UseTileMaxHeight ? theSettings.Height : 0),
			LoadCollapsed: theSettings.LoadCollapsed
		};

		if (theSettings.UseTileSpecificSettings) {
			let scopeSettings = theSettings.ScopeSettings.GetScopeInitializerSettings();
			if (scopeSettings) {
				it.InitialScopeSettings = scopeSettings;
			}
		}
		return it;
	}
}

export interface iTdFDashboardTile {
	TileInstanceID: string;
	TileType: string;
	Icon: string;
	Column?: number;
	HideTileTitleBar?: boolean;
	DashboardTileSettings?: Partial<DashboardTileSettings>;
	TileSettings?: any;
}