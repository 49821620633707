import { Form } from "../controls/form";
import { Dialog } from "../dialogs/dialog";
import { DashboardMode, LoadStyle } from "../../enums/dashboards/enums";
import {
  DataScopeSelectionMode,
  DataScopeSelectionTypes
} from "../../enums/user/datascope/enums";
import { iTdFDashboardTile } from "../../interfaces/dashboards/idashboarddescriptor";
import { DashboardTileSettings } from "./dashboardtile";
import {
  DataScopeInitializer,
  DynamicDataScopeSelector,
  DynamicDataScopeSelectorSettings
} from "./dynamicdatascopeselector";
import { TileBase, TileScopeType } from "./tilebase";
import { TileConfigurator } from "./tileconfigurator";
import { TileFactory } from "./tilefactory";
import { TileFrameIDs } from "./tileframeids";
import { TileSettingsBase } from "./tilesettingsbase";
import { eventNameSpace, EventTypes } from "../../enums/webevents/enums";
import { AddHandler, AddHandler2, RaiseEvent, RaiseEvent2 } from "../../infrastructure/events/ui_events";
import { DomSafeID, CloneIT, GetDevice } from "../../util/allutils";
//import { Collapse } from 'bootstrap/dist/js/bootstrap.bundle.js'
import "bootstrap/js/src/collapse"

export class TdFDashboardTile implements iTdFDashboardTile {
  public static TileDataScopeSelectorSettings: DynamicDataScopeSelectorSettings = {
    ScopeSelectionType: DataScopeSelectionTypes.BIScope,
    DialogTitle: "Summary Settings",
    AllowDefault: false,
    SelectionMode: DataScopeSelectionMode.Multiple
  };
  public TileInstanceID: string;
  public TileType: string;
  public Icon: string;
  public Column?: number;
  public HideTileTitleBar?: boolean;
  public TileSettings?: TileSettingsBase;
  public DashboardTileSettings?: DashboardTileSettings;
  public InitialScopeSettings?: DataScopeInitializer;
  protected Rendered: boolean = false; // Used at rendering time to determine if we've rendered this.
  protected TileObject: TileBase;
  protected _itemLoadStyle: LoadStyle;
  public get ItemLoadStyle(): LoadStyle {
    let theTile = this;
    return theTile._itemLoadStyle;
  }
  protected Configurator: TileConfigurator;
  private CardElement: JQuery;
  private ContentElement: JQuery;
  private Mode: DashboardMode;
  private IsDirty: Boolean = false;
  constructor(initialMode: DashboardMode, initTileArgs?: Partial<iTdFDashboardTile>) {
    let theTile = this;
    theTile.Mode = initialMode;
    $.each(initTileArgs, function (k, v) {
      if (k != "TileSettings") {
        theTile[<string>k] = v;
      }
    });
    theTile.DashboardTileSettings = new DashboardTileSettings(initTileArgs ? initTileArgs.DashboardTileSettings : null);
    theTile.TileSettings = new TileSettingsBase();
    if (initTileArgs && initTileArgs.TileSettings) {
      theTile.TileSettings.InitSettings(initTileArgs.TileSettings);
    }
    if (theTile.DashboardTileSettings.LoadCollapsed) {
      theTile._itemLoadStyle = LoadStyle.LoadOnClick;
    } else if (theTile.TileType === "LegacyBYOR") {
      theTile._itemLoadStyle = LoadStyle.LoadDelayed;
    } else {
      theTile._itemLoadStyle = LoadStyle.LoadImmediate;
    }
  }
  toJSON(key) {
    let theTile = this;
    let it: iTdFDashboardTile = {
      TileInstanceID: theTile.TileInstanceID,
      TileType: theTile.TileType,
      Icon: theTile.Icon,
      Column: theTile.Column,
      HideTileTitleBar: theTile.HideTileTitleBar,
      TileSettings: theTile.TileObject.Settings,
      DashboardTileSettings: theTile.DashboardTileSettings
    };
    return it;
  }
  CreateTile(newTile: boolean = false) {
    let theDashboardTile = this;
    theDashboardTile.TileObject = TileFactory.CreateTile(theDashboardTile.TileType, theDashboardTile.TileInstanceID, theDashboardTile.TileSettings);
    if (newTile) {
      theDashboardTile.TileObject.Configured = false;
    }
    if (theDashboardTile.TileObject) {
      return true;
    } else {
      return false;
    }
  }
  private mySectionIDs: TileFrameIDs = null;
  public get TileSectionIDs(): TileFrameIDs {
    let theTile = this;
    if (!theTile.mySectionIDs) {
      let domSafeNodeID = DomSafeID(theTile.TileInstanceID);
      theTile.mySectionIDs = new TileFrameIDs();
      theTile.mySectionIDs.TileWrapperID = `tileWrapper_${domSafeNodeID}`;
      theTile.mySectionIDs.SettingsBtnID = `btn${theTile.TileType}Settings_${domSafeNodeID}`;
      theTile.mySectionIDs.MaximizeButtonID = `btn${theTile.TileType}Maximize_${domSafeNodeID}`;
      theTile.mySectionIDs.DeleteButtonID = `btn${theTile.TileType}Delete_${domSafeNodeID}`;
      theTile.mySectionIDs.PinButtonID = `btn${theTile.TileType}Pin_${domSafeNodeID}`;
      theTile.mySectionIDs.CardID = `tile_${theTile.TileType}_${domSafeNodeID}`;
      theTile.mySectionIDs.ScrollViewID = `tileScroller_${domSafeNodeID}`;
      theTile.mySectionIDs.TileTitleID = `tileTitle_${domSafeNodeID}`;
      theTile.mySectionIDs.SettingsPopoverSectionID = `tileSettings_${domSafeNodeID}`;
      theTile.mySectionIDs.CardBodyID = `tileBody_${domSafeNodeID}`;
      theTile.mySectionIDs.CardHeaderID = `cardHeader_${domSafeNodeID}`;
      theTile.mySectionIDs.SettingsPopupID = `popSettings_${domSafeNodeID}`;
      theTile.mySectionIDs.CardHeaderWrapperID = `cardHeaderWrapper_${domSafeNodeID}`;
      theTile.mySectionIDs.TagCloudID = `tileSettingsTagCloud_${domSafeNodeID}`;
      theTile.mySectionIDs.ScopeSupportTextID = `tileSettingsScopeSupportText_${domSafeNodeID}`;
    }
    return theTile.mySectionIDs;
  }
  private get UsesDashboardScope(): boolean {
    let node = this;
    return (node.TileObject.ScopeSupportLevel === TileScopeType.SupportsScopeSettings && !node.DashboardTileSettings.UseTileSpecificSettings); 1
  }
  public BuildTileFrame(container: JQuery) {
    let node = this;
    let interactiveClass: string = !node.TileObject.HasInteractiveSettings ? "noInteractiveTileSettings" : "";
    let scopeClass: string = node.UsesDashboardScope ? "tileUsesDashboardScope" : "";
    let settingsBtn: string = `<i id="${node.TileSectionIDs.SettingsBtnID}" class="fa fa-cogs pull-right configureTileSettings ${interactiveClass}" title = "Edit ${node.DashboardTileSettings.Title} Options" ></i>`;
    let cardSettingsPopoverSection: string = `<div id='${node.TileSectionIDs.SettingsPopoverSectionID}' />`;
    let deleteBtn: string = `<i id="${node.TileSectionIDs.DeleteButtonID}" class="dashboardTileDeleter fa fa-trash pull-right" title = "Remove this tile from this dashboard" > </i>`;
    let cardTitleBar: string = node.HideTileTitleBar ? "" : `
                        <div class="tdfcard-header tdfcard-header-info" id='${      node.TileSectionIDs.CardHeaderWrapperID}'>
                          <div style='min-width: 42px; max-width: 60px; float: right' >
                              ${settingsBtn}
                              <i id="${      node.TileSectionIDs.MaximizeButtonID}" class="fa fa-arrows-alt pull-right" title = "Maximize ${node.DashboardTileSettings.Title}" > </i>
                              ${deleteBtn}
                            </div>
                            <div class="card-collapse-handle" data-toggle='collapse' data-target="#${      node.TileSectionIDs.CardBodyID}" id='${node.TileSectionIDs.CardHeaderID}'>
                                <i class="${node.Icon}"></i><span id=${node.TileSectionIDs.TileTitleID} class='tdfTileTitle'>${node.DashboardTileSettings.Title}</span>
                            </div>                            
                        </div>`;
    let content: string = `
                            <div id='${      node.TileSectionIDs.TileWrapperID}' class='tdfcard ${node.TileType} ${scopeClass}'>
                                ${cardTitleBar}
                                <div id='${      node.TileSectionIDs.CardBodyID}' class="collapse ${node.DashboardTileSettings.LoadCollapsed ? "" : "collapse show"}">
                                    <div class="tdfcard-body " id='${      node.TileSectionIDs.ScrollViewID}'>
                                        ${cardSettingsPopoverSection}
                                        <div id='${node.TileSectionIDs.CardID}'>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        `;
    container.append(content);
    node.ContentElement = $(`#${node.TileSectionIDs.CardID}`);
    node.CardElement = $(`#${node.TileSectionIDs.TileWrapperID}`);
    $(node.CardElement).data("TileID", node.TileInstanceID);
    node.SetHeight();
    AddHandler(EventTypes.DashboardEventTypes.dashboardModeChanged, eventNameSpace.dashboard, $(`#${node.TileSectionIDs.TileWrapperID}`), node.HandleModeChange.bind(node));
    AddHandler2(EventTypes.DashboardEventTypes.RefreshTile, DomSafeID(node.TileInstanceID), eventNameSpace.dashboard, $(`#${node.TileSectionIDs.TileWrapperID}`), node.Render.bind(node));
    AddHandler2(EventTypes.DashboardEventTypes.TileScope, DomSafeID(node.TileInstanceID), eventNameSpace.request, $(`#${node.TileSectionIDs.TileWrapperID}`), node.HandleScopeRequest.bind(node));
    AddHandler(EventTypes.DashboardEventTypes.DashboardSaved, eventNameSpace.dashboard, $(`#${node.TileSectionIDs.TileWrapperID}`), node.MarkClean.bind(node)); if (node.UsesDashboardScope) {
      //TODO:  This needs added and/or removed if the tile settings change.
      //TODO:  How is this differentiated in the future if there's a dashboard in the dashboard and then child dashboard has a predefined summary setting set?
      AddHandler(EventTypes.DashboardEventTypes.dashboardScopeChanged, eventNameSpace.dashboard, $(`#${node.TileSectionIDs.TileWrapperID}`), node.Render.bind(node));
    }
    $(`#${node.TileSectionIDs.SettingsBtnID}`).on("click", function (e) {
      if (node.Mode === DashboardMode.Viewer) {
        let settingsPopover = $(`#${node.TileSectionIDs.SettingsPopoverSectionID}`).dxPopover({
          target: $(`#${node.TileSectionIDs.SettingsBtnID}`),
          closeOnOutsideClick: true,
          //position: "bottom",
          width: GetDevice().isDevice ? '95%' : 600,
          shading: true,
          shadingColor: "rgba(0, 0, 0, 0.5)",
          deferRendering: true,
          contentTemplate: function (element: JQuery) {
            element.append($(`<div id='${node.TileSectionIDs.SettingsPopupID}' />`));
          },
          onShown: function (e) {
            node.RenderSettings($(`#${node.TileSectionIDs.SettingsPopupID}`));
          },
          onHidden: function (e) {
            $(`#${node.TileSectionIDs.SettingsPopupID}`).empty();
          }
        }).dxPopover("instance");
        settingsPopover.show();
      } else {
        node.LoadConfigurator();
      }
    });
    $(`#${node.TileSectionIDs.DeleteButtonID}`).on("click", function (e) {
      node.Remove();
    });
    $(`#${node.TileSectionIDs.MaximizeButtonID}`).on("click", function (e) {
      let bodyContent = "<div id='expandedTileContent'></div>";
      let dialog = new Dialog({ title: node.DashboardTileSettings.Title, body: bodyContent, closable: true, onHidden: function (e) { $("#" + node.TileSectionIDs.CardID).appendTo($("#" + node.TileSectionIDs.TileWrapperID)); node.TileObject.RefreshUI(); } });
      dialog.open().done(function () {
        $("#" + node.TileSectionIDs.CardID).appendTo("#expandedTileContent");
        node.TileObject.RefreshUI();
      });
    });
    if (node.DashboardTileSettings.LoadCollapsed) {
      $(`#${node.TileSectionIDs.CardHeaderID}`).addClass("delayLoaded")
      $(`#${node.TileSectionIDs.CardHeaderID}`).on("click", function (e) {
        $(`#${node.TileSectionIDs.CardHeaderID}`).removeClass("delayLoaded");
        node.Render();
      });
    }
    AddHandler2(EventTypes.DashboardEventTypes.updateTileTitle, node.TileObject.ID, eventNameSpace.dashboard, $(`#${node.TileSectionIDs.CardHeaderID}`), node.UpdateTitle.bind(node));
  }
  public Remove() {
    let node = this;
    $(`#${node.TileSectionIDs.TileWrapperID}`).remove();
    RaiseEvent(EventTypes.DashboardEventTypes.removeTile, eventNameSpace.dashboard, { ID: node.TileObject.ID });
  }
  public Collapse() {
    let node = this;
    let cardBody: JQuery = $(`#${node.TileSectionIDs.CardBodyID}`);
    let cardHeader: JQuery = $(`#${node.TileSectionIDs.CardHeaderID}`);

    if (!cardHeader.hasClass("delayLoaded")) {
      cardBody.collapse('hide');
    }
  }
  public Expand() {
    let node = this;
    // Use the click event on the card header so that any delayed tiles that get expanded end up having the content rendered.
    let cardHeader: JQuery = $(`#${node.TileSectionIDs.CardHeaderID}`);
    cardHeader.click();
  }
  private SetHeight() {
    let theDashboardTile = this;
    let height: string = "";

    if (theDashboardTile.DashboardTileSettings.UseTileMaxHeight) {
      let heightNum: number = parseInt(theDashboardTile.DashboardTileSettings.Height);
      if (heightNum != NaN) {
        if (heightNum === 0) {
          height = "300px";
        }
        else if (heightNum.toString() == theDashboardTile.DashboardTileSettings.Height) {
          height = heightNum.toString() + "px";
        }
        else {
          height = theDashboardTile.DashboardTileSettings.Height;
        }
      }
      else {
        height = theDashboardTile.DashboardTileSettings.Height;
      }

      //$(`#${theDashboardTile.TileSectionIDs.CardBodyID}`).attr("style", "max-height: " + height);
      $(`#${theDashboardTile.TileSectionIDs.CardID}`).attr("style", "height: " + height);
    }
    else {
      if (theDashboardTile.TileObject.DefaultHeight != "") {
        $(`#${theDashboardTile.TileSectionIDs.CardID}`).attr("style", "height: " + theDashboardTile.TileObject.DefaultHeight);
      }
      else {
        $(`#${theDashboardTile.TileSectionIDs.CardID}`).removeAttr("style");
      }
    }

    if ($(`#${theDashboardTile.TileSectionIDs.ScrollViewID}`).data("dxScrollView")) {
      $(`#${theDashboardTile.TileSectionIDs.ScrollViewID}`).dxScrollView("instance").option("height", height);
    }
    else {
      let scrollerOptions: DevExpress.ui.dxScrollViewOptions = {};
      scrollerOptions = {
        height: height,
        direction: 'vertical'
      };
      $(`#${theDashboardTile.TileSectionIDs.ScrollViewID}`).dxScrollView(scrollerOptions);

    }
  }
  public MarkDirty() {
    let theDashboardTile = this;
    theDashboardTile.IsDirty = true;
    $(theDashboardTile.CardElement).addClass("tdfcardUnsaved").addClass("btnWarning");
    RaiseEvent(EventTypes.DashboardEventTypes.MarkDashboardDirty, eventNameSpace.dashboard);
  }
  public MarkClean() {
    let theDashboardTile = this;
    theDashboardTile.IsDirty = false;
    $(theDashboardTile.CardElement).removeClass("tdfcardUnsaved").removeClass("btnWarning");
  }
  public Render(): JQueryPromise<any> {
    let theDashboardTile = this;
    let dfd: JQueryDeferred<any> = $.Deferred();
    if (theDashboardTile.TileObject.NeedsConfigured && !theDashboardTile.TileObject.Configured && !theDashboardTile.TileObject.BeingConfigured) {
      theDashboardTile.LoadConfigurator().done(function () {
        return dfd.resolve();
      });
    } else {
      theDashboardTile.TileObject.RenderTile(theDashboardTile.ContentElement).done(function () {
        dfd.resolve();
      });
    }
    return dfd.promise();
  }
  public LoadConfigurator(): JQueryPromise<any> {
    let theDashboardTile = this;
    let dfd: JQueryDeferred<any> = $.Deferred();
    let contentElement: JQuery = $(`#${theDashboardTile.TileSectionIDs.CardID}`);
    let backupFormData = CloneIT(theDashboardTile.FormData);
    if (!theDashboardTile.Configurator) {
      theDashboardTile.Configurator = new TileConfigurator(theDashboardTile);
    }
    theDashboardTile.TileObject.BeingConfigured = true;
    theDashboardTile.Configurator.Configure(contentElement).done(function (canceled) {
      if (canceled && !theDashboardTile.TileObject.Configured) {        // If the tile was never configured.
        theDashboardTile.Remove();
        return dfd.resolve;
      } else if (canceled) {
        theDashboardTile.DashboardTileSettings.ApplyClone(backupFormData.DashboardTileSettings);
        theDashboardTile.TileObject.ApplyNewSettings(backupFormData.TileSettings);
      } else {
        theDashboardTile.TileObject.Configured = true;
        let newSettings = JSON.stringify(theDashboardTile.TileObject.Settings);
        let oldSettings = JSON.stringify(backupFormData.TileSettings);
        if (newSettings != oldSettings) {
          theDashboardTile.MarkDirty();
          RaiseEvent(EventTypes.DashboardEventTypes.MarkDashboardDirty, eventNameSpace.dashboard);
        }
        RaiseEvent2(EventTypes.DashboardEventTypes.updateTileTitle, theDashboardTile.TileObject.TileInstanceID, eventNameSpace.dashboard, { Title: theDashboardTile.DashboardTileSettings.Title });
        theDashboardTile.TileObject.BeingConfigured = false;
        theDashboardTile.SetHeight();
      }
      theDashboardTile.Render().done(function () {
        return dfd.resolve;
      });
    });
    return dfd.promise();
  }
  private get FormData() {
    let theDashboardTile = this;
    let myFormData: any = {
      DashboardTileSettings: theDashboardTile.DashboardTileSettings,
      TileSettings: theDashboardTile.TileObject.Settings
    };
    return myFormData;
  }
  public RenderSettings(element): JQueryPromise<any> {
    let theDashboardTile = this;
    let dfd: JQueryDeferred<any> = $.Deferred();
    $.when(theDashboardTile.TileObject.GetInteractiveTileSettings()).done(function (tileSettings) {
      if (tileSettings) {
        // Add a Tag Cloud for the selected summary settings of this tile.
        tileSettings.push(theDashboardTile.GetTagCloud(true));
        let settingsFormID = `tileSettingsForm_${DomSafeID(theDashboardTile.TileInstanceID)}`;
        element.append($(`<div id='${settingsFormID}'></div>`));
        $(`#${settingsFormID}`).dxForm({
          formData: theDashboardTile.FormData,
          colCount: 1,
          items: tileSettings
        });
      }
      return dfd.resolve();
    });
    return dfd.promise();
  }
  public RenderSettingsForDesigner(contentElement: JQuery): JQueryPromise<any> {
    let theDashboardTile = this;
    let dfd: JQueryDeferred<any> = $.Deferred();
    let summaryGroupName = "Summary Settings";
    let heightGroupName = "Height Options";
    $.when(theDashboardTile.TileObject.GetConfigurableTileSettings()).done(function (tileSettings) {
      let form;
      form = new Form(contentElement, {
        labelLocation: "top",
        formData: theDashboardTile.FormData,
        colCountByScreen: {
          lg: 1,
          md: 1,
          sm: 1,
          xs: 1
        },
        colCount: 1,
        height: contentElement.outerHeight(),
        items: [
          {
            //visible: tileSettings ? true : false,
            itemType: "group",
            caption: `General Settings`,
            colCount: 1,
            visibleIndex: 0,
            colSpan: 1,
            items: theDashboardTile.GetGeneralSettingItems()
          },
          {
            visible: tileSettings ? true : false,
            itemType: "group",
            caption: `${theDashboardTile.TileObject.Name} Settings`,
            colCount: 1,
            visibleIndex: 0,
            colSpan: 1,
            items: tileSettings
          },
          {
            itemType: "group",
            caption: summaryGroupName,
            name: summaryGroupName,
            visible: true,
            colCount: 1,
            visibleIndex: 0,
            colSpan: 1
          },
          {
            itemType: "group",
            caption: heightGroupName,
            name: heightGroupName,
            visible: true,
            colCount: 1,
            visibleIndex: 0,
            colSpan: 1
          }
        ],
        onInitialized: function (e) {
          e.component.itemOption(summaryGroupName, "items", theDashboardTile.GetContextSettingItems(summaryGroupName, e.component));
          e.component.itemOption(heightGroupName, "items", theDashboardTile.GetHeightSettingItems(heightGroupName, e.component));
        }
      });
      return dfd.resolve();
    }
    );
    return dfd.promise();
  }
  private GetTagCloud(forceVisible: boolean = false): DevExpress.ui.dxFormSimpleItem {
    let theDashboardTile = this;
    let item: DevExpress.ui.dxFormSimpleItem = {
      name: "TagCloud",
      label: { visible: false },
      visible: true,
      template: function (data, itemElement) {
        let scopeText: string = ``;
        if (theDashboardTile.TileObject.ScopeSupportLevel === TileScopeType.AlwaysUsesHomeList) {
          scopeText = "This tile always uses the home list settings.";
        } else if (theDashboardTile.TileObject.ScopeSupportLevel === TileScopeType.SupportsScopeSettings) {
          if (theDashboardTile.DashboardTileSettings.UseTileSpecificSettings) {
            scopeText = `This tile uses the following summary settings and does not inherit from the dashboard.`;
          } else {
            scopeText = `This tile inherits the summary settings of the dashboard.`;
          }
        } else {
          // Leave scopeText blank so as not to render anything.
          scopeText = `This tile uses pre-defined summary settings that are not configurable in the dashboard.`;
        }
        let hr = "";
        if (theDashboardTile.TileObject.ScopeSupportLevel === TileScopeType.SupportsScopeSettings) {
          hr = "<hr>";
        }
        let html = `${hr}<div id='${theDashboardTile.mySectionIDs.ScopeSupportTextID}'>${scopeText}</div><div id='${theDashboardTile.mySectionIDs.TagCloudID}' class='biTagCloud'></div>`;
        itemElement.append(html);
        theDashboardTile.BuildTileSettingsTagCloud();
      }
    };
    return (item);
  }
  protected GetContextSettingItems(formGroupName: string, form: DevExpress.ui.dxForm): Array<DevExpress.ui.dxFormSimpleItem | DevExpress.ui.dxFormGroupItem | DevExpress.ui.dxFormTabbedItem | DevExpress.ui.dxFormEmptyItem | DevExpress.ui.dxFormButtonItem> {
    let theDashboardTile = this;
    let items: Array<DevExpress.ui.dxFormSimpleItem | DevExpress.ui.dxFormGroupItem | DevExpress.ui.dxFormTabbedItem | DevExpress.ui.dxFormEmptyItem | DevExpress.ui.dxFormButtonItem> = [];
    items = [
      {
        label: {
          text: "Use Tile-Specific Summary Settings"
        },
        dataField: "DashboardTileSettings.UseTileSpecificSettings",
        editorType: "dxCheckBox",
        //helpText: "When enabled, you will select a specific folder structure item or unique TdF item that this tile will be providing data on.  Otherwise, the tile will use the context set on the dashboard it is included on.",
        colSpan: 1,
        visible: theDashboardTile.TileObject.ScopeSupportLevel === TileScopeType.SupportsScopeSettings,  // Display this if the tile is set to have it's own context.
        editorOptions: <DevExpress.ui.dxCheckBoxOptions>{
          onValueChanged: function (e) {
            form.itemOption(`[${formGroupName}].ScopeSettingsBtn`, "visible", e.value);
            form.itemOption(`[${formGroupName}].TagCloud`, "visible", e.value);
          }
        }
      },
      theDashboardTile.GetTagCloud(),
      <DevExpress.ui.dxFormButtonItem>{
        name: "ScopeSettingsBtn",
        label: { visible: false },
        visible: theDashboardTile.DashboardTileSettings.UseTileSpecificSettings,  // Display this if the tile is set to have it's own context.
        editorType: "dxButton",
        editorOptions: {
          type: "info",
          text: "Select Summary Settings",
          onClick: () => {
            //TODO:  Should I also refresh the tile?
            let callBack = function () {
              theDashboardTile.BuildTileSettingsTagCloud();
              theDashboardTile.Render();
            };
            // TODO:  This eventually needs to factor in the types that the tile is tagged with so that only specific things are selected.
            let sumSettingSelector = new DynamicDataScopeSelector(theDashboardTile.DashboardTileSettings.ScopeSettings, TdFDashboardTile.TileDataScopeSelectorSettings);
            sumSettingSelector.DisplayPanel(callBack);
          }
        }
      }
    ];
    return (items);
  }
  private BuildTileSettingsTagCloud() {
    let theDashboardTile = this;
    let tagCloud: JQuery = $(`#${theDashboardTile.mySectionIDs.TagCloudID}`);
    tagCloud.empty();
    theDashboardTile.DashboardTileSettings.ScopeSettings.WriteTagCloud(tagCloud);
  }
  private GetHeightSettingItems(formGroupName: string, form: DevExpress.ui.dxForm): Array<DevExpress.ui.dxFormSimpleItem | DevExpress.ui.dxFormGroupItem | DevExpress.ui.dxFormTabbedItem | DevExpress.ui.dxFormEmptyItem | DevExpress.ui.dxFormButtonItem> {
    let theTile = this;
    let items: Array<DevExpress.ui.dxFormSimpleItem | DevExpress.ui.dxFormGroupItem | DevExpress.ui.dxFormTabbedItem | DevExpress.ui.dxFormEmptyItem | DevExpress.ui.dxFormButtonItem> = [];
    items = [
      {
        label: {
          text: "Load Tile Collapsed Initially"
        },
        dataField: "DashboardTileSettings.LoadCollapsed",
        editorType: "dxCheckBox",
        colSpan: 1,
      },
      {
        label: {
          text: "Set Tile's Maximum Height"
        },
        dataField: "DashboardTileSettings.UseTileMaxHeight",
        editorType: "dxCheckBox",
        colSpan: 1,
        editorOptions: <DevExpress.ui.dxCheckBoxOptions>{
          onValueChanged: function (e) {
            // Note:  I'm using the cssClass to hide it rather than just making the item visible because I don't want the form to re-draw itself when this item becomes visible.
            //form.itemOption(`[${formGroupName}].MaxHeightBox`, "visible", e.value);
            let elem = form.element().find(".maxHeightBoxClass");
            if (e.value) {
              elem.removeClass("hidden");
            }
            else {
              elem.addClass("hidden");
            }
          }
        }
      },
      {
        name: "MaxHeightBox",
        label: {
          text: "Maximum Height (pixels)"
        },
        cssClass: theTile.DashboardTileSettings.UseTileMaxHeight ? "maxHeightBoxClass" : "maxHeightBoxClass hidden",    // Note:  I'm using the cssClass to hide it rather than just making the item visible because I don't want the form to re-draw itself when this item becomes visible.
        //visible: theTile.DashboardTileSettings.UseTileMaxHeight,
        dataField: "DashboardTileSettings.Height",
        editorType: "dxTextBox",
        colSpan: 1,
      }
    ];
    return items;
  }
  private GetGeneralSettingItems(): Array<DevExpress.ui.dxFormSimpleItem | DevExpress.ui.dxFormGroupItem | DevExpress.ui.dxFormTabbedItem | DevExpress.ui.dxFormEmptyItem | DevExpress.ui.dxFormButtonItem> {
    let theTile = this;
    let items: Array<DevExpress.ui.dxFormSimpleItem | DevExpress.ui.dxFormGroupItem | DevExpress.ui.dxFormTabbedItem | DevExpress.ui.dxFormEmptyItem | DevExpress.ui.dxFormButtonItem> = [];
    items = [
      {
        label: {
          text: "Title"
        },
        dataField: "DashboardTileSettings.Title",
        editorType: "dxTextBox",
        colSpan: 1,
      }
    ];
    return (items);
  }
  private HandleScopeRequest(e, data) {
    let node = this;
    if (node.DashboardTileSettings.UseTileSpecificSettings) {
      data.deferred.resolve(node.DashboardTileSettings.ScopeSettings);
    } else {
      RaiseEvent(EventTypes.DashboardEventTypes.DashboardScope, eventNameSpace.request, { deferred: data.deferred });
    }
  }
  private HandleModeChange(e, data) {
    let node = this;
    node.Mode = data.CurrentMode;
  }
  private UpdateTitle(e, data) {
    let node = this;
    //TODO:  This doesn't get updated if the tile is set to a delayed load.
    $(`#${node.TileSectionIDs.TileTitleID}`).text(data.Title);
  }
}
