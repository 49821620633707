import { DashboardSectionType } from "../../enums/dashboards/enums";
import { iColumnInfo, iTdFDashboardTile } from "../../interfaces/dashboards/idashboarddescriptor";
import { Dashboard } from "./dashboard";
import { DashboardSection } from "./dashboardsection";
import { TdFDashboardTile } from "./tdfdashboardtile";
export class SixEqualColumnDashboardSection extends DashboardSection {
  public SectionType: DashboardSectionType = DashboardSectionType.SixEqualColumns;
  private designerTiles: iTdFDashboardTile[];
  private myColumns: iColumnInfo[];
  protected readonly SectionClassName: string = "tdfSixEqualColumnDashboardSection";
  // Column IDs
  private ColOneID: string;
  private ColTwoID: string;
  private ColThreeID: string;
  private ColFourID: string;
  private ColFiveID: string;
  private ColSixID: string;
  // Columns
  private columnOne: JQuery;
  private columnTwo: JQuery;
  private columnThree: JQuery;
  private columnFour: JQuery;
  private columnFive: JQuery;
  private columnSix: JQuery;
  protected get ColumnOne(): JQuery {
    let myDashboardSection = this;
    if (!myDashboardSection.columnOne) {
      myDashboardSection.columnOne = $(`#${myDashboardSection.ColOneID}`);
    }
    return (myDashboardSection.columnOne);
  }
  protected get ColumnTwo(): JQuery {
    let myDashboardSection = this;
    if (!myDashboardSection.columnTwo) {
      myDashboardSection.columnTwo = $(`#${myDashboardSection.ColTwoID}`);
    }
    return (myDashboardSection.columnTwo);
  }
  protected get ColumnThree(): JQuery {
    let myDashboardSection = this;
    if (!myDashboardSection.columnThree) {
      myDashboardSection.columnThree = $(`#${myDashboardSection.ColThreeID}`);
    }
    return (myDashboardSection.columnThree);
  }
  protected get ColumnFour(): JQuery {
    let myDashboardSection = this;
    if (!myDashboardSection.columnFour) {
      myDashboardSection.columnFour = $(`#${myDashboardSection.ColFourID}`);
    }
    return (myDashboardSection.columnFour);
  }
  protected get ColumnFive(): JQuery {
    let myDashboardSection = this;
    if (!myDashboardSection.columnFive) {
      myDashboardSection.columnFive = $(`#${myDashboardSection.ColFiveID}`);
    }
    return (myDashboardSection.columnFive);
  }
  protected get ColumnSix(): JQuery {
    let myDashboardSection = this;
    if (!myDashboardSection.columnSix) {
      myDashboardSection.columnSix = $(`#${myDashboardSection.ColSixID}`);
    }
    return (myDashboardSection.columnSix);
  }
  constructor(tiles: iTdFDashboardTile[], parentDashboard: Dashboard) {
    super(tiles, parentDashboard);
    let myDashboardSection = this;
    myDashboardSection.SetIDs();
  }
  private SetIDs() {
    let myDashboardSection = this;
    myDashboardSection.ColOneID = `colOne_${myDashboardSection.SectionID}`;
    myDashboardSection.ColTwoID = `colTwo_${myDashboardSection.SectionID}`;
    myDashboardSection.ColThreeID = `colThree_${myDashboardSection.SectionID}`;
    myDashboardSection.ColFourID = `colFour_${myDashboardSection.SectionID}`;
    myDashboardSection.ColFiveID = `colFive_${myDashboardSection.SectionID}`;
    myDashboardSection.ColSixID = `colSix_${myDashboardSection.SectionID}`;
  }
  protected get ColumnInfo(): iColumnInfo[] {
    let myDashboardSection = this;
    if (!myDashboardSection.myColumns) {
      myDashboardSection.myColumns = [];
      let col1 = { ColumnID: myDashboardSection.ColOneID, ColumnName: 0, Class: "col-md-2" };
      let col2 = { ColumnID: myDashboardSection.ColTwoID, ColumnName: 1, Class: "col-md-2" };
      let col3 = { ColumnID: myDashboardSection.ColThreeID, ColumnName: 2, Class: "col-md-2" };
      let col4 = { ColumnID: myDashboardSection.ColFourID, ColumnName: 3, Class: "col-md-2" };
      let col5 = { ColumnID: myDashboardSection.ColFiveID, ColumnName: 4, Class: "col-md-2" };
      let col6 = { ColumnID: myDashboardSection.ColSixID, ColumnName: 5, Class: "col-md-2" };
      myDashboardSection.myColumns.push(col1);
      myDashboardSection.myColumns.push(col2);
      myDashboardSection.myColumns.push(col3);
      myDashboardSection.myColumns.push(col4);
      myDashboardSection.myColumns.push(col5);
      myDashboardSection.myColumns.push(col6);
    }
    return myDashboardSection.myColumns;
  }
  protected GetTileContainer(node: TdFDashboardTile) {
    let myDashboardSection = this;
    let targetContainer: JQuery = null;
    if (node.Column === 0) {
      targetContainer = myDashboardSection.ColumnOne;
    }
    else if (node.Column === 1) {
      targetContainer = myDashboardSection.ColumnTwo;
    }
    else if (node.Column === 2) {
      targetContainer = myDashboardSection.ColumnThree;
    }
    else if (node.Column === 3) {
      targetContainer = myDashboardSection.ColumnFour;
    }
    else if (node.Column === 4) {
      targetContainer = myDashboardSection.ColumnFive;
    }
    else if (node.Column === 5) {
      targetContainer = myDashboardSection.ColumnSix;
    }
    return targetContainer;
  }
}
