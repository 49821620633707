import { RaiseEvent } from "../../infrastructure/events/ui_events";
import { DashboardScopeSettingsFrameIDs } from "./dashboardscopesettingsframeids";
import {
  DataScopeInitializer,
  DataScopeSelection,
  DynamicDataScopeSelector
} from "./dynamicdatascopeselector";
import { TdFDashboardTile } from "./tdfdashboardtile";
import { eventNameSpace, EventTypes } from "../../enums/webevents/enums";
import { DomSafeID, GimmeGUID, CloneIT } from "../../util/allutils";
import { LoadUser } from "../../infrastructure/context";
import { Preferences } from "../../infrastructure/user/preferences";
import {
  DashboardSummarySettingSaveOption,
  DashboardMode
} from "../../enums/dashboards/enums";

export interface iDashboardSummarySettings {
  UseDashboardSpecificSettings: boolean;
  InitialScope?: DataScopeInitializer;
}

export enum DashboardSummarySettingsMode {
  Active = 0,
  Saved = 1
}

export class DashboardSummarySettings {
  private useDashboardSpecificSettings: boolean = false;
  private activeScope: DataScopeSelection;
  private savedScope: DataScopeSelection;

  private myFrameIDs: DashboardScopeSettingsFrameIDs;
  private get FrameIDs(): DashboardScopeSettingsFrameIDs {
    let settings = this;
    if (!settings.myFrameIDs) {
      let domSafeDashboardID = DomSafeID(GimmeGUID());

      settings.myFrameIDs = new DashboardScopeSettingsFrameIDs();
      settings.myFrameIDs.ActiveTagCloudID = `activeSummarySettingsTagCloud_${domSafeDashboardID}`;
      settings.myFrameIDs.ActiveChangeScopeBtnID = `activeChangeSummarySettings_${domSafeDashboardID}`;
      settings.myFrameIDs.SavedTagCloudID = `savedSummarySettingsTagCloud_${domSafeDashboardID}`;
      settings.myFrameIDs.SavedChangeScopeBtnID = `savedChangeSummarySettings_${domSafeDashboardID}`;
      settings.myFrameIDs.SummarySettingsTabControlID = `summarySettingsTabControl_${domSafeDashboardID}`;
      settings.myFrameIDs.UseDashboardScopeChkBoxID = `useDashboardScopeChkBox_${domSafeDashboardID}`;
      settings.myFrameIDs.SavedSpecificSettingsFrameID = `savedSpecificSettingsFrame_${domSafeDashboardID}`;
    }

    return settings.myFrameIDs;
  }

  public get UseDashboardSpecificSettings(): boolean {
    let settings = this;
    return settings.useDashboardSpecificSettings;
  }

  public get SavedScope(): DataScopeSelection {
    let settings = this;
    return settings.savedScope;
  }

  public get ActiveScope(): DataScopeSelection {
    let settings = this;
    return settings.activeScope;
  }

  public constructor(initialSettings: iDashboardSummarySettings) {
    let settings = this;
    settings.useDashboardSpecificSettings = initialSettings
      ? initialSettings.UseDashboardSpecificSettings
      : false;

    if (settings.useDashboardSpecificSettings && initialSettings.InitialScope) {
      settings.savedScope = new DataScopeSelection();
      settings.savedScope.InitSettings(initialSettings.InitialScope);

      settings.activeScope = new DataScopeSelection();
      settings.activeScope.InitSettings(initialSettings.InitialScope);
    } else {
      settings.activeScope = new DataScopeSelection();

      LoadUser().done(function () {
        let dataScopeInitializer: DataScopeInitializer = {
          ActiveSelectionLevel: parseInt(
            Preferences.GetPreference("HomeType", "ManagersConsole")
          ),
          SelectedItems: Preferences.GetPreference("HomeList", "ManagersConsole").split(",").map(function (value, index, array) { return { ItemID: value, Name: "" }; })
        };

        settings.activeScope.InitSettings(dataScopeInitializer);
      });
    }
  }

  ApplyNewScope(summarySettingOption: DashboardSummarySettingSaveOption) {
    let settings = this;

    switch (summarySettingOption) {
      case DashboardSummarySettingSaveOption.UseHomeList:
        settings.useDashboardSpecificSettings = false;
        break;
      case DashboardSummarySettingSaveOption.UseActiveSettings:
        if (!settings.SavedScope) {
          settings.savedScope = new DataScopeSelection();
        }
        settings.SavedScope.ApplyClone(CloneIT(settings.ActiveScope));
        settings.useDashboardSpecificSettings = true;
        break;
      case DashboardSummarySettingSaveOption.UsePreviouslySavedSettings:
        // Do nothing because this will automatically happen.
        break;
    }
  }

  toJSON(key) {
    let settings = this;

    let it: iDashboardSummarySettings = {
      UseDashboardSpecificSettings: settings.UseDashboardSpecificSettings
    };

    if (settings.UseDashboardSpecificSettings) {
      let scopeSettings = settings.SavedScope.GetScopeInitializerSettings();
      if (scopeSettings) {
        it.InitialScope = scopeSettings;
      }
    }
    return it;
  }

  public LoadEditor(el: JQuery, mode: DashboardMode) {
    let settings = this;

    el.empty();
    switch (mode) {
      case DashboardMode.Viewer:
        settings.DisplayForViewMode(el);
        break;
      case DashboardMode.Designer:
        settings.DisplayForViewMode(el); // We moved the way summary settings get set for the dashboard into the Save dialog.
        //settings.DisplayForDesignerMode(el);
        break;
    }
  }

  private DisplayForViewMode(el: JQuery) {
    let settings = this;
    settings.DisplayViewSettings(el);
  }
  private DisplayForDesignerMode(el: JQuery) {
    let settings = this;
    el.append(`<div id='${settings.FrameIDs.SummarySettingsTabControlID}' />`);

    let tabs: any[] = [
      {
        title: "Currently Viewing",
        index: 0
      },
      {
        title: "Dashboard Settings",
        index: 1
      }
    ];

    $(`#${settings.FrameIDs.SummarySettingsTabControlID}`).dxTabPanel({
      loop: false,
      items: tabs,
      selectedIndex: 0,
      animationEnabled: false,
      swipeEnabled: false,
      itemTemplate: function (itemData, itemIndex, itemElement) {
        switch (itemData.index) {
          case 0:
            settings.DisplaySettings(
              $(itemElement),
              DashboardSummarySettingsMode.Active
            );
            break;
          case 1:
            settings.DisplayDashboardSettings($(itemElement));
            break;
        }
      }
    });
  }

  private DisplayViewSettings(el: JQuery) {
    let settings = this;
    settings.DisplaySettings(el, DashboardSummarySettingsMode.Active);
  }

  private DisplaySettings(el: JQuery, mode: DashboardSummarySettingsMode) {
    let settings = this;

    var tagCloudID: string = settings.FrameIDs.ActiveTagCloudID;
    var changeScopeBtnID: string = settings.FrameIDs.ActiveChangeScopeBtnID;

    if (mode === DashboardSummarySettingsMode.Saved) {
      tagCloudID = settings.FrameIDs.SavedTagCloudID;
      changeScopeBtnID = settings.FrameIDs.SavedChangeScopeBtnID;
    }

    var message: string =
      "Interacting with these summary settings will apply only to the highlighted tiles set to inherit their settings from the dashboard.";

    el.append(`<div id='${tagCloudID}' class='biTagCloud' />`);
    el.append($(`<hr />`));
    el.append(`<div>${message}</div>`);
    el.append(`<div id='${changeScopeBtnID}' class='pull-right' />`);

    settings.BuildTagCloud(tagCloudID, mode);

    $(`#${changeScopeBtnID}`).dxButton({
      elementAttr: {
        class: "NavBarButton"
      },
      text: "Change Summary Settings",
      height: 35,
      onClick: function (e) {
        let callBack = function () {
          settings.BuildTagCloud(tagCloudID, mode);
          if (mode === DashboardSummarySettingsMode.Active) {
            RaiseEvent(
              EventTypes.DashboardEventTypes.dashboardScopeChanged,
              eventNameSpace.dashboard
            );
          }
        };

        //    // TODO:  This eventually needs to factor in the types that the dashboard is tagged with so that only specific things are selected.
        let sumSettingSelector = new DynamicDataScopeSelector(
          mode === DashboardSummarySettingsMode.Active
            ? settings.ActiveScope
            : settings.SavedScope,
          TdFDashboardTile.TileDataScopeSelectorSettings
        );
        sumSettingSelector.DisplayPanel(callBack);
      }

      // Need a button that applies current active settings to the saved
      // Need a button to apply updated saved settings to the active.
    });
  }
  private DisplayDashboardSettings(element: JQuery) {
    let settings = this;

    element.append(
      $(`<div id='${settings.FrameIDs.UseDashboardScopeChkBoxID}' />`)
    );
    element.append($("<hr />"));
    element.append(
      $(`<div id='${settings.FrameIDs.SavedSpecificSettingsFrameID}' />`)
    );

    let savedSettingsFrame = $(
      `#${settings.FrameIDs.SavedSpecificSettingsFrameID}`
    );

    $(`#${settings.FrameIDs.UseDashboardScopeChkBoxID}`).dxCheckBox({
      text: "Use Default Home List Setting",
      value: !settings.UseDashboardSpecificSettings,
      onOptionChanged: function (e) {
        settings.useDashboardSpecificSettings = !e.value;
        // TODO:  Show the other options
        if (settings.UseDashboardSpecificSettings) {
          if (settings.SavedScope === undefined) {
            settings.savedScope = new DataScopeSelection();
          }

          savedSettingsFrame.empty();
          settings.DisplaySettings(
            savedSettingsFrame,
            DashboardSummarySettingsMode.Saved
          );
        } else {
          savedSettingsFrame.empty();
        }
      }
    });

    if (settings.UseDashboardSpecificSettings) {
      if (settings.SavedScope === undefined) {
        settings.savedScope = new DataScopeSelection();
      }

      settings.DisplaySettings(
        savedSettingsFrame,
        DashboardSummarySettingsMode.Saved
      );
    }
  }

  // TODO:  Should this accept the mode or the settings object???
  private BuildTagCloud(
    tagCloudID: string,
    mode: DashboardSummarySettingsMode
  ) {
    let settings = this;
    let tagCloud: JQuery = $(`#${tagCloudID}`);

    tagCloud.empty();
    if (mode === DashboardSummarySettingsMode.Active) {
      settings.ActiveScope.WriteTagCloud(tagCloud);
    } else {
      settings.SavedScope.WriteTagCloud(tagCloud);
    }
  }
}
