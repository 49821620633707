export class TileFrameIDs {
	public TileWrapperID: string;
	public SettingsBtnID: string;
	public MaximizeButtonID: string;
	public DeleteButtonID: string;
	public PinButtonID: string;
	public CardID: string;
	public ScrollViewID: string;
	public TileTitleID: string;
	public SettingsPopoverSectionID: string;
	public CardBodyID: string;
	public CardHeaderID: string;
	public CardHeaderWrapperID: string;
	public SettingsPopupID: string;
	public TagCloudID: string;
	public ScopeSupportTextID: string;
}
