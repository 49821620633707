import { DashboardSectionType } from "../../enums/dashboards/enums";
import { iDashboard } from "../../interfaces/dashboards/idashboarddescriptor";
import { ChartTileSettings } from "../tiles-general/charts";
  export class MyDashboard {

    static get HomePageChartTileSettings(): Partial<ChartTileSettings> {
      //TODO:  These should be defaulted from the personal preferences
      let chartTileSettings = {
        ChartName: "Sales/Profit Trend (This Year)",
        ChartViewType: "line",
      };

      return (chartTileSettings);
    }

    //TODO:  Maybe remove icon from Tile object

    public static GlobalDashboardID: string = "13E99934-ABE4-41BB-B279-E54DFAC93116";
    public static HomepageStructure: iDashboard = {
      ID: MyDashboard.GlobalDashboardID,
      Name: "Global Dashboard",
      Visibility: 0,
      Owner: "Global",
      //SummarySettings: {
      //  UseDashboardSpecificSettings: true,
      //  InitialScope: {
      //    ActiveSelectionLevel: 7,
      //    SelectedIds: ["{AED89147-A486-4DE0-A5DE-14FC36F61490}"]
      //  }
      //},
      Sections:
        [
          {
            SectionType: DashboardSectionType.TwoThirdsOneThird,
            Tiles: [
              { Column: 0, TileInstanceID: "{F31D4A42-02E9-487A-B521-AA8590C8DF28}", TileType: "DailySummary", Icon: "fa fa-calendar-o", DashboardTileSettings: { Title: "Daily Summary" } },
              { Column: 1, TileInstanceID: "{B6A078DC-D17C-42FE-9F94-6D67B917F3A1}", TileType: "Welcome", Icon: "", DashboardTileSettings: { Title: "Welcome" } },
            ]
          },
          {
            SectionType: DashboardSectionType.TwoThirdsOneThird,
            Tiles: [
              { Column: 0, TileInstanceID: "{24E6152E-9D0B-494A-883E-FB1E8AD0FD2B}", TileType: "Charts", Icon: "fa fa-pie-chart", DashboardTileSettings: { Title: "Visualizations" }, TileSettings: MyDashboard.HomePageChartTileSettings },
              { Column: 1, TileInstanceID: "{335BB535-0552-41F9-A923-CD2336EFF945}", TileType: "ByorReportList", Icon: "fa fa-line-chart", DashboardTileSettings: { Title: "BYOR Reports" } },
            ]
          },
          {
            SectionType: DashboardSectionType.Whole,
            Tiles: [
              { TileInstanceID: "{CDC93DE7-9597-441C-BF64-972416D2DFE3}", TileType: "Alerts", Icon: "fa fa-exclamation-circle", DashboardTileSettings: { Title: "Alerts List" } },
            ]
          },
        ]
    };
  }
