import { TileBase, TileScopeType } from "../dashboards/tilebase";
import { TileSettingsBase } from "../dashboards/tilesettingsbase";
import { Preferences } from "../../infrastructure/user/preferences";
import { TDFRequest } from "../../services/request";
import { GetDevice } from "../../util/allutils";
import { itemTypes, enumscope, CenterType } from "enums/enums";
import { createitem } from "components/items/factory/itemfactory";
import { SummaryTypes } from "enums/alerts/enum";
import { BSIGrids } from "enums/bi/enums";
import { BI__ItemDetailOrderForm } from "../items/bi/bi_itemdetailorderform";
import { BI__ItemDetailQuoteForm } from "../items/bi/bi_itemdetailquoteform";
import { DisplayInfo } from "../../infrastructure/context";

export class SingleAlert {
  static MakeAlertDataGrid(alertID, data, itemElement) {
    let summaryItems = { totalItems: [] };
    //$(itemElement[0]).append(data);
    let gridCols: any[] = data.Fields.map(function (item) {
      // item.FieldName = item.FieldName.Replace(":", "___");

      if (item.Summary && item.Summary != "None") {
        if (!isNaN(parseInt(item.Summary))) {
          let numericSummary = Number(item.Summary);

          for (let sumTypeItem in SummaryTypes) {
            if ((parseInt(sumTypeItem) != NaN) && (parseInt(sumTypeItem) != 0)) {
              if ((numericSummary & Number(sumTypeItem)) == Number(sumTypeItem)) {
                let sumItem: any = {
                  column: item.FieldName,
                  summaryType: SummaryTypes[sumTypeItem].toLowerCase()
                };

                if ((item.Formats === "C") && (Number(sumTypeItem) != SummaryTypes.Count)) {
                  sumItem.valueFormat = { type: "currency", precision: 2 };
                }
                else if (item.Formats === "d") {
                  sumItem.valueFormat = { type: "shortDate" };
                }
                else if (item.Formats === "") {
                  sumItem.valueFormat = SingleAlert.GetBestGuessFormat(item.FieldName);
                }

                summaryItems.totalItems.push(sumItem);
              }
            }
          }
        }
        else {
          let sumItem: any = {
            column: item.FieldName,
            summaryType: item.Summary.toLowerCase()
          };

          if (item.Formats === "C") {
            sumItem.valueFormat = { type: "currency", precision: 2 };
          }
          else if (item.Formats === "d") {
            sumItem.valueFormat = { type: "shortDate" };
          }
          else if (item.Formats === "") {
            sumItem.valueFormat = SingleAlert.GetBestGuessFormat(item.FieldName);
          }



          summaryItems.totalItems.push(sumItem);
        }
      }

      let retval: any = {
        caption: item.Caption,
        dataField: item.FieldName
      };

      if (item.Formats === "C") {
        retval.dataType = "number";
        retval.format = { type: "currency", precision: 2 };
      }
      else if (item.Formats === "P") {
        retval.dataType = "number";
        retval.format = { type: "percent", precision: 2 };
      }
      else if (item.Formats === "d") {
        retval.dataType = "date";
        //retval.format = {type: "percent", precision: 2};
      }
      else if (item.Formats === "") {
        let bestGuess = SingleAlert.GetBestGuessFormat(item.FieldName);
        if (bestGuess) {
          retval.dataType = bestGuess.dataType;
          retval.format = bestGuess.format;
        }
      }

      return retval;
    });

    let alertItemType = data.Meta[0].ItemType;
    if (SingleAlert.AlertTypeSupportsOpening(alertItemType)) {
      let openLinkCol: DevExpress.ui.dxDataGridColumn | any = SingleAlert.CreateAlertOpenItemColumn(alertItemType);
      gridCols.unshift(openLinkCol);
    }

    let gridElem = $(`<div id='${alertID}' />`)
    $(itemElement[0]).append(gridElem);
    let alertGrid = gridElem.dxDataGrid({
      groupPanel: { allowColumnDragging: true, visible: true },
      rowAlternationEnabled: true,
      columns: gridCols,
      allowColumnResizing: true,
      allowColumnReordering: true,
      dataSource: data.AlertData,
      filterRow: {
        visible: true,
        showOperationChooser: true
      },
      summary: summaryItems,
      columnAutoWidth: true,
      width: '100%'
    }).dxDataGrid("instance");
  }

  private static GetBestGuessFormat(fieldName: string): any {
    let retval: any;
    fieldName = fieldName.toLowerCase();

    if (fieldName.includes('bsi')) {
      if (fieldName.includes('percent') || fieldName.includes('%') || fieldName.includes('variance')) {
        retval = {
          dataType: "number",
          format: { type: "percent", precision: 2 }
        };
      }
      else if (fieldName.includes('sales') || fieldName.includes('profit')) {
        retval = {
          dataType: "number",
          format: { type: "currency", precision: 2 }
        };
      }
      else if (fieldName.includes('unit')) {
        retval = {
          dataType: "number"
        };
      }
      else if (fieldName.includes('ar_')) {
        retval = {
          format: { type: "currency", precision: 2 },
          dataType: "number"
        };
      }
      else if (fieldName.includes('date')) {
        retval = {
          dataType: "date"
        }
      }
    }

    return retval;
  }

  private static AlertTypeSupportsOpening(itemType): boolean {
    let retVal: boolean = false;

    if (itemTypes[itemType] != null) {
      retVal = true;
    }
    else if ((itemType === BSIGrids.Orders) || (itemType === 11015)) // TDF.Alert.Alerts.SpecialAlerts.BSIOrdersForSalespeople
    {
      retVal = true;
    }
    else if ((itemType === BSIGrids.Quotes) || (itemType === 11016)) // TDF.Alert.Alerts.SpecialAlerts.BSIQuotesForSalespeople
    {
      retVal = true;
    }

    return (retVal);
  }

  private static CreateAlertOpenItemColumn(itemType): DevExpress.ui.dxDataGridColumn | any {
    let drillDownCol: DevExpress.ui.dxDataGridColumn | any = {
      alignment: "center",
      width: GetDevice().isDevice ? "25px" : "60px",
      visible: true,
      allowEditing: false,
      fixed: true,
      cellTemplate: function (container: any, options: any) {
        let text = GetDevice().isDevice ? "+" : "Open"
        $(`<a title'Open' href='javascript:void (0)'>${text}</a>`).on("click", function () {
          //alert(itemType);
          SingleAlert.OpenAlertItem(itemType, options);
        }).appendTo(container);
      }
    };

    return drillDownCol;
  }

  private static OpenAlertItem(itemType, dataRowOptions) {
    if (itemType == itemTypes.itemQuote) {
      var urlString = window.location.origin + `/Item/?itemID=${dataRowOptions.data["TDF GUID"]}&itemType=${itemType}`;
      window.open(urlString);
    }
    else if (itemTypes[itemType] != null) {
      createitem(itemType, { ItemID: dataRowOptions.data["TDF GUID"] });
    }
    else if ((itemType === BSIGrids.Orders) || (itemType === 11015)) // TDF.Alert.Alerts.SpecialAlerts.BSIOrdersForSalespeople
    {
      let item = new BI__ItemDetailOrderForm(dataRowOptions.data["OrderNumber"], CenterType.Bi);    // Note:  I don't think CenterType matters
      item.Initialize();
    }
    else if ((itemType === BSIGrids.Quotes) || (itemType === 11016)) // TDF.Alert.Alerts.SpecialAlerts.BSIQuotesForSalespeople
    {
      let item = new BI__ItemDetailQuoteForm(dataRowOptions.data["QuoteId"], CenterType.Bi);    // Note:  I don't think CenterType matters
      item.Initialize();
    }
  }
}

export class Alerts extends TileBase {
  public HasInteractiveSettings: boolean = true;
  protected _ScopeSupportLevel: TileScopeType = TileScopeType.ScopeNotSupported;
  protected _Name: string = "Alert List";

  readonly CONTAINER_ID: string = "#homepage-alerts";
  readonly URL: string = "/Alert/List/";
  readonly dataUrl: string = "/Alert/Data/";

  private alertAccordian: DevExpress.ui.dxAccordion;

  constructor(
    tileInstanceID: string,
    settings: TileSettingsBase,
    settingsClassRef: typeof TileSettingsBase = TileSettingsBase
  ) {
    super(tileInstanceID, settings, settingsClassRef);
  }

  public Render(contentElement: JQuery): JQueryPromise<any> {
    let alertsObj = this;

    let dfd: JQueryDeferred<any> = $.Deferred();

    let content: string = `<div id="alertSpinner"></div>
			<div id="homepage-alerts"></div>`;

    $(contentElement).append(content);

    alertsObj.RequestData().done(function () {
      return dfd.resolve();
    });

    return dfd.promise();
  }

  public GetConfigurableTileSettings(): JQueryPromise<Array<DevExpress.ui.dxFormSimpleItem | DevExpress.ui.dxFormGroupItem | DevExpress.ui.dxFormTabbedItem | DevExpress.ui.dxFormEmptyItem | DevExpress.ui.dxFormButtonItem>> {
    let alertsObj = this;
    let dfd: JQueryDeferred<any> = $.Deferred();

    let items = alertsObj.GetSettingItems();
    return dfd.promise(dfd.resolve(items));
  }

  public GetInteractiveTileSettings(): JQueryPromise<Array<DevExpress.ui.dxFormSimpleItem | DevExpress.ui.dxFormGroupItem | DevExpress.ui.dxFormTabbedItem | DevExpress.ui.dxFormEmptyItem | DevExpress.ui.dxFormButtonItem>> {
    let alertsObj = this;
    let dfd: JQueryDeferred<any> = $.Deferred();

    let items = alertsObj.GetSettingItems();
    return dfd.promise(dfd.resolve(items));
  }

  private GetSettingItems(): Array<DevExpress.ui.dxFormSimpleItem | DevExpress.ui.dxFormGroupItem | DevExpress.ui.dxFormTabbedItem | DevExpress.ui.dxFormEmptyItem | DevExpress.ui.dxFormButtonItem> {
    let alertsObj = this;

    let items: Array<DevExpress.ui.dxFormSimpleItem | DevExpress.ui.dxFormGroupItem | DevExpress.ui.dxFormTabbedItem | DevExpress.ui.dxFormEmptyItem | DevExpress.ui.dxFormButtonItem> = [];

    items = [
      <DevExpress.ui.dxFormButtonItem>{
        name: "ConfigureAlertsBtn",
        label: { visible: false },
        editorType: "dxButton",
        editorOptions: {
          type: "info",
          text: "Configure Alerts",
          onClick: () => {
            alertsObj.GetAlertList();
          }
        }
      }
    ];
    return items;
  }

  private GetAlertList() {
    let alertsObj = this;
    if (!$("#alertsSettings").length) {
      $("<div id='alertsSettings' />").appendTo("#tdfbody");
    }

    $("#alertsSettings")
      .dxPopup({
        contentTemplate: function (element: JQuery) {
          element.append($("<div id='alertsScroll'><div id='searchTextBox'></div><div id='alertsList'/></div>"));
        },
        width: $(window).innerWidth() > 500 ? 500 : $(window).innerWidth(),
        title: "Homepage Alerts",
        showCloseButton: true,
        onShowing: function () {
          Preferences.LoadAvailableAlerts(false);
        },
        onHiding: function () {
          alertsObj.RequestData();
        }
      })
      .dxPopup("instance")
      .show();

    $("#alertsScroll").dxScrollView();
  }

  private RequestData(): JQueryPromise<any> {
    var alertsObj = this;
    let dfd: JQueryDeferred<any> = $.Deferred();

    var request = new TDFRequest({
      url: alertsObj.URL,
      type: "GET",
      data: {}
    });
    request.spinnerContainerID = "alertSpinner";
    request.MakeRequest().done(function (data) {
      alertsObj.UpdateContainer(data);
      return dfd.resolve();
    });

    return dfd.promise();
  }

  private UpdateContainer(data) {
    var alertsObj = this;

    var datastore = [];

    data.forEach(function (item) {
      datastore.push(item);
    });

    alertsObj.alertAccordian = $(alertsObj.CONTAINER_ID)
      .dxAccordion({
        dataSource: datastore,
        collapsible: true,
        selectedIndex: -1,
        multiple: true,
        itemTitleTemplate: function (itemData, itemIndex, itemElement) {
          $(itemElement).attr("id", "alertTitle_" + itemData.ID);
          itemElement.append("<strong><span>" + itemData.Description + "</span></strong>");  //<span class='button pull-right' style='cursor:pointer;font-size:10px;padding:2px;' onclick= \"Preferences.UpdateAlertEnrollment('" + itemData.MailingID + "', '" + itemData.ID + "', '" + $(itemElement).attr("id") + "', 0)\">REMOVE</span>
        },
        itemTemplate: function (itemData, itemIndex, itemElement) {
          var contentID = "alertContent_" + itemData.ID;
          var titleID = "alertTitle_" + itemData.ID;
          $(itemElement).attr("id", contentID);
          var request = new TDFRequest({
            url: alertsObj.dataUrl,
            type: "Get",
            data: { mailingid: itemData.MailingID, alertid: itemData.ID }
          });
          request.spinnerContainerID = titleID;
          request.MakeRequest().done(function (data) {
            SingleAlert.MakeAlertDataGrid(itemData.ID, data, itemElement);
          });
        },
        onItemTitleClick: function (e) { }
      })
      .dxAccordion("instance");
  }


}