import "../../index";
import { RegisterTiles } from "../../components/dashboards/dashboardtileregistrar";
import { DashboardEngine } from "../../components/dashboards/dashboardengine";



//$().ready(() => {

if ($("#dashboardFrame").length <= 0) {
  $("#tdfbodycontent").append("<div id='dashboardFrame'></div>");
  RegisterTiles().done(() => {
    new DashboardEngine("");
  });
} else {

  new DashboardEngine("");
}

//});


