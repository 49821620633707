import { Notification } from '../dialogs/notification';

import { eventNameSpace, EventTypes } from '../../enums/webevents/enums';
import { WebEvents } from '../../infrastructure/events/ui_events';
import { iColumnInfo, iTdFDashboardTile } from '../../interfaces/dashboards/idashboarddescriptor';
import { Dashboard } from './dashboard';
import { TdFDashboardTile } from './tdfdashboardtile';
import { DomSafeID, GimmeGUID, GetDevice } from '../../util/allutils';
import { DashboardMode } from './dashboardengine';
import { confirm } from 'devextreme/ui/dialog';

export enum DashboardSectionType {
  TwoThirdsOneThird = 0,
  OneThirdTwoThirds = 1,
  ThreeEqualColumns = 2,
  OneHalfOneHalf = 3,
  Whole = 4,
  SixEqualColumns = 5
}

export interface iDashboardSection {
  SectionType: DashboardSectionType;
  Tiles: iTdFDashboardTile[];
}

export interface iColumnInfo {
  ColumnID: string;
  ColumnName: number;
  Class: string;
}

export abstract class DashboardSection {
  //TODO:  Change these to public getters with private setters.  These should not be modified outside this class!
  public SectionType: DashboardSectionType;
  public Tiles: TdFDashboardTile[] = [];
  protected TileSettings: iTdFDashboardTile[];
  protected ParentDashboard?: Dashboard;

  protected SectionID?: string;
  public get ID(): string {
    let myDashboardSection = this;
    return myDashboardSection.SectionID;
  }
  public get SectionWrapperID() {
    let myDashboardSection = this;
    return myDashboardSection._SectionWrapperID;
  }
  protected Container?: JQuery;
  protected SectionWrapper: JQuery;
  protected readonly SectionClassName: string;

  //ID
  private _SectionWrapperID: string;
  private DesignerTilesAdded: boolean = false;

  constructor(tiles: iTdFDashboardTile[], parentDashboard: Dashboard) {
    let myDashboardSection = this;

    this.Tiles = [];
    this.TileSettings = tiles;
    this.ParentDashboard = parentDashboard;
    this.SectionID = DomSafeID(GimmeGUID());

    myDashboardSection.SetBaseIDs();
  }

  public toJSON(key) {
    let myDashboardSection = this;

    // Need to verify the tiles are all ordered correctly.  Movement within a column isn't always caught.  If I were to trigger it on the sortable.Update event, I'd have to handle knowing if it was update within a column or receive/remove updates.
    myDashboardSection.UpdateTileOrder();

    let it = {
      SectionType: myDashboardSection.SectionType,
      Tiles: myDashboardSection.Tiles
    };
    return it;
  }

  private RemoveTile(e: JQueryEventObject, data) {
    let myDashboardSection = this;
    let indexToRemove = -1;

    $.each(myDashboardSection.Tiles, function (currentIndex, currentTile) {
      if (currentTile.TileInstanceID === data.ID) {
        indexToRemove = currentIndex;
      }
    });

    if (indexToRemove != -1) {
      myDashboardSection.ParentDashboard.UnregisterTile(
        myDashboardSection.Tiles[indexToRemove].TileInstanceID
      );
      myDashboardSection.Tiles.splice(indexToRemove, 1);
      WebEvents.Event.RaiseEvent(EventTypes.DashboardEventTypes.MarkDashboardDirty, eventNameSpace.dashboard);
    }
  }

  private SetBaseIDs() {
    let myDashboardSection = this;
    myDashboardSection._SectionWrapperID = `wrapper_${myDashboardSection.SectionID}`;
  }

  public Render(container: JQuery) {
    let myDashboardSection = this;
    myDashboardSection.Container = container;

    myDashboardSection.RenderSectionFrame();
    myDashboardSection.RenderLayoutAndRegisterTiles();
  }

  protected RenderSectionFrame() {
    let myDashboardSection = this;

    myDashboardSection.SectionWrapper = $(`<div id='${myDashboardSection._SectionWrapperID}' class='tdfDashboardSectionFrame clearfix col-md-12 ${myDashboardSection.SectionClassName}' />`).data('SectionID', myDashboardSection.ID);

    let sectionHeader = myDashboardSection.CreateSectionToolbar();

    myDashboardSection.SectionWrapper.append(sectionHeader.element());

    myDashboardSection.Container.append(myDashboardSection.SectionWrapper);

    // Subscribe to events that the section handles
    WebEvents.Event.AddHandler(EventTypes.DashboardEventTypes.removeTile, eventNameSpace.dashboard, myDashboardSection.SectionWrapper, myDashboardSection.RemoveTile.bind(myDashboardSection));
    WebEvents.Event.AddHandler(EventTypes.DashboardEventTypes.dashboardModeChanged, eventNameSpace.dashboard, myDashboardSection.SectionWrapper, myDashboardSection.HandleModeChange.bind(myDashboardSection));
    WebEvents.Event.AddHandler2(EventTypes.DashboardEventTypes.addTile, myDashboardSection.ID, eventNameSpace.dashboard, myDashboardSection.SectionWrapper, myDashboardSection.HandleAddNewTileRequest.bind(myDashboardSection));
    WebEvents.Event.AddHandler2(EventTypes.DashboardEventTypes.TileLayoutTileRemoved, myDashboardSection.ID, eventNameSpace.dashboard, myDashboardSection.SectionWrapper, myDashboardSection.HandleTileLayoutTileRemoved.bind(myDashboardSection));
    WebEvents.Event.AddHandler2(EventTypes.DashboardEventTypes.TileLayoutTileReceived, myDashboardSection.ID, eventNameSpace.dashboard, myDashboardSection.SectionWrapper, myDashboardSection.HandleTileLayoutTileReceived.bind(myDashboardSection));

    myDashboardSection.RenderSectionColumns();
  }

  private CreateSectionToolbar() {
    let myDashboardSection = this;

    let sectionHeader: DevExpress.ui.dxToolbar = $('<div />').css({ 'background-color': 'transparent' })
      .dxToolbar(<DevExpress.ui.dxToolbarOptions>{
        width: '100%',
        items: <DevExpress.ui.dxToolbarItemTemplate[]>[
          {
            location: 'before',
            locateInMenu: 'never',
            widget: 'dxButton',
            options: <DevExpress.ui.dxButtonOptions>{
              stylingMode: 'text',
              icon: 'fa fa-window-minimize',
              onClick: e => {
                if (e.component.option('icon') === 'fa fa-window-minimize') {
                  for (let i = 0, length = myDashboardSection.Tiles.length; i < length; i++) {
                    // TOOD:  Check if it's set to Load Collapsed and it hasn't been rendered.
                    // myDashboardSection.Tiles[i].DashboardTileSettings.LoadCollapsed && ...
                    myDashboardSection.Tiles[i].Collapse();
                  }

                  e.component.option('icon', 'fa fa-window-maximize');
                } else {
                  for (let i = 0, length = myDashboardSection.Tiles.length; i < length; i++) {
                    myDashboardSection.Tiles[i].Expand();
                  }

                  e.component.option('icon', 'fa fa-window-minimize');
                }
              }
            }
          },
          {
            location: 'after',
            locateInMenu: 'never',
            widget: 'dxButton',
            options: <DevExpress.ui.dxButtonOptions>{
              visible: false,
              stylingMode: 'text',
              icon: 'fa fa-plus',
              elementAttr: { class: 'onlyInDesigner' },
              onClick: e => {
                if (myDashboardSection.ParentDashboard.TileCount < myDashboardSection.ParentDashboard.DashboardLimitsObj.MaxNumTiles) {
                  WebEvents.Event.RaiseEvent2(EventTypes.DashboardEventTypes.addTile, myDashboardSection.ID, eventNameSpace.dashboard, { ID: myDashboardSection.ID });
                } else {
                  new Notification({ message: `The maximum number of Tiles for this Dashboard has been reached. (Max = ${myDashboardSection.ParentDashboard.DashboardLimitsObj.MaxNumTiles})`, type: 'error' });
                }
              }
            }
          },
          {
            location: 'after',
            locateInMenu: 'never',
            widget: 'dxButton',
            options: <DevExpress.ui.dxButtonOptions>{
              visible: false,
              stylingMode: 'text',
              icon: 'fa fa-chevron-down',
              elementAttr: { class: 'onlyInDesigner' },
              onClick: e => {
                if ($(`#ChangeSectionType_${myDashboardSection.ID}`).length) {
                  $(`#ChangeSectionType_${myDashboardSection.ID}`).remove();
                }
                $('body').append(
                  $(`<div id='ChangeSectionType_${myDashboardSection.ID}' />`)
                );

                let sectionTypes: any[] = [];
                sectionTypes.push({
                  Name: 'Whole',
                  SectionType: DashboardSectionType.Whole,
                  Icon: 'icomoon icon-Whole',
                  NumOfColumns: 1
                });
                sectionTypes.push({
                  Name: 'One Half - One Half',
                  SectionType: DashboardSectionType.OneHalfOneHalf,
                  Icon: 'icomoon icon-Half-Half',
                  NumOfColumns: 2
                });
                sectionTypes.push({
                  Name: 'Two Thirds - One Third',
                  SectionType: DashboardSectionType.TwoThirdsOneThird,
                  Icon: 'icomoon icon-TwoThird-OneThird',
                  NumOfColumns: 2
                });
                sectionTypes.push({
                  Name: 'One Third - Two Thirds',
                  SectionType: DashboardSectionType.OneThirdTwoThirds,
                  Icon: 'icomoon icon-OneThird-TwoThird',
                  NumOfColumns: 2
                });
                sectionTypes.push({
                  Name: 'Three Equal Columns',
                  SectionType: DashboardSectionType.ThreeEqualColumns,
                  Icon: 'icomoon icon-Thirds',
                  NumOfColumns: 3
                });
                sectionTypes.push({
                  Name: 'Six Equal Columns',
                  SectionType: DashboardSectionType.SixEqualColumns,
                  Icon: 'icomoon icon-Sixths',
                  NumOfColumns: 6
                });

                let sectionMenu = $(
                  `#ChangeSectionType_${myDashboardSection.ID}`
                )
                  .dxActionSheet({
                    items: sectionTypes,
                    showTitle: false,
                    itemTemplate: function (data, index, el) {
                      let btn = $('<div />').dxButton({
                        hint: data.Name,
                        width: 'auto',
                        icon: `${data.Icon} fa-3x`,
                        template: (data, element) => {
                          return $('<i></i>').addClass(data.icon);
                        },
                        onClick: function (e) {
                          WebEvents.Event.RaiseEvent2(EventTypes.DashboardEventTypes.ChangeSectionType, myDashboardSection.ParentDashboard.ID, eventNameSpace.dashboard, { NewSectionInfo: data, OldSectionInfo: myDashboardSection });
                          //myDashboardSection.ChangeSectionType(data);
                        }
                      });

                      el.append(btn);
                    },
                    onContentReady(e) {
                      let container = (e.component as any)._$itemContainer;
                      $(container).dxScrollView({ height: '100%' });
                    },
                    width: 'auto'
                  })
                  .dxActionSheet('instance');

                if (!GetDevice().isDevice) {
                  sectionMenu.option('target', e.element.children()[0]);
                  sectionMenu.option('usePopover', true);
                }

                sectionMenu.show();
              }
            }
          },
          {
            location: 'after',
            locateInMenu: 'never',
            widget: 'dxButton',
            options: <DevExpress.ui.dxButtonOptions>{
              visible: false,
              stylingMode: 'text',
              icon: 'fa fa-trash',
              elementAttr: { class: 'onlyInDesigner' },
              onClick: e => {
                let callback = () => {
                  let indexToRemove: number = -1;

                  // Loop Backwards since we are removing them.
                  for (var i = myDashboardSection.Tiles.length - 1; i >= 0; i--) {
                    myDashboardSection.Tiles[i].Remove()
                  }

                  WebEvents.Event.RaiseEvent(EventTypes.DashboardEventTypes.RemoveSection, eventNameSpace.dashboard, { ID: myDashboardSection.ID });
                  WebEvents.Event.RaiseEvent(EventTypes.DashboardEventTypes.MarkDashboardDirty, eventNameSpace.dashboard);
                };

                if (myDashboardSection.Tiles.length > 0) {
                  confirm(
                    'Removing this section will also remove all tiles currently in it. Are you sure you want to remove this section?',
                    'Remove Section'
                  ).done(answer => {
                    if (answer) {
                      return callback();
                    }
                  });
                } else {
                  return callback();
                }
              }
            }
          }
        ]
      })
      .dxToolbar('instance');

    return sectionHeader;
  }

  public HandleTileLayoutTileReceived(e: JQueryEventObject, data) {
    let myDashboardSection = this;

    let mytile: TdFDashboardTile = data.Tile;
    mytile.Column = data.NewColumnName;

    myDashboardSection.Tiles.push(data.Tile);

    myDashboardSection.UpdateTileOrder();
  }

  UpdateTileOrder() {
    let myDashboardSection = this;

    let newTileList: TdFDashboardTile[] = [];

    $.each(myDashboardSection.ColumnInfo, function (columnIndex, column) {
      let tileElementIDList: string[] = $(`#${column.ColumnID}`).sortable(
        'toArray'
      );

      $.each(tileElementIDList, function (idIndex, tileElementID) {
        let tileInstanceID = $(`#${tileElementID}`).data('TileID');
        let currentTile: TdFDashboardTile;
        let currentTileIndex = -1;

        $.each(myDashboardSection.Tiles, function (index, dashboardTile) {
          if (dashboardTile.TileInstanceID === tileInstanceID) {
            currentTile = dashboardTile;
            currentTileIndex = index;
          }
        });

        if (currentTile) {
          newTileList.push(currentTile);
          myDashboardSection.Tiles.splice(currentTileIndex, 1);
        }
      });
    });

    myDashboardSection.Tiles = newTileList;
  }

  public HandleTileLayoutTileRemoved(e: JQueryEventObject, data) {
    let myDashboardSection = this;

    let removedTileIndex: number = -1;
    $.each(myDashboardSection.Tiles, function (index, dashboardTile) {
      if (dashboardTile.TileInstanceID === data.TileID) {
        removedTileIndex = index;
      }
    });

    if (removedTileIndex != -1) {
      let removedTile = myDashboardSection.Tiles[removedTileIndex];
      myDashboardSection.Tiles.splice(removedTileIndex, 1);

      WebEvents.Event.RaiseEvent(EventTypes.DashboardEventTypes.TileDetached, eventNameSpace.dashboard, { DetachedTile: removedTile });
    }
  }

  public HandleModeChange(e: JQueryEventObject, data) {
    let myDashboardSection = this;

    $(`.tdfSectionColumnFrame`).sortable('option', 'disabled', myDashboardSection.ParentDashboard.Mode === DashboardMode.Viewer);
  }

  public HandleAddNewTileRequest(e: JQueryEventObject, data) {
    let myDashboardSection = this;

    // Ask to be notified when the Tile Selector is closed
    WebEvents.Event.AddHandler(EventTypes.DashboardEventTypes.tileSelectorClosed, eventNameSpace.dashboard, myDashboardSection.SectionWrapper, myDashboardSection.HandleNewTileSelected.bind(myDashboardSection), { Column: 0 });

    // Load the Tile Selector
    WebEvents.Event.RaiseEvent(EventTypes.DashboardEventTypes.loadTileSelector, eventNameSpace.dashboard);
  }

  public HandleNewTileSelected(e: JQueryEventObject, data) {
    let myDashboardSection = this;
    WebEvents.Event.RaiseEvent(EventTypes.DashboardEventTypes.MarkDashboardDirty, eventNameSpace.dashboard);

    let newDashboardTile = new TdFDashboardTile(myDashboardSection.ParentDashboard.Mode);
    newDashboardTile.Column = e.data.value.Column;
    newDashboardTile.TileInstanceID = `{${GimmeGUID()}}`;
    newDashboardTile.TileType = data.TileType;
    newDashboardTile.Icon = data.Icon;
    newDashboardTile.TileSettings = data.TileSettings ? data.TileSettings : {};

    newDashboardTile.CreateTile(true);

    myDashboardSection.Tiles.push(newDashboardTile);

    let targetColumnContainer: JQuery = myDashboardSection.GetTileContainer(newDashboardTile);

    newDashboardTile.BuildTileFrame(targetColumnContainer);
    myDashboardSection.ParentDashboard.RegisterTile(newDashboardTile);

    myDashboardSection.ParentDashboard.RenderTilesByID([newDashboardTile.TileInstanceID]);

    WebEvents.Event.RemoveHandler(EventTypes.DashboardEventTypes.tileSelectorClosed, eventNameSpace.dashboard, myDashboardSection.SectionWrapper);

    WebEvents.Event.RaiseEvent(EventTypes.DashboardEventTypes.RefreshSortableTileLists, eventNameSpace.dashboard, { ColumnID: targetColumnContainer.attr('id') });
  }

  protected RenderLayoutAndRegisterTiles() {
    let myDashboardSection = this;

    $.each(myDashboardSection.TileSettings, function (index, tileNode: iTdFDashboardTile) {
      let newDashboardTile = new TdFDashboardTile(myDashboardSection.ParentDashboard.Mode, tileNode);
      if (newDashboardTile.CreateTile()) {
        myDashboardSection.Tiles.push(newDashboardTile);

        let targetColumnContainer: JQuery = myDashboardSection.GetTileContainer(newDashboardTile);

        newDashboardTile.BuildTileFrame(targetColumnContainer);

        myDashboardSection.ParentDashboard.RegisterTile(newDashboardTile);
      }
    });
  }

  protected abstract GetTileContainer(node: TdFDashboardTile);

  protected abstract get ColumnInfo(): iColumnInfo[];
  private RenderSectionColumns() {
    let myDashboardSection = this;

    $.each(myDashboardSection.ColumnInfo, function (index, item) {
      let column: JQuery = $(`<div id='${item.ColumnID}' class='tdfSectionColumnFrame ${item.Class}' />`).data('SectionID', myDashboardSection.ID).data('ColumnName', item.ColumnName);
      myDashboardSection.SectionWrapper.append(column);

      WebEvents.Event.RaiseEvent(EventTypes.DashboardEventTypes.InitializeSortableTileLists, eventNameSpace.dashboard, { ColumnID: item.ColumnID });
    });
  }
}