import { DashboardSectionType } from "../../enums/dashboards/enums";
import { iColumnInfo, iTdFDashboardTile } from "../../interfaces/dashboards/idashboarddescriptor";
import { Dashboard } from "./dashboard";
import { DashboardSection } from "./dashboardsection";
import { TdFDashboardTile } from "./tdfdashboardtile";
export class ThreeEqualColumndDashboardSection extends DashboardSection {
  public SectionType: DashboardSectionType = DashboardSectionType.ThreeEqualColumns;
  private designerTiles: iTdFDashboardTile[];
  private myColumns: iColumnInfo[];
  protected readonly SectionClassName: string = "tdfThreeEqualColumndDashboardSection";
  // Column IDs
  private ColOneID: string;
  private ColTwoID: string;
  private ColThreeID: string;
  // Columns
  private columnOne: JQuery;
  private columnTwo: JQuery;
  private columnThree: JQuery;
  protected get ColumnOne(): JQuery {
    let myDashboardSection = this;
    if (!myDashboardSection.columnOne) {
      myDashboardSection.columnOne = $(`#${myDashboardSection.ColOneID}`);
    }
    return (myDashboardSection.columnOne);
  }
  protected get ColumnTwo(): JQuery {
    let myDashboardSection = this;
    if (!myDashboardSection.columnTwo) {
      myDashboardSection.columnTwo = $(`#${myDashboardSection.ColTwoID}`);
    }
    return (myDashboardSection.columnTwo);
  }
  protected get ColumnThree(): JQuery {
    let myDashboardSection = this;
    if (!myDashboardSection.columnThree) {
      myDashboardSection.columnThree = $(`#${myDashboardSection.ColThreeID}`);
    }
    return (myDashboardSection.columnThree);
  }
  constructor(tiles: iTdFDashboardTile[], parentDashboard: Dashboard) {
    super(tiles, parentDashboard);
    let myDashboardSection = this;
    myDashboardSection.SetIDs();
  }
  private SetIDs() {
    let myDashboardSection = this;
    myDashboardSection.ColOneID = `colOne_${myDashboardSection.SectionID}`;
    myDashboardSection.ColTwoID = `colTwo_${myDashboardSection.SectionID}`;
    myDashboardSection.ColThreeID = `colThree_${myDashboardSection.SectionID}`;
  }
  protected get ColumnInfo(): iColumnInfo[] {
    let myDashboardSection = this;
    if (!myDashboardSection.myColumns) {
      myDashboardSection.myColumns = [];
      let col1 = { ColumnID: myDashboardSection.ColOneID, ColumnName: 0, Class: "col-md-4" };
      let col2 = { ColumnID: myDashboardSection.ColTwoID, ColumnName: 1, Class: "col-md-4" };
      let col3 = { ColumnID: myDashboardSection.ColThreeID, ColumnName: 2, Class: "col-md-4" };
      myDashboardSection.myColumns.push(col1);
      myDashboardSection.myColumns.push(col2);
      myDashboardSection.myColumns.push(col3);
    }
    return myDashboardSection.myColumns;
  }
  protected GetTileContainer(node: TdFDashboardTile) {
    let myDashboardSection = this;
    let targetContainer: JQuery = null;
    if (node.Column === 0) {
      targetContainer = myDashboardSection.ColumnOne;
    }
    else if (node.Column === 1) {
      targetContainer = myDashboardSection.ColumnTwo;
    }
    else {
      targetContainer = myDashboardSection.ColumnThree;
    }
    return targetContainer;
  }
}
